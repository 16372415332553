import { declareList } from 'libs/reatom-toolkit';

export interface Message {
  id: number;
  body: string;
  timestamp: string;
  sender: string;
  pet: {};
  petOwner: {};
  serviceOwner: {};
  url?: string;
  fileId?: number;
  key?: string;
}

export const MessagesList = declareList<Message>('Messages');
