import React, { useEffect, useState } from 'react';

export function useElementOnScreen(
  ref: React.Ref<HTMLElement>,
  rootMargin = '0px',
) {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin,
      },
    );

    if (ref.current) {
      const element = ref.current;
      observer.observe(element);
      return () => {
        observer.unobserve(element);
      };
    }
  }, [ref.current]);

  return isIntersecting;
}
