import React from 'react';
import { InputProps, useFormField } from 'libs/forms';

import { Input } from '../atoms/input/input';
import { TextArea } from '../atoms/textarea/testarea';
import { PasswordInput } from '../molecules/password-input/password-input';
import { PhoneInput } from '../molecules/phone-input/phone-input';

interface Props {
  name: string;
  placeholder?: string;
  type?: 'text' | 'tel' | 'password';
  rounded?: boolean;
  middle?: boolean;
  className?: string;
  readOnly?: boolean;
  disabled?: boolean;
}

const componentsHash: {
  [key: string]: React.FC<InputProps<string> & { placeholder?: string }>;
} = {
  // @ts-ignore
  text: Input,
  password: PasswordInput,
  tel: PhoneInput,
};

export function InputField(props: Props) {
  const { input, shouldShowError, disabled } = useFormField(props.name);

  const Component = componentsHash[props.type ?? 'text'];

  return (
    // @ts-ignore
    <Component
      {...input}
      placeholder={props.placeholder}
      disabled={disabled || props.disabled}
      isInvalid={shouldShowError}
      rounded={props.rounded}
      middle={props.middle}
      className={props.className}
      readOnly={props.readOnly}
    />
  );
}

interface TextFieldProps {
  name: string;
  placeholder?: string;
  className?: string;
}

export function TextField(props: TextFieldProps) {
  const { input, shouldShowError, disabled } = useFormField(props.name);
  return (
    <TextArea
      {...input}
      isInvalid={shouldShowError}
      disabled={disabled}
      placeholder={props.placeholder}
      className={props.className}
    />
  );
}
