import React, { useEffect, useMemo } from 'react';
import { render } from 'react-dom';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { combine, createStore } from '@reatom/core';
import { setConfig } from 'react-hot-loader';
import { Provider, useAtom } from '@reatom/react';

import { getPrivateApi, publicApi } from 'api';
import { ProfileAtom, setCurrentProfile } from 'features/profile';
import { TokenAtom } from 'features/authentication';
import { call } from 'libs/reatom-toolkit';
import { setTokenFromStorageAction } from 'features/authentication/auth.model';
import { compileRoutes } from 'libs/guards';
import { connectReduxDevtools } from '@reatom/debug';
import { autoAppHeightUpdate } from 'libs/mobile-app-height';

import App from './App';
import { routes } from './routes';

const rootAtom = combine({
  token: TokenAtom,
  profile: ProfileAtom,
});

const store = createStore(window.__INITIAL_STATE__);

store.subscribe(rootAtom, () => {});
store.publicApi = publicApi;
store.privateApi = getPrivateApi(() => store.getState(TokenAtom));

function ClientApp(props) {
  useEffect(() => {
    return connectReduxDevtools(store);
  }, []);
  const user = useAtom(ProfileAtom);
  const compiledRoutes = useMemo(() => compileRoutes(routes, { user }), [user]);
  return (
    <Router history={props.history}>
      <App routes={compiledRoutes} />
    </Router>
  );
}

(async () => {
  const history = createBrowserHistory();

  store.history = history;
  try {
    store.dispatch(setTokenFromStorageAction());
    const token = store.getState(TokenAtom);
    if (token) {
      await call(store, setCurrentProfile());
    }
  } finally {
    render(
      <Provider value={store}>
        <ClientApp history={history} />
      </Provider>,
      document.getElementById('root'),
    );
  }
})();

setConfig({
  reloadHooks: false,
});

if (module.hot) {
  module.hot.accept();
}

autoAppHeightUpdate();
