import React from 'react';

import { ChangePasswordModalForm } from 'features/authentication';
import { privateApi } from 'api';

export function PasswordChangeForm() {
  async function onSubmit({ password, currentPassword }) {
    await privateApi.put(`/service-owner/user/password`, {
      password,
      current_password: currentPassword,
    });
  }

  return <ChangePasswordModalForm onSubmit={onSubmit} />;
}
