import React from 'react';
import styled from 'styled-components';

import { UserAvatar } from 'ui/ecosystems/users';
import { useDropdown, DropdownContext } from 'libs/react-dropdown-elements';
import { mediaTablet, mediaPhone } from 'ui/styles/media';

import { DropDownMenu } from '../../atoms/dropdown-menu';

interface Props {
  avatar?: string;
  AvatarComponent: typeof UserAvatar;
  renderUserMenu: (close: () => void) => React.ReactNode;
  renderMenu: () => React.ReactNode;
}

export function HeaderProfile(props: Props) {
  const { ref, openAbleState: menu, contextValue } = useDropdown();

  return (
    <DropdownContext.Provider value={contextValue}>
      <HeaderProfileRoot>
        <Content>{props.renderMenu && props.renderMenu()}</Content>

        <Profile ref={ref as any} onClick={menu.toggle}>
          <UserAvatar as={props.AvatarComponent} avatar={props.avatar} />
          <Label>Profile</Label>
          <StopPropagation>
            {props.renderUserMenu && menu.isOpened && (
              <Menu>{props.renderUserMenu(menu.close)}</Menu>
            )}
          </StopPropagation>
        </Profile>
      </HeaderProfileRoot>
    </DropdownContext.Provider>
  );
}

function StopPropagation(props) {
  return <div onClick={e => e.stopPropagation()}>{props.children}</div>;
}

const Content = styled.div`
  display: flex;

  & > *:not(:last-child) {
    margin-right: 40px;
  }
`;

const Menu = styled(DropDownMenu)``;

const Label = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  ${mediaPhone} {
    display: none;
  }
`;

const Profile = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const HeaderProfileRoot = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  ${Content} {
    margin-right: 24px;
  }

  ${UserAvatar} {
    width: 40px;
    height: 40px;
    margin-right: 15px;

    ${mediaTablet} {
      width: 32px;
      height: 32px;
      margin: 0;
    }
  }

  ${Menu} {
    position: absolute;
    top: calc(100% + 2px);
    right: 0;
    z-index: 1;
  }
`;
