import React from 'react';

import { useFormField } from 'libs/forms';

import { SimpleSelector } from '../molecules/simple-selector/simple-selector';

interface Props {
  name: string;
  placeholder?: string;
  options: Array<{ label: string; value: unknown }>;
}

export function SimpleSelectorField(props: Props) {
  const { input, shouldShowError } = useFormField<number>(props.name);

  return (
    <SimpleSelector
      {...input}
      options={props.options}
      placeholder={props.placeholder}
      isInvalid={shouldShowError}
    />
  );
}
