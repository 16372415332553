import React from 'react';
import styled, { css } from 'styled-components';

// @ts-ignore
import { mediaTablet } from 'ui/styles/media';

import LogoIcon from './logo.svg';
import BlueLogoIcon from './blue.svg';
import { Icon } from '../icon';

export function Logo(props) {
  return <StyledIcon blue={props.blue} icon={LogoIcon} />;
}

const StyledIcon = styled(Icon).attrs(props => ({
  icon: props.blue ? BlueLogoIcon : LogoIcon,
}))`
  --color: white;
  ${props =>
    props.blue &&
    css`
      --color: var(--darkBlue);
    `}

  ${Icon.imageSelector} {
    width: 184px;
    height: 41px;
    ${mediaTablet} {
      width: 145.33px;
      height: 32px;
    }
  }
`;
