import React from 'react';
import { Link } from 'react-router-dom';

import { SPAvatar } from 'ui/ecosystems/users';
import { useSession } from 'features/authentication';
import { useOpenableState } from 'libs/use-openable-state';
import {
  ServiceProviderEditProfileModal,
  useCurrentServiceProviderProfile,
} from 'features/service-provider-profile';

import { BaseHeaderTemplate } from '../../templates/base-header-template/base-header-template';
import { HeaderProfile } from '../../molecules/header-profile/header-profile';
import { Divider, LinkMenuItem, MenuItem } from '../../atoms/dropdown-menu';
import { HeaderMenuItem } from '../../atoms/header-menu-item';
import { MessagesIcon } from '../../../../../features/messages';

export function ServiceProviderHeader() {
  const { profile } = useCurrentServiceProviderProfile();
  const { logout } = useSession();

  const editProfileModal = useOpenableState();

  return (
    <BaseHeaderTemplate
      renderContent={() => (
        <React.Fragment>
          <HeaderProfile
            AvatarComponent={SPAvatar}
            avatar={profile.avatar?.url}
            renderMenu={() => (
              <React.Fragment>
                <HeaderMenuItem>
                  <Link to="/service-provider-profile/premium">
                    {profile.hasPremium ? 'Premium' : 'Get Premium'}
                  </Link>
                </HeaderMenuItem>
                <MessagesIcon />
              </React.Fragment>
            )}
            renderUserMenu={() => (
              <React.Fragment>
                <LinkMenuItem to="/service-provider-profile">
                  View Profile
                </LinkMenuItem>
                <Divider />
                <LinkMenuItem to="/service-provider-profile/appointments">
                  Appointments
                </LinkMenuItem>
                <LinkMenuItem to="/service-provider-profile/services">
                  Services
                </LinkMenuItem>
                <LinkMenuItem to="/service-provider-profile/personnel">
                  Personnel
                </LinkMenuItem>
                <LinkMenuItem to="/service-provider-profile/reviews">
                  Reviews
                </LinkMenuItem>
                <Divider />
                <MenuItem onClick={editProfileModal.open}>
                  Edit Profile
                </MenuItem>
                <MenuItem onClick={logout}>Log Out</MenuItem>
              </React.Fragment>
            )}
          />
          <ServiceProviderEditProfileModal
            isOpened={editProfileModal.isOpened}
            onClose={editProfileModal.close}
          />
        </React.Fragment>
      )}
    />
  );
}
