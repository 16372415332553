import styled from 'styled-components';

import { InputWrapper } from '../input-wrapper';

export const TextArea = styled(InputWrapper).attrs({
  as: 'textarea',
})`
  resize: none;
  padding-top: 8px;
  height: 80px;
  width: 100%;
`;
