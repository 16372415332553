import { useContext, useEffect, useRef, useState } from 'react';
import * as React from 'react';
import styled from 'styled-components';

import {
  TabsState,
  Tabs,
  Tab as BaseTab,
  TabsContext,
} from '../../molecules/tabs/tabs';

interface SimpleTabsProps {
  defaultTab?: string;
  children: React.ReactNode;
}

export function ModalTabs(props: SimpleTabsProps) {
  const [currentTab, setCurrentTab] = useState<TabsState>({
    ref: null,
    id: props.defaultTab || React.Children.toArray(props.children)[0].props.id,
  });

  const renderContents = React.Children.map(props.children, ({ props }) => ({
    id: props.id,
    renderContent: props.renderContent,
  })).reduce(
    (res, { id, renderContent }) => ({
      ...res,
      [id]: renderContent,
    }),
    {},
  );

  return (
    <React.Fragment>
      <ModalTabsRoot currentTab={currentTab} setCurrentTab={setCurrentTab}>
        {props.children}
      </ModalTabsRoot>
      {renderContents[currentTab.id]()}
    </React.Fragment>
  );
}

const ModalTabsRoot = styled(Tabs)`
  justify-content: flex-start;
  margin-bottom: 32px;

  & > :not(:last-child) {
    margin-right: 24px;
  }
`;

interface SimpleTabProps {
  id: string;
  children: React.ReactNode;
  renderContent(): React.ReactNode;
}

export function Tab(props: SimpleTabProps) {
  const ref = useRef<HTMLDivElement>(null);
  const { setCurrentTab, currentTab } = useContext(TabsContext);

  function markTabAsCurrent() {
    setCurrentTab({ ref, id: props.id });
  }

  useEffect(() => {
    if (currentTab.id === props.id) {
      markTabAsCurrent();
    }
  }, [currentTab.id]);

  return (
    <BaseTab
      ref={ref}
      onClick={markTabAsCurrent}
      isActive={currentTab.id === props.id}
    >
      {props.children}
    </BaseTab>
  );
}
