import {
  PayloadActionCreator,
  declareAction,
  declareAtom,
  Store,
} from '@reatom/core';

export interface AsyncActionsCreator<Response, Variables = undefined>
  extends PayloadActionCreator<Variables> {
  done: PayloadActionCreator<Response>;
  fail: PayloadActionCreator<any>;
}

export function declareAsyncAction<Response, Variables = undefined>(
  name: string,
  fetcher: (variables: Variables, store: Store) => Promise<Response> | Response,
): AsyncActionsCreator<Response, Variables> {
  const done = declareAction<Response>(`${name} fetch done`);
  const fail = declareAction<Error>(`${name} fetch fail`);

  const fetchAction: Partial<AsyncActionsCreator<Variables>> = declareAction<
    Variables
  >(`${name} fetch`, async (variables, store) => {
    try {
      const data = await fetcher(variables, store);
      store.dispatch(done(data));
      return data;
    } catch (error) {
      store.dispatch(fail(error));
      return Promise.reject(error);
    }
  });

  fetchAction.done = done;
  fetchAction.fail = fail;

  // @ts-ignore
  return fetchAction;
}

export function declareAsyncStatusAtom(
  name: string | [string],
  asyncAction: AsyncActionsCreator<any>,
  defaultValue = false,
) {
  return declareAtom<boolean>(name, defaultValue, on => [
    on(asyncAction, () => true),
    on(asyncAction.fail, () => false),
    on(asyncAction.done, () => false),
  ]);
}
