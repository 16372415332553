import React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';

import {
  FormModalContentTemplate,
  InputField,
  ServiceTypeSelectorField,
  TextField,
  CoverImageField,
  ImageField,
  FormLine,
  FieldsGroup,
  FormFields,
  SubmitFormButton,
} from 'ui/ecosystems/forms';
import { Form, useCreateForm } from 'libs/forms';
import { SPAvatar } from 'ui/ecosystems/users';

import {
  AddressDetails,
  PlaceAutocompleteInput,
} from 'ui/ecosystems/forms/molecules/place-autocomplete/place-autocomplete-input';

import * as publicProfileForm from '../../../forms/public-profile-form';
import { useCurrentServiceProviderProfile } from '../../../current-service-provider-profile.model';

const schema = yup.object({
  firstName: yup.string().required('required'),
  lastName: yup.string().required('required'),
  companyName: yup.string().required('required'),
  email: yup.string().email().required('required'),
  serviceTypeId: yup.number().required('required'),
  phoneNumber: yup
    .string()
    .required('required')
    .matches(/(\(\d\d\d\) \d\d\d-\d\d\d\d)|\d{10}/, 'invalid format'),
  companyAddress: yup.string().required('required'),
  city: yup.string().required('required'),
  state: yup.string().required('required'),
  zipCode: yup.string().required('required'),
});

export function PublicProfileForm() {
  const { profile, updateProfile } = useCurrentServiceProviderProfile();

  async function handleFormSubmit(values: publicProfileForm.FormState) {
    const data = publicProfileForm.format(values);
    await updateProfile(data);
  }

  const form = useCreateForm<publicProfileForm.FormState>(
    {
      initialValues: profile,
      onSubmit: handleFormSubmit,
    },
    [profile],
  );

  async function handleAutocompleteChange(value: AddressDetails) {
    form.FFFormApi.batch(() => {
      form.FFFormApi.change(
        'companyAddress',
        (value?.street_number || value?.route) &&
          [value?.street_number, value?.route].join(' '),
      );
      form.FFFormApi.change('city', value?.city);
      form.FFFormApi.change('state', value?.state);
      form.FFFormApi.change('zipCode', value?.zip_code);
      form.FFFormApi.change('latitude', value?.latitude);
      form.FFFormApi.change('longitude', value?.longitude);
    });
  }

  return (
    <Form
      schema={schema}
      form={form}
      render={() => (
        <FormModalContentTemplate
          renderContent={() => (
            <FormFields>
              <ImageField name="avatar" ImageComponent={SPAvatar} />
              <CoverImageField name="coverImage" />
              <FormLine label="Company Name">
                <InputField name="companyName" placeholder="Company Name" />
              </FormLine>
              <FormLine label="Type">
                <ServiceTypeSelectorField
                  name="serviceTypeId"
                  placeholder="Service Type"
                />
              </FormLine>

              <FormLine label="Phone Number">
                <InputField
                  type="tel"
                  name="phoneNumber"
                  placeholder="Phone Number"
                />
              </FormLine>
              <FormLine label="Website">
                <InputField name="website" placeholder="Website" />
              </FormLine>
              <FormLine label="Location">
                <FieldsGroup>
                  <PlaceAutocompleteInput
                    placeholder="Type your address here"
                    onChange={handleAutocompleteChange}
                  />
                  <InputField
                    name="companyAddress"
                    placeholder="Address"
                    disabled
                  />
                  <InputField name="city" placeholder="City" disabled />
                  <StateZipGroup>
                    <InputField name="state" placeholder="State" disabled />
                    <InputField name="zipCode" placeholder="Zip" disabled />
                  </StateZipGroup>
                </FieldsGroup>
              </FormLine>
              <FormLine label="About">
                <TextField
                  name="about"
                  placeholder="Tell us more about your business!"
                />
              </FormLine>
            </FormFields>
          )}
          renderActions={() => (
            <SubmitFormButton>Save Changes</SubmitFormButton>
          )}
        />
      )}
    />
  );
}

const StateZipGroup = styled(FieldsGroup)`
  grid-template-columns: 80fr 20fr;
`;
