import React from 'react';
import styled, { css } from 'styled-components';

import { useCurrentProfile } from 'features/profile';
import Footer from 'ui/_components/Footer/Footer';
import Header from 'ui/_components/Header/Header';
import {
  ServiceProviderHeader,
  PetOwnerHeader,
} from 'ui/ecosystems/navigation';

import { PageTemplate } from '../page-template/page-template';

export function InternalPageTemplate(props: {
  children: React.ReactNode;
  isFull?: boolean;
  noFooter?: boolean;
}) {
  const profile = useCurrentProfile();

  return (
    <PageTemplate
      renderHeader={() => {
        switch (true) {
          case profile.isServiceProvider:
            return <ServiceProviderHeader />;
          case profile.isPetOwner:
            return <PetOwnerHeader />;
          default:
            return <Header fixed />;
        }
      }}
      renderContent={() => (
        <Content isFull={props.isFull}>{props.children}</Content>
      )}
      renderFooter={() => !props.noFooter && <Footer />}
    />
  );
}

const Content = styled.div<{ isFull: boolean }>`
  padding: 0 0 120px;
  min-height: calc(var(--appHeight) - var(--headerHeight));

  ${props =>
    props.isFull &&
    css`
      padding: 0;
    `}
`;
