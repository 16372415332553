import styled, { css } from 'styled-components';

interface Props {
  disabled?: boolean;
  outline?: boolean;
  secondary?: boolean;
  rounded?: boolean;
  small?: boolean;
  medium?: boolean;
  large?: boolean;
}

const BasicButton = css`
  --basicColor: var(--darkBlue);

  background-color: var(--basicColor);
  border: var(--basicColor);
  color: var(--white);
  font-weight: normal;

  :hover {
    --basicColor: var(--blueMiddle);
  }

  :disabled {
    --basicColor: #f7f8ff;
    color: #b9cbdf;
  }
`;

const OutlineButton = css`
  border: 2px solid var(--grayMiddle);
  color: var(--grayDark);

  :hover {
    color: var(--blueMiddle);
    border-color: var(--blueMiddle);
  }
  :disabled {
    color: #b9cbdf;
    border-color: #b9cbdf;
  }
`;

export const Button = styled.button<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  
  :disabled {
    cursor: auto;
  }

  height: 40px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  padding: 0 16px;
  
  ${props => (!props.outline ? BasicButton : OutlineButton)};
  
  ${props =>
    props.secondary &&
    css`
      --basicColor: #dfe8f6;

      background-color: #dfe8f6;
      border: #dfe8f6;
      color: #172549;
    `}
  

  &:focus {
    outline: none;
  }

  ${props =>
    props.rounded &&
    css`
      border-radius: 128px;
    `}
  
  ${props =>
    props.small &&
    css`
      height: 32px;
    `}
  
  ${props =>
    props.medium &&
    css`
      height: 48px;
      padding: 0 24px;
      font-size: 16px;
      line-height: 1.5;
    `}
  
  ${props =>
    props.large &&
    css`
      height: 56px;
      padding: 0 32px;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
    `}
`;
