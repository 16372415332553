import { useContext } from 'react';
import { context as ReatomContext } from '@reatom/react';
import { PayloadActionCreator } from '@reatom/core';

import { call } from './call';

export function useAsyncAction<T, R = undefined>(
  action: PayloadActionCreator<T>,
): (arg: T) => Promise<R> {
  const store = useContext(ReatomContext);
  return arg => call(store, action(arg));
}

export function useDispatch() {
  const { dispatch } = useContext(ReatomContext);
  return dispatch;
}
