import { useAction, useAtom } from '@reatom/react';
import { useMemo } from 'react';

import { useListItemData, useListItemsData } from 'libs/reatom-toolkit/useList';
import { declareAsyncStatusAtom } from 'libs/reatom-toolkit';

import { DialogsList } from './dialogs.model';
import { MessagesList } from './messages.model';

export function useDialog(dialogId, { getMessagesAction }) {
  const dialog = useListItemData(DialogsList, dialogId);
  const messages = useListItemsData(MessagesList, dialog.messagesIds);

  const getNext = useAction(getMessagesAction);
  const isLoading = useAtom(
    useMemo(() => declareAsyncStatusAtom('is Loading', getMessagesAction), []),
  );

  function getNextPage(params) {
    if (!isLoading) {
      getNext({
        dialogId,
        lastMessageId: messages[messages.length - 1]?.id,
        ...params,
      });
    }
  }

  return {
    isLoading,
    messages,
    getNextPage,
  };
}
