import { useAtom } from '@reatom/react';

import { List } from './declare-list';
import { useAsyncAction } from './useAsyncAction';

export function useList<T>(list: List<T>) {
  const [atom, actions] = list;
  const { ids, data } = useAtom(atom);

  const addList = useAsyncAction(actions.addList);
  const add = useAsyncAction(actions.add);
  const remove = useAsyncAction(actions.remove);
  const edit = useAsyncAction(actions.edit);

  return {
    ids,
    data,
    add,
    addList,
    remove,
    edit,
  };
}

export function useListItemData<T>(list: List<T>, id: string | number) {
  const [atom] = list;
  const { data } = useAtom(atom);
  return data[id];
}

export function useListItemsData<T>(
  list: List<T>,
  ids: Array<string | number>,
) {
  const [atom] = list;
  return useAtom(atom, ({ data }) => ids.map(id => data[id]), [ids]);
}
