import React from 'react';

import { DatePickerField } from './date-picker-field';

interface Props {
  name: string;
  placeholder?: string;
  minAge?: number;
}

export function BirthdayField(props: Props) {
  const minAge = props.minAge ? props.minAge : 0;
  let maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() - minAge);
  return (
    <DatePickerField
      {...props}
      placeholder={props.placeholder}
      defaultView="decade"
      maxDate={maxDate}
    />
  );
}
