import axios, { AxiosInstance } from 'axios';

const baseURL = process.env.BASE_API_URL || 'https://api.pawzr.devvela.net';

export const baseConfig = {
  baseURL: `${baseURL}/api/public/`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
};

export const publicApi = axios.create(baseConfig);

const wsUrl = baseURL.replace('https://', 'wss://');

export function createSocket(path) {
  const ws = new WebSocket(`${wsUrl}${path}`);

  return ws;
}

export let privateApi: AxiosInstance = axios;

export function getPrivateApi(getToken) {
  const config = {
    baseURL: `${baseURL}/api/private/`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  };

  privateApi = axios.create(config);

  publicApi.interceptors.request.use(config => {
    const token = getToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${getToken()}`;
    }

    return config;
  });

  privateApi.interceptors.request.use(config => {
    config.headers['Authorization'] = `Bearer ${getToken()}`;
    return config;
  });

  return privateApi;
}
