import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { dayjs } from 'libs/dayjs-ext/dayjs-ext';
import { Form } from 'libs/forms';
import { InputField } from 'ui/ecosystems/forms';
import { adaptive, useAdaptive } from 'ui/styles/addaptive-provider';
import { Dialog, InterlocutorType } from 'features/messages';
import { PetAvatar, POAvatar, SPAvatar } from 'ui/ecosystems/users';
import { useElementOnScreen } from 'libs/use-element-on-screen';
import { useAsyncAction } from 'libs/reatom-toolkit';
import { useAsyncCallback } from 'libs/useAsyncCallback';
import { DownloadAble } from 'ui/organisms/downloadable';

import { Message } from '../../messages.model';
import { ReactComponent as BackArrow } from './arrow-left.svg';
import { ReactComponent as SendIcon } from './send.svg';
import { ReactComponent as ClipIcon } from './clip.svg';
import FileIcon from './file.png';

import { ReadMessageAction } from '../../dialogs.model';

interface Props {
  dialog: Dialog;
  messages: Message[];
  getNextPage(): void;
  getIsOwnMessage(messages: Message): boolean;
  onPostMessage?(message: string): Promise<void>;
  onAttachmentsButtonClick?(): void;
}

const AvatarComponentsMap = {
  [InterlocutorType.serviceOwner]: SPAvatar,
  [InterlocutorType.pet]: PetAvatar,
  [InterlocutorType.petOwner]: POAvatar,
};

export function DialogTemplate(props: Props) {
  async function handleFormSubmit({ newMessage }, form) {
    setTimeout(() => {
      form.reset();
    });

    props.onPostMessage(newMessage);
  }

  const isAdaptive = useAdaptive();
  const [scrollPosition, setScrollPosition] = useState(0);

  if (!props.dialog) return null;
  return (
    <DialogContent withoutForm={!props.onPostMessage}>
      {isAdaptive && (
        <MobileHeader>
          <BackButton as={Link} to="/messages">
            <BackArrow />
          </BackButton>

          <Profile>
            <Avatar
              as={AvatarComponentsMap[props.dialog.interlocutor.type]}
              avatar={props.dialog.interlocutor.avatar}
            />
            <ProfileName>{props.dialog.interlocutor.name}</ProfileName>
          </Profile>
        </MobileHeader>
      )}
      {props.messages.length === 0 ? (
        <Empty>No messages here yet</Empty>
      ) : (
        <Scroll
          dataKey={props.messages}
          onScrolledToTop={props.getNextPage}
          onChangeScroll={setScrollPosition}
        >
          {props.messages.map(message => (
            <SingleMessage
              scrollPosition={scrollPosition}
              dialogId={props.dialog.id}
              interlocutor={
                !props.getIsOwnMessage(message) && props.dialog.interlocutor
              }
              key={message.key || message.id}
              message={message}
              own={props.getIsOwnMessage(message)}
            />
          ))}
        </Scroll>
      )}

      {props.onPostMessage && (
        <MessageForm>
          <Form
            onSubmit={handleFormSubmit}
            render={({ handleSubmit }) => (
              <SendForm onSubmit={handleSubmit}>
                {props.onAttachmentsButtonClick && (
                  <AttachmentsButton onClick={props.onAttachmentsButtonClick} />
                )}
                <InputField name="newMessage" placeholder="Type message..." />
                <SendButton onClick={handleSubmit} />
              </SendForm>
            )}
          />
        </MessageForm>
      )}
    </DialogContent>
  );
}

const BackButton = styled.div``;

const SendButton = styled(SendIcon)`
  display: none;
  width: 32px;
`;

const AttachmentsButton = styled(ClipIcon)`
  transform: rotate(-45deg);
  width: 42px;
  height: 42px;

  path {
    fill: rgba(100, 150, 200, 0.5);
  }
`;

const SendForm = styled.form`
  display: flex;
  align-items: center;
  ${AttachmentsButton} {
    margin-right: 10px;
  }

  ${adaptive} {
    ${SendButton} {
      display: block;
      margin-left: 10px;
    }
  }
`;

const MobileHeader = styled.div`
  border: 1px solid #dfe8f6;
  display: flex;
  align-items: center;
  padding: 0 15px;

  ${BackButton} {
    margin-right: 15px;
  }
`;

const Messages = styled.div`
  padding: 0 var(--padding) 0;
  display: flex;
  flex-direction: column-reverse;
  min-height: 100%;

  :after {
    content: '';
    display: block;
    flex: 1 1 auto;
  }
  & > * {
    margin-bottom: 16px;
  }
`;

const MessageForm = styled.div`
  padding: var(--padding);

  input {
    width: 100%;
  }

  ${adaptive} {
    padding: 5px 15px;
  }
`;

const DialogContent = styled.div`
  --padding: 26px;
  height: 100%;
  position: relative;
  display: grid;
  grid-template-rows: 1fr 88px;

  ${Messages} {
    > * :last-child {
      padding-top: 20px;
    }
  }

  ${MessageForm} {
  }

  ${adaptive} {
    grid-template-rows: 60px 1fr 50px;
  }

  ${props =>
    props.withoutForm &&
    css`
      grid-template-rows: 1fr;

      ${adaptive} {
        grid-template-rows: 60px 1fr;
      }
    `}
`;

const Avatar = styled.div`
  width: 40px;
  height: 40px;
`;

const ProfileName = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
`;

const Profile = styled.div`
  display: flex;
  align-items: center;
  ${Avatar} {
    margin-right: 9px;
  }
`;

function SingleMessage(props: {
  message: Message;
  own?: boolean;
  isAttachment?: boolean;
  interlocutor?: {
    type: InterlocutorType;
    name: string;
    avatar?: string;
  };
}) {
  const message = props.message;
  const isAttachment = Boolean(message.fileId);
  const isAdaptive = useAdaptive();
  const ref = useRef();
  const read = useAsyncAction(ReadMessageAction);
  const [readMessage, loading] = useAsyncCallback(read);
  const onScreen = useElementOnScreen(ref);

  useEffect(() => {
    if (!props.own && onScreen && message.is_read === false && !loading) {
      readMessage({
        messageId: message.id,
        dialogId: props.dialogId,
      });
    }
  }, [onScreen, message.is_read, loading]);

  return (
    <SingleMessageRoot own={props.own} ref={ref}>
      <MessageContent>
        {!isAdaptive && props.interlocutor && (
          <MessageAvatar
            as={AvatarComponentsMap[props.interlocutor.type]}
            avatar={props.interlocutor.avatar}
          />
        )}
        <DownloadAble url={message.url} fileId={message.fileId}>
          <MessageBody isAttachment={isAttachment}>
            <DocIcon />
            <MessageText>{message.body}</MessageText>
          </MessageBody>
        </DownloadAble>
      </MessageContent>

      <MessageTime>
        {dayjs(message.timestamp).format('DD MMM YYYY  hh:mm')}
      </MessageTime>
    </SingleMessageRoot>
  );
}

const Empty = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  line-height: 32px;

  color: #dfe8f6;

  ${adaptive} {
    font-size: 18px;
  }
`;

const MessageTime = styled.div`
  font-size: 12px;
  line-height: 16px;

  color: #a1b3ce;
`;

const DocIcon = styled.div`
  display: none;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  background: url(${FileIcon}) no-repeat center;
  background-size: contain;
  ${adaptive} {
    width: 32px;
    height: 32px;
  }
`;

const MessageText = styled.div`
  font-size: 14px;
  line-height: 21px;
`;

const MessageBody = styled.div<{ isAttachment: boolean }>`
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 5px 16px 5px 11px;
  background-color: #f7f8ff;
  width: 396px;
  max-width: 100%;
  ${adaptive} {
    width: 300px;
  }
  ${DocIcon} {
    margin-right: 5px;
  }
  ${props =>
    props.isAttachment &&
    css`
      padding-left: 5px !important;
      background-color: rgba(0, 0, 0, 0) !important;
      color: #384d9e !important;
      border: 1px solid #384d9e;
      cursor: pointer;
      :hover {
        background-color: rgba(196, 196, 196, 0.25) !important;
      }
      ${DocIcon} {
        display: block;
      }
    `}
`;

const MessageAvatar = styled.div`
  width: 32px;
  height: 32px;
`;

const MessageContent = styled.div`
  display: flex;
  align-items: flex-end;

  ${MessageAvatar} {
    margin-right: 8px;
  }
`;

const SingleMessageRoot = styled.div<{ own: boolean }>`
  display: flex;
  flex-direction: column;

  ${props =>
    props.own &&
    css`
      align-items: flex-end;

      ${MessageBody} {
        padding-left: 16px;
        background-color: #344ca4;
        color: #fff;
      }
    `};

  ${props =>
    !props.own &&
    css`
      ${MessageTime} {
        margin-left: 44px;
        ${adaptive} {
          margin-left: 0;
        }
      }
    `}
`;

class Scroll extends React.PureComponent {
  element = React.createRef();
  savedScrollPosition = 0;

  componentDidMount() {
    this.handleScroll();
    setTimeout(() => {
      this.setScrollToBottom();
      window.addEventListener('resize', this.keepScrollPosition);
    }, 3000);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dataKey !== this.props.dataKey) {
      this.keepScrollPosition();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.keepScrollPosition);
  }

  setScrollToBottom() {
    const element = this.element.current;
    if (element) {
      element.scrollTop = element.scrollHeight - element.clientHeight;
      this.savedScrollPosition = 0;
    }
  }

  keepScrollPosition = () => {
    const element = this.element.current;
    if (element) {
      element.scrollTop =
        element.scrollHeight - element.offsetHeight + this.savedScrollPosition;
    }
  };

  handleScroll = () => {
    const element = this.element.current;
    this.savedScrollPosition =
      element.scrollTop + element.offsetHeight - element.scrollHeight;

    if (element.scrollTop <= element.offsetHeight || element.scrollTop === 0) {
      this.props.onScrolledToTop();
    }

    this.props.onChangeScroll(element.scrollTop);
  };

  render() {
    // eslint-disable-next-line no-unused-vars
    const { children, dataKey, onScrolledToTop, ...props } = this.props;
    return (
      <ScrollRoot ref={this.element} onScroll={this.handleScroll} {...props}>
        <Messages>{children}</Messages>
      </ScrollRoot>
    );
  }
}

const ScrollRoot = styled.div`
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`;
