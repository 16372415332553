import React, { useContext } from 'react';

export const FormNestedContext = React.createContext<string>('');

export function useNestedName(name: string = '') {
  const nestedFormName = useContext(FormNestedContext);
  return !nestedFormName ? name : `${String(nestedFormName)}.${name}`;
}

type NestedFormProps = {
  name?: string;
  children: React.ReactNode;
};

export const NestedForm = (props: NestedFormProps) => {
  const nestedName = useNestedName(props.name);

  return (
    <FormNestedContext.Provider value={props.name === '' ? '' : nestedName}>
      {props.children}
    </FormNestedContext.Provider>
  );
};
