const appHeightUpdate = () => {
  if (process.env.BROWSER) {
    const doc = document.documentElement;
    doc.style.setProperty('--appHeight', `${window.innerHeight}px`);
    doc.style.setProperty('--vh', `${window.innerHeight / 100}px`);
  }
};

export const autoAppHeightUpdate = () => {
  appHeightUpdate();
  window.addEventListener('resize', appHeightUpdate);
};
