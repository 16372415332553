import styled from 'styled-components';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: var(--modalOverlay);
  background: rgba(23, 37, 73, 0.8);
  overflow: auto;
`;
