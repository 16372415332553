import { useState } from 'react';

export function useAsyncCallback<
  T,
  I extends any[],
  F extends (...args: I) => Promise<T>
>(func: F): [F, boolean] {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  async function returnedFunction(...args: I): Promise<T> {
    setIsSubmitting(true);
    try {
      return await func(...args);
    } finally {
      setIsSubmitting(false);
    }
  }

  // @ts-ignore
  return [returnedFunction, isSubmitting];
}
