import React from 'react';

interface Props {
  renderHeader?: () => React.ReactNode;
  renderContent?(): React.ReactNode;
  children?: React.ReactNode;
  renderFooter?: () => React.ReactNode;
}

export function PageTemplate(props: Props) {
  return (
    <React.Fragment>
      {props.renderHeader && props.renderHeader()}
      {props.renderContent ? props.renderContent() : props.children}
      {props.renderFooter && props.renderFooter()}
    </React.Fragment>
  );
}
