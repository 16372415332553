import { declareAction, declareAtom, Reaction } from '@reatom/core';

export function declareState<State>(
  name: string | [string],
  defaultValue?: State,
  reaction?: Reaction<State> = () => {},
  dep = () => [],
) {
  const actionName = Array.isArray(name) ? `set ${name[0]}` : `set ${name}`;

  const setAction = declareAction<State, State>(actionName, reaction);
  const atom = declareAtom<State>(name, defaultValue, on => [
    on(setAction, (_, payload) => payload),
    ...dep(on),
  ]);

  return [atom, setAction];
}
