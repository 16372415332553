import React, { useRef, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { useQueryField } from 'libs';
import { useQueryParams } from 'libs/router';
import { useGeolocation } from 'libs/react-use-geolocation';
import { PublicPageTemplate } from 'ui/templates/public-page-template/public-page-template';
import { placeholderCss } from 'ui/ecosystems/forms/atoms/input-wrapper';
import { Button } from 'ui';
import { InputWrapper } from 'ui/ecosystems/forms';
import { DropdownTemplate } from 'ui/ecosystems/dropdown';
import { SelectAbleItem } from 'ui/ecosystems/dropdown/molecules/selectable-item/selectable-item';
import { LoadableContent } from 'ui/templates/loadable-content';
import {
  adaptive,
  AdaptiveProvider,
  useAdaptive,
} from 'ui/styles/addaptive-provider';
import { useServiceTypes } from 'services/service-types';

import { SearchByService } from './byServiceType/service-providers-by-servcie-type.page';
import { CategoryName } from './components/category-name';
import { CompaniesList } from './components/companies-list';
import { ServicesOnMap } from './components/servcies-on-map';
import { ReactComponent as CardViewIcon } from './card-view-icon.svg';
import { ReactComponent as MapViewIcon } from './map-view-icon.svg';
import { ReactComponent as SearchIcon } from './search-icon.svg';

type Props = {
  headerImage: string;
  renderTitle: React.ReactNode;
  get(): void;
  isLoading: boolean;
  data: any[];
  services: any[];
};

export function SearchPageTemplate(props: Props) {
  const [params] = useQueryParams();

  return (
    <AdaptiveProvider mobile={768}>
      <PublicPageTemplate>
        <Header headerImageUrl={props.headerImage}>{props.renderTitle}</Header>
        <PageContent>
          <Content>
            <Search services={props.services} />
            {params.serviceType ? (
              <SearchByService services={props.services} />
            ) : (
              <DefaultSearch
                get={props.get}
                isLoading={props.isLoading}
                data={props.data}
              />
            )}
          </Content>
        </PageContent>
      </PublicPageTemplate>
    </AdaptiveProvider>
  );
}

const Categories = styled.div`
  display: grid;
  grid-gap: 80px;
`;

const Header = styled.div<{ headerImageUrl: string }>`
  height: 400px;
  background-image:  url(${props => props.headerImageUrl});
  background-size: cover;
  background-position: center;
  ${adaptive} {
    height: 320px;
    `;

const Content = styled.div`
  width: var(--contentWidth);
  margin: 0 auto;

  ${Categories} {
    margin-top: 80px;
  }

  ${adaptive} {
    ${Categories} {
      margin-top: 40px;
    }
  }
`;

const PageContent = styled.div`
  min-height: calc(100vh - 400px);
  background-color: #a1b3ce;
  padding-bottom: 120px;
`;

function Search(props) {
  const [params, set] = useQueryParams();
  const inputRef = useRef();

  function handleSubmit(e) {
    e.preventDefault();
    const search = inputRef.current.value;
    set({ search });
  }

  const isAdaptive = useAdaptive();

  return (
    <SearchRoot as="form" onSubmit={handleSubmit}>
      <SearchInput middle={!isAdaptive}>
        <input
          type="search"
          placeholder="Search Services, Cities, Places.."
          ref={inputRef}
          name="search"
          defaultValue={params.search}
        />
        {!isAdaptive && <Dropdown services={props.services} />}
      </SearchInput>
      <Button medium={!isAdaptive}>
        <SearchIcon />
        <SearchLabel>Search</SearchLabel>
      </Button>
    </SearchRoot>
  );
}

const SearchLabel = styled.div`
  ${adaptive} {
    display: none;
  }
`;

const SearchInput = styled(InputWrapper)`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 10px;

  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
`;

const SearchRoot = styled.div`
  position: relative;
  z-index: 1;
  transform: translateY(-50%);
  display: grid;
  grid-template-columns: 1fr 168px;
  grid-gap: 0;

  box-shadow: 0 16px 24px rgba(104, 130, 159, 0.24);

  input {
    border: none;
    padding: 0;
    background: transparent;
    height: 100%;
    :focus {
      outline: none;
      background: transparent;
    }

    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
    }

    ::placeholder {
      ${placeholderCss}
    }
  }

  ${InputWrapper} {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-color: transparent;
  }

  ${Button} {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  ${adaptive} {
    grid-template-columns: 1fr 40px;
    ${Button} {
      padding: 0;
    }
  }
`;

function Category(props) {
  const services = useServiceTypes();
  const service = services.find(s => s.id === props.serviceId);

  const [viewType, setViewType] = useState<'card' | 'map'>('card');

  return (
    <CategoryRoot>
      <CategoryHeader>
        <CategoryName service={service} count={props.count} />
        <ViewTypeSwitcher>
          <Type
            isActive={viewType === 'card'}
            onClick={() => setViewType('card')}
          >
            <CardViewIcon />
            <TypeLabel>Card view</TypeLabel>
          </Type>
          <Type
            isActive={viewType === 'map'}
            onClick={() => setViewType('map')}
          >
            <MapViewIcon />
            <TypeLabel>Map View</TypeLabel>
          </Type>
        </ViewTypeSwitcher>
      </CategoryHeader>

      {viewType === 'card' && <CompaniesList items={props.items} />}
      {viewType === 'map' && (
        <ServicesOnMap serviceType={props.serviceId} search={props.search} />
      )}
    </CategoryRoot>
  );
}

const TypeLabel = styled.div``;

const Type = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  color: #172549;
  font-size: 16px;
  line-height: 24px;

  .icon {
    fill: #172549;
  }

  ${TypeLabel} {
    margin-left: 8px;
  }

  ${props =>
    !props.isActive &&
    css`
      cursor: pointer;
      color: #344ca4;

      .icon {
        fill: #344ca4;
      }
    `}

  ${adaptive} {
    ${TypeLabel} {
      display: none;
    }
  }
`;

const ViewTypeSwitcher = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 24px;

  ${adaptive} {
    grid-gap: 10px;
  }
`;

const CategoryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CategoryRoot = styled.div`
  ${CategoryHeader} {
    margin-bottom: 32px;
  }

  ${adaptive} {
    margin-bottom: 16px;
  }
`;

function DefaultSearch(props) {
  const [{ search }] = useQueryParams();
  const geoLocation = useGeolocation();

  const { get, isLoading, data } = props;

  const perPage = useAdaptive() ? 4 : 6;

  useEffect(() => {
    if (geoLocation) {
      get({
        search,
        geoLocation,
      });
    }
  }, [search, geoLocation]);

  if (data.length === 0 && !isLoading)
    return <NoResultImage>No Results</NoResultImage>;

  return (
    <LoadableContent isLoading={isLoading}>
      <Categories>
        {data.map(list => (
          <Category
            search={search}
            key={list.service_type.id}
            count={list.results_count}
            serviceId={list.service_type.id}
            items={list.service_owners.slice(0, perPage)}
          />
        ))}
      </Categories>
    </LoadableContent>
  );
}

function Dropdown(props) {
  const { services } = props;

  const [serviceTypeId, setServiceTypeId] = useQueryField('serviceType', {
    parse: parseInt,
  });
  const labelAllServiceTypes = 'All Service Types';

  const currentLabel =
    services.find(s => s.id === serviceTypeId)?.title ?? labelAllServiceTypes;

  return (
    <SearchDropdown>
      <DropdownTemplate
        renderLabel={() => currentLabel}
        renderPopupContent={() => (
          <React.Fragment>
            {currentLabel !== labelAllServiceTypes && (
              <SelectAbleItem onClick={() => setServiceTypeId()}>
                {labelAllServiceTypes}
              </SelectAbleItem>
            )}
            {services.map(service => (
              <SelectAbleItem
                key={service.id}
                onClick={() => setServiceTypeId(service.id)}
              >
                {service.title}
              </SelectAbleItem>
            ))}
          </React.Fragment>
        )}
      />
    </SearchDropdown>
  );
}

const SearchDropdown = styled.div`
  color: #344ca4;
  --arrowColor: #344ca4;

  ${adaptive} {
    display: none;
  }
`;

const NoResultImage = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #f7f8ff;

  text-align: center;
  margin-top: 100px;
`;
