import { useMediaQuery } from 'react-responsive';

export const createMediaMaxWidth = width => `@media (max-width: ${width}px)`;
export const createMediaMinWidth = width => `@media (min-width: ${width}px)`;

export const screenPhone = 767;
export const screenTablet = 768;
export const mediaPhone = createMediaMaxWidth(screenPhone);
export const mediaTablet = createMediaMaxWidth(screenTablet);

export function useMedia() {
  const isTablet = useMediaQuery({
    query: `(max-width: ${screenTablet}px)`,
  });
  const isPhone = useMediaQuery({
    query: `(max-width: ${screenPhone}px)`,
  });
  return { isTablet, isPhone };
}
