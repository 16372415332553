import { declareAtom } from '@reatom/core';

import { call, declareAsyncAction, useAsyncAction } from 'libs/reatom-toolkit';
import { Image } from 'types';
import { privateApi, publicApi } from 'api';
import { useAtom } from '@reatom/react';

import { dayjs, removeTimeZone } from 'libs/dayjs-ext';

import { useEffect } from 'react';

import { clearTokenAction, setTokenAction } from '../authentication/auth.model';
import { useGeolocation } from '../../libs/react-use-geolocation';

type CurrentPetOwnerProfile = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  phoneNumber: string;
  zipCode: string;
  birthday: string;
  avatar?: Image;
  coverImage?: Image;
  latitude?: number;
  longitude?: number;
};

interface PetOwnerApiResponse {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  zip_code: string;
  birthday: string;
  avatar?: Image;
  hero_image?: Image;
  latitude?: number;
  longitude?: number;
}

export const petOwnerAuthAction = declareAsyncAction<
  string,
  { email: string; password: string }
>('get token', async (authData, store) => {
  const {
    data: { token },
  } = await publicApi.post('/pet-owner/auth', authData);
  store.dispatch(setTokenAction(token));
  await call(store, getProfileAction());
  return token;
});

export const getProfileAction = declareAsyncAction<CurrentPetOwnerProfile>(
  'Current Pet Owner | get profile',
  async () => {
    const { data } = await privateApi.get<PetOwnerApiResponse>(
      '/common/user/self-profile',
    );
    return parsePetOwnerProfile(data);
  },
);

export interface ProfileApiInput {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  zip_code: string;
  plain_password?: string;
  current_password?: string;
  birthday: string;
  avatar_id?: number;
  hero_image_id?: number;
}

const updateProfileActions = declareAsyncAction<ProfileApiInput>(
  'Current Pet Owner | update profile',
  async profile => {
    const { data } = await privateApi.put<PetOwnerApiResponse>(
      '/pet-owner/user/self-profile',
      profile,
    );
    return parsePetOwnerProfile(data);
  },
);

export const currentPetOwnerAtom = declareAtom<CurrentPetOwnerProfile>(
  ['Current Pet Owner'],
  {} as CurrentPetOwnerProfile,
  on => [
    on(clearTokenAction, () => ({} as CurrentPetOwnerProfile)),
    on(getProfileAction.done, (_, payload) => payload),
    on(updateProfileActions.done, (_, payload) => payload),
  ],
);

export function usePetOwnerSignInService() {
  return {
    authAsPetOwner: useAsyncAction(petOwnerAuthAction),
  };
}

export function useCurrentPetOwnerProfile(): {
  profile: CurrentPetOwnerProfile;
  updateProfile(data: ProfileApiInput): Promise<void>;
} {
  return {
    profile: useAtom(currentPetOwnerAtom),
    updateProfile: useAsyncAction(updateProfileActions),
  };
}

export function useCurrentPetOwnerCoordinates() {
  const geoLocation = useGeolocation();

  async function setCoordinates(geoLocation) {
    await privateApi.put('/pet-owner/user/coordinates', geoLocation);
  }
  useEffect(() => {
    if (geoLocation?.latitude && geoLocation?.longitude) {
      setCoordinates(geoLocation).then(() => {});
    }
  }, [geoLocation]);
}

export function parsePetOwnerProfile(
  data: PetOwnerApiResponse,
): CurrentPetOwnerProfile {
  return {
    id: data.id,
    email: data.email,
    firstName: data.first_name,
    lastName: data.last_name,
    fullName: `${data.first_name} ${data.last_name}`,
    phoneNumber: data.phone_number,
    zipCode: data.zip_code,
    birthday: removeTimeZone(data.birthday),
    avatar: data.avatar,
    coverImage: data.hero_image,
    latitude: data.latitude,
    longitude: data.longitude,
  };
}

export function formatPetOwner(
  profile: CurrentPetOwnerProfile,
): ProfileApiInput {
  return {
    first_name: profile.firstName,
    last_name: profile.lastName,
    email: profile.email,
    phone_number: profile.phoneNumber,
    zip_code: profile.zipCode,
    birthday: dayjs(profile.birthday).format('YYYY-MM-DD'),
    avatar_id: profile.avatar?.id ?? null,
    hero_image_id: profile.coverImage?.id ?? null,
  };
}
