import React from 'react';

import { ModalWindowTemplate } from 'ui/ecosystems/modals';
import { ModalTabs, Tab } from 'ui/ecosystems/navigation/modals-tabs';

import { PublicProfileForm } from '../public-profile-form/public-profile-form';
import { AccountSettingForm } from '../account-settings-form/account-settings-form';
import { PasswordChangeForm } from '../change-password-form';

interface Props {
  isOpened: boolean;
  onClose(): void;
}

export function ServiceProviderEditProfileModal(props: Props) {
  return (
    <ModalWindowTemplate
      isOpened={props.isOpened}
      onClose={props.onClose}
      title="Edit Profile"
      renderContent={() => (
        <React.Fragment>
          <ModalTabs>
            <Tab id="public" renderContent={() => <PublicProfileForm />}>
              Public Profile
            </Tab>
            <Tab id="Account" renderContent={() => <AccountSettingForm />}>
              Account Settings
            </Tab>
            <Tab id="Password" renderContent={() => <PasswordChangeForm />}>
              Password
            </Tab>
          </ModalTabs>
        </React.Fragment>
      )}
    />
  );
}
