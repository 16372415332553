import {
  Atom,
  declareAtom,
  PayloadActionCreator,
  Store,
  combine,
} from '@reatom/core';

import {
  AsyncActionsCreator,
  declareAsyncAction,
  declareAsyncStatusAtom,
} from 'libs/reatom-toolkit/declareAsyncAction';

export interface Resource<T, V = undefined> extends Atom<T> {
  isLoading: Atom<boolean>;
  get: AsyncActionsCreator<T, V>;
  error: Atom<unknown>;
}

type Reducer<TState, TValue> = (state: TState, value: TValue) => TState;
interface DependencyMatcherOn<TState> {
  <T>(dependency: Atom<T>, reducer: Reducer<TState, T>): void;
  <T>(dependency: PayloadActionCreator<T>, reducer: Reducer<TState, T>): void;
  <T>(
    dependency: Atom<T> | PayloadActionCreator<T>,
    reducer: Reducer<TState, T>,
  ): void;
}
export type DependencyMatcher<TState> = (
  on: DependencyMatcherOn<TState>,
) => any;

export function declareResource<T, V = undefined>(
  name: string,
  initialState: T,
  fetcher: (variables: V, store: Store) => Promise<T>,
  dependencyMatcher: DependencyMatcher<T> = () => [],
): Resource<T, V> {
  const asyncAction = declareAsyncAction<T, V>(name, fetcher);

  // @ts-ignore
  const resource: Resource<T> = declareAtom<T>([name], initialState, on => [
    on(asyncAction.done, (_, payload) => payload),
    ...dependencyMatcher(on),
  ]);

  resource.isLoading = declareAsyncStatusAtom(
    [`${name} is loading`],
    asyncAction,
    true,
  );

  resource.error = declareAtom([`${name} error`], null, on => [
    on(asyncAction.fail, (_, error) => error),
  ]);

  resource.get = asyncAction;

  return resource;
}

export function resource(resource: Resource<unknown>) {
  return combine({
    resource: resource,
    isLoading: resource.isLoading,
  });
}
