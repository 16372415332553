import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { useQueryParams } from 'libs/router';
import { useGeolocation } from 'libs/react-use-geolocation';
import { useResource } from 'libs/reatom-toolkit';
import { useElementOnScreen } from 'libs/use-element-on-screen';
import { LoadableContent } from 'ui/templates/loadable-content';

import { SearchByServiceTypeResource } from './search-by-service-type.model';
import { CategoryName } from '../components/category-name';
import { CompaniesList } from '../components/companies-list';
import { ServicesOnMap } from '../components/servcies-on-map';

//todo: remove model from template

export function SearchByService(props) {
  const [{ serviceType, search }] = useQueryParams();
  const geoLocation = useGeolocation();
  const services = props.services;

  const {
    data: { list, count, page },
    isLoading,
    get,
  } = useResource(SearchByServiceTypeResource);

  useEffect(() => {
    if (geoLocation) {
      get({
        serviceType,
        search,
        ...geoLocation,
      });
    }
  }, [search, geoLocation, serviceType]);

  const service = services.find(
    service => service.id === parseInt(serviceType),
  );

  const loadMoreTriggerRef = useRef<HTMLDivElement>();
  const scrolledToBottom = useElementOnScreen(loadMoreTriggerRef);

  useEffect(() => {
    if (scrolledToBottom && !isLoading && list.length < count) {
      get({
        serviceType,
        search,
        ...geoLocation,
        page: page + 1,
      });
    }
  }, [scrolledToBottom]);

  return (
    <>
      <LoadableContent isLoading={isLoading && list.length === 0}>
        <Root>
          <CategoryName service={service} count={count} />
          <Content>
            <ServicesOnMap search={search} serviceType={serviceType} />
            <CompaniesList items={list} />
          </Content>
        </Root>
      </LoadableContent>

      <LoadableContent isLoading={isLoading && list.length > 0} />
      <div ref={loadMoreTriggerRef} />
    </>
  );
}

const Content = styled.div`
  display: grid;
  grid-gap: 40px;
`;

const Root = styled.div`
  margin-top: 80px;
  ${CategoryName} {
    margin-bottom: 40px;
  }
`;
