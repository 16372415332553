import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { useAtom } from '@reatom/react';

import { ReactComponent as EmptyIcon } from './icon.svg';
import { DialogsList } from '../../../dialogs.model';

export function MessagesIcon() {
  const count = useAtom(
    DialogsList[0],
    ({ data }) =>
      Object.values(data).reduce((sum, dialog) => sum + dialog.unreadCount, 0),
    [],
  );

  return (
    <MessageIconRoot as={Link} to="/messages" count={count}>
      <EmptyIcon />
    </MessageIconRoot>
  );
}

const MessageIconRoot = styled.div<{ count: number }>`
  position: relative;
  top: 4px;
  align-self: center;

  cursor: pointer;

  ${props =>
    props.count > 0
      ? css`
          :before {
            position: absolute;
            content: '';
            display: block;
            background: #eb5757;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            border: 2px solid #fff;
            top: 0;
            right: 0;
            transform: translate(25%, -25%);
          }

          .icon {
            fill: #344ca4;
          }
        `
      : css`
          :hover {
            .icon {
              fill: #344ca4;
            }
          }
        `}
`;
