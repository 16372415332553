import { privateApi } from 'api';

import { useAtom } from '@reatom/react';

import { declareResource, useResource } from '../../libs/reatom-toolkit';

const petsDocuments = declareResource('Pets Documents', [], async () => {
  const { data } = await privateApi.get('/pet-owner/pet-cards/documents/all');
  return data;
});

export function usePetsDocuments() {
  const { get, isLoading, error } = useResource(petsDocuments);
  return {
    isLoading,
    error,
    getPetsDocuments: get,
    petsDocuments: useAtom(petsDocuments),
  };
}
