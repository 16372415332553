import { useEffect } from 'react';

import { useMessagesSubscription } from './useSubscription';
import { useResource } from '../../libs/reatom-toolkit';

export function useMessages(resource, { getDialogId }) {
  const { get } = useResource(resource);

  useMessagesSubscription(getDialogId);

  useEffect(() => {
    get();
  }, []);
}
