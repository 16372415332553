import React from 'react';
import { Route, Switch } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import { useCurrentProfile } from 'features/profile';
import {
  PetOwnerDialogsIdsResource,
  PODialog,
} from 'features/pet-owner-messages';
import {
  ServiceProviderMessages,
  SPDialog,
} from 'features/service-provider-messages';
import { useResource } from 'libs/reatom-toolkit';
import { adaptive, AdaptiveProvider } from 'ui/styles/addaptive-provider';

import { PreviewDialog } from 'features/messages';
import { InternalPageTemplate } from 'ui/templates';
import { Icon } from 'ui/atoms/icon';

import { LoadableContent } from 'ui/templates/loadable-content';

import messageIcon from './message-icon.svg';

const breakPoint = 768;

export function MessagesPage() {
  const { isPetOwner } = useCurrentProfile();

  const messagesResource = isPetOwner
    ? PetOwnerDialogsIdsResource
    : ServiceProviderMessages;

  const DialogComponent = isPetOwner ? PODialog : SPDialog;

  const { data: dialogsIds, isLoading } = useResource(messagesResource);

  const isMobile = useMediaQuery({ maxWidth: breakPoint });

  return (
    <AdaptiveProvider mobile={breakPoint}>
      <InternalPageTemplate noFooter isFull>
        <LoadableContent isLoading={isLoading}>
          {dialogsIds.length === 0 ? (
            <EmptyList />
          ) : isMobile ? (
            <Switch>
              <Route
                exact
                path="/messages"
                render={() => (
                  <Dialogs>
                    {dialogsIds.map(id => (
                      <DialogsItem key={id} as={NavLink} to={`/messages/${id}`}>
                        <PreviewDialog dialogId={id} />
                      </DialogsItem>
                    ))}
                  </Dialogs>
                )}
              />
              <Route
                path="/messages/:dialogId"
                render={({ match }) => (
                  <MobileDialog>
                    <DialogComponent
                      dialogId={match.params.dialogId}
                      key={match.params.dialogId}
                    />
                  </MobileDialog>
                )}
              />
            </Switch>
          ) : (
            <Grid>
              <Dialogs>
                {dialogsIds.map(id => (
                  <DialogsItem key={id} as={NavLink} to={`/messages/${id}`}>
                    <PreviewDialog dialogId={id} />
                  </DialogsItem>
                ))}
              </Dialogs>
              <OpenedDialog>
                <Switch>
                  <Route
                    path="/messages/:dialogId"
                    render={({ match }) => (
                      <DialogComponent
                        dialogId={match.params.dialogId}
                        key={match.params.dialogId}
                      />
                    )}
                  />
                  <Route
                    path="*"
                    render={() => (
                      <NotSelected>Select conversation</NotSelected>
                    )}
                  />
                </Switch>
              </OpenedDialog>
            </Grid>
          )}
        </LoadableContent>
      </InternalPageTemplate>
    </AdaptiveProvider>
  );
}

const DialogsItemActiveStyles = css`
  background-color: #fff;
  border-right-color: transparent;
`;

const DialogsItem = styled.div`
  cursor: pointer;
  display: block;
  border: solid #dfe8f6;
  border-width: 1px 0 0;
  :hover {
    ${DialogsItemActiveStyles};
    text-decoration: none;
  }

  &.active {
    ${DialogsItemActiveStyles};
    margin-right: -1px;
    padding-right: 1px;
  }

  ${adaptive} {
    border-width: 1px 0 0;
  }
`;

const Dialogs = styled(({ children, ...props }) => (
  <div {...props}>
    <div>{children}</div>
  </div>
))`
  display: grid;
  background: #f7f8ff;
  border-right: 1px solid #dfe8f6;

  ${adaptive} {
    background: #fff;
    border: none;
  }
`;

const StatusMessage = styled(Icon).attrs({
  icon: messageIcon,
})`
  ${Icon.imageSelector} {
    width: 80px;
    height: 80px;
    margin-bottom: 12px;
  }

  height: 100%;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;

  color: #dfe8f6;
  text-align: center;
  ${adaptive} {
    font-size: 16px;
    padding: 15px;
  }
`;

const NotSelected = styled(StatusMessage)`
  ${adaptive} {
    display: none;
  }
`;

const OpenedDialog = styled.div`
  overflow: hidden;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 300px 1fr;

  border-radius: 8px;
  overflow: hidden;
  background: #fff;

  height: calc(var(--appHeight) - var(--headerHeight));

  ${adaptive} {
    display: block;

    ${Dialogs} {
      width: 100vw;
    }
  }
`;

const MobileDialog = styled.div`
  height: calc(var(--appHeight) - var(--headerHeight));
`;

function EmptyList() {
  const { isPetOwner, isServiceProvider, profile } = useCurrentProfile();

  return (
    <EmptyListGrid>
      {isPetOwner && (
        <StatusMessage>You don't have any dialogs yet.</StatusMessage>
      )}
      {isServiceProvider && (
        <StatusMessage>
          {profile.hasPremium ? (
            <div>You don't have any dialogs yet.</div>
          ) : (
            <div>
              To have access to messages and other cool features, please
              <div>
                <Link to="/service-provider-profile/premium">get premium</Link>.
              </div>
            </div>
          )}
        </StatusMessage>
      )}
    </EmptyListGrid>
  );
}

const EmptyListGrid = styled.div`
  height: calc(var(--appHeight) - var(--headerHeight));
`;
