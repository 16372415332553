import { useQueryParams } from './router';

export function useQueryField<T = string>(
  name: string,
  options: {
    parse?: (value: string) => T;
  } = {},
) {
  const [params, set] = useQueryParams<{ [name]: T }>();

  const value = options.parse ? options.parse(params[name]) : params[name];

  function setValue(value: T) {
    set({
      [name]: value,
    });
  }

  return [value, setValue];
}
