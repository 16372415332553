import React from 'react';
import styled from 'styled-components';

import { MetaTags } from 'libs/metaTags';
import { useResource } from 'libs/reatom-toolkit';
import { adaptive } from 'ui/styles/addaptive-provider';
import { SearchPageTemplate } from 'ui/templates/search-page-template/search-page-template';
import { useServiceTypes } from 'services/service-types';

// @ts-ignore
import bannerImg from './banner.jpg';
import { BasicSearchResource } from './search-page.model';

export function SearchPage() {
  const { get, isLoading, data } = useResource(BasicSearchResource);
  const services = useServiceTypes();

  return (
    <React.Fragment>
      <MetaTags {...metaTags} />
      <SearchPageTemplate
        headerImage={bannerImg}
        renderTitle={
          <HeaderBlock>
            <Title>Pawzr Search</Title>
            <Description>Search Here For Your Pet Care Needs.</Description>
          </HeaderBlock>
        }
        get={get}
        isLoading={isLoading}
        data={data}
        services={services}
      />
    </React.Fragment>
  );
}

const metaTags = {
  title: 'Search | Pawzr',
};

const Title = styled.div`
  font-size: 48px;
  line-height: 56px;
  font-weight: bold;
  color: var(--white);
  ${adaptive} {
    font-size: 24px;
    line-height: 32px;
  }
`;

const Description = styled.div`
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  color: var(--white);
  ${adaptive} {
    font-size: 14px;
    line-height: 21px;
  }
`;

const HeaderBlock = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1160px;
  height: 100%;
  margin: 0 auto;
  padding: 0 16px;
  overflow: hidden;
  justify-content: center;
  ${Title} {
    margin-bottom: 8px;
    ${adaptive} {
      margin-bottom: 4px;
    }
  }
`;
