import React, { useCallback, useState } from 'react';

import cn from 'classnames';

import s from './DropDown.module.css';

function DropDown(props) {
  const { children, title, className } = props;

  const [hidden, setHidden] = useState(true);

  const toggleHidden = useCallback(() => {
    setHidden(!hidden);
  }, [hidden, setHidden]);

  return (
    <div className={cn(s.dropdown, className)}>
      <p
        className={cn(s.title, { [s.visible]: !hidden })}
        onClick={toggleHidden}
      >
        {title}
      </p>
      <div className={cn(s.hiddeble, { [s.hidden]: hidden })}>{children}</div>
    </div>
  );
}

export default DropDown;
