import { publicApi } from 'api';

class RequestRepository {
  resetPassword(email) {
    return fetch(
      `${process.env.REACT_APP_HOST}/api/public/pet-owner/reset-password/request`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify({ email: email }),
      },
    );
  }

  getAboutUsData() {
    return fetch(`${process.env.REACT_APP_HOST}/api/public/information-pages`);
  }

  signUpPetOwner({
    firstName,
    lastName,
    phoneNumber,
    zipCode,
    plainPassword,
    email,
    birthday,
  }) {
    return fetch(
      `${process.env.REACT_APP_HOST}/api/public/pet-owner/register`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify({
          first_name: firstName,
          last_name: lastName,
          phone_number: phoneNumber,
          zip_code: zipCode,
          plain_password: plainPassword,
          email: email,
          birthday,
        }),
      },
    );
  }

  emailSubscription(email) {
    return fetch(
      `${process.env.REACT_APP_HOST}/api/public/email-subscriptions`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify({ email: email }),
      },
    );
  }

  async getSocialLinks() {
    const { data } = await publicApi.get('/social-links');
    return data;
  }

  getPremiumAccountDescription() {
    return fetch(
      `${process.env.REACT_APP_HOST}/api/public/description-blocks/premium-account`,
    );
  }

  getServiceOwnerDescription() {
    return fetch(
      `${process.env.REACT_APP_HOST}/api/public/description-blocks/service-owner`,
    );
  }
  getPetOwnerDescription() {
    return fetch(
      `${process.env.REACT_APP_HOST}/api/public/description-blocks/pet-owner`,
    );
  }

  confirmNewPassword(password, token) {
    return fetch(
      `${process.env.REACT_APP_HOST}/api/public/user/reset-password/confirm`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify({
          token: token,
          plain_password: password,
        }),
      },
    );
  }
}

export const requestRepository = new RequestRepository();
