import React from 'react';
import { Helmet } from 'react-helmet-async';

interface MetaTagsProps {
  title?: string;
  description?: string;
  url?: string;
  imageUrl?: string;
  keywords?: string;
  canonical?: string;
  jsonLD?: unknown;
}

export function MetaTags(props: MetaTagsProps) {
  return (
    <Helmet>
      {props.title && <title>{props.title}</title>}
      {props.title && <meta name="title" content={props.title} />}
      {props.description && (
        <meta name="description" content={props.description} />
      )}

      <meta property="og:type" content="website" />
      {props.url && <meta property="og:url" content={props.url} />}
      {props.title && <meta property="og:title" content={props.title} />}
      {props.description && (
        <meta property="og:description" content={props.description} />
      )}
      <meta property="og:image" content={props.imageUrl} />

      <meta property="twitter:card" content="summary_large_image" />
      {props.url && <meta property="twitter:url" content={props.url} />}
      {props.title && <meta property="twitter:title" content={props.title} />}
      {props.description && (
        <meta property="twitter:description" content={props.description} />
      )}
      <meta name="twitter:image" content={props.imageUrl} />

      {props.keywords && <meta property="keywords" content={props.keywords} />}

      {props.canonical && <link rel="canonical" href={props.canonical} />}

      {props.jsonLD && (
        <script type="application/ld+json">
          {JSON.stringify(props.jsonLD)}
        </script>
      )}
    </Helmet>
  );
}
