import React from 'react';

import Header from '../../_components/Header/Header';
import Footer from '../../_components/Footer/Footer';

interface Props {
  children: React.ReactNode;
}

export function PublicPageTemplate(props: Props) {
  return (
    <React.Fragment>
      <Header />
      {props.children}
      <Footer />
    </React.Fragment>
  );
}
