import React from 'react';
import styled from 'styled-components';
import { ModalFooter } from 'ui/ecosystems/modals';
import { ModalContent } from 'ui/ecosystems/modals';

interface Props {
  renderContent(): React.ReactNode;
  renderActions(): React.ReactNode;
}

export function FormModalContentTemplate(props: Props) {
  return (
    <FormModalTemplateRoot>
      <Content>
        <ModalContent>
          {props.renderContent && props.renderContent()}
        </ModalContent>
      </Content>
      <Footer>{props.renderActions && props.renderActions()}</Footer>
    </FormModalTemplateRoot>
  );
}

const Footer = styled(ModalFooter)`
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  flex: 1;
`;

const FormModalTemplateRoot = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
