import React, { useState, useRef } from 'react';
import styled from 'styled-components';

import { InputProps } from 'libs/forms';

import { Input } from '../../atoms/input/input';
// @ts-ignore
import { ReactComponent as Icon } from './password-icon.svg';

type Props = InputProps<string> &
  React.ComponentProps<typeof Input> & {
    placeholder?: string;
  };

export function PasswordInput(props: Props) {
  const [isVisible, setIsVisible] = useState(false);
  const inputRef = useRef<HTMLInputElement>();

  function handleVisibleClick() {
    setIsVisible(!isVisible);
    inputRef?.current?.focus();
  }

  return (
    <PasswordInputRoot>
      <Input
        ref={inputRef as any}
        type={isVisible ? 'text' : 'password'}
        {...props}
      />
      <PasswordIcon onClick={handleVisibleClick} />
    </PasswordInputRoot>
  );
}

const PasswordIcon = styled(Icon)`
  cursor: pointer;
  --color: var(--grayMiddle);
  :hover {
    --color: var(--blueMiddle);
  }
`;

const PasswordInputRoot = styled.div`
  position: relative;

  ${PasswordIcon} {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
`;
