import React from 'react';
import styled from 'styled-components';
import { useDropdown, DropdownContext } from 'libs/react-dropdown-elements';

import { Selector } from '../../atoms/selector';
import { Popup } from '../../atoms/popup';

interface Props {
  renderLabel(): React.ReactNode;
  renderPopupContent(): React.ReactNode;
  isInvalid?: boolean;
}

export function DropdownTemplate(props: Props) {
  const { openAbleState, ref, contextValue } = useDropdown();

  return (
    <DropdownContext.Provider value={contextValue}>
      <DropdownRoot ref={ref as any}>
        <Selector
          onClick={openAbleState.toggle}
          isOpened={openAbleState.isOpened}
          isInvalid={props.isInvalid}
        >
          {props.renderLabel()}
        </Selector>
        {openAbleState.isOpened && <Popup>{props.renderPopupContent()}</Popup>}
      </DropdownRoot>
    </DropdownContext.Provider>
  );
}

const DropdownRoot = styled.div<{ ref: React.Ref<any> }>`
  position: relative;
  cursor: pointer;

  ${Popup} {
    position: absolute;
    min-width: 100%;
    top: calc(100% + 8px);
    z-index: 2;
    max-height: 190px;
    overflow: scroll;
  }
`;
