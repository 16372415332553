import React from 'react';
import { POAvatar } from 'ui/ecosystems/users';

import { Link } from 'react-router-dom';

import { useSession } from 'features/authentication';
import { useOpenableState } from 'libs/use-openable-state';

import {
  PetOwnerEditProfileModal,
  useCurrentPetOwnerProfile,
} from 'features/pet-owner-profile';

import { MessagesIcon } from 'features/messages';

import { BaseHeaderTemplate } from '../../templates/base-header-template/base-header-template';
import { HeaderProfile } from '../../molecules/header-profile/header-profile';
import { Divider, MenuItem } from '../../atoms/dropdown-menu';

export function PetOwnerHeader() {
  const { profile } = useCurrentPetOwnerProfile();
  const { logout } = useSession();
  const editProfileModal = useOpenableState();

  return (
    <BaseHeaderTemplate
      renderContent={() => (
        <React.Fragment>
          <MessagesIcon />
          <HeaderProfile
            AvatarComponent={POAvatar}
            avatar={profile.avatar?.url}
            renderUserMenu={() => (
              <React.Fragment>
                <MenuItem>
                  <Link to="/pet-owner-profile">View Profile</Link>
                </MenuItem>
                <Divider />
                <MenuItem onClick={editProfileModal.open}>
                  Edit Profile
                </MenuItem>
                <MenuItem onClick={logout}>Log Out</MenuItem>
              </React.Fragment>
            )}
          />

          <PetOwnerEditProfileModal
            isOpened={editProfileModal.isOpened}
            onClose={editProfileModal.close}
          />
        </React.Fragment>
      )}
    />
  );
}
