export const clientSideOnly = ({ fallback }) => (route, context) => {
  if (!context.isSSR) {
    return route;
  }

  return {
    ...route,
    routes: [],
    component: fallback,
    stop: true,
  };
};
