import styled from 'styled-components';
import React from 'react';

import { RatingStar } from 'ui/atoms/rating-star/rating-star';

export interface RatingProps {
  rating: number;
  size?: number;
}

export function Rating(props: RatingProps) {
  let rating;
  props.rating ? (rating = Math.floor(props.rating)) : (rating = 0);
  const size = props.size ?? 16;

  let content: Array<React.ReactNode> = [];
  for (let i = 1; i <= 5; i++) {
    content.push(
      <Star
        key={`star-${i}`}
        fill={i <= rating}
        style={{ width: size, height: size }}
      />,
    );
  }
  return <Wrapper>{content}</Wrapper>;
}

const Star = styled(RatingStar)`
  width: 16px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
