import React from 'react';
import { renderRoutes } from 'react-router-config';
import { HelmetProvider } from 'react-helmet-async';

import { GlobalStyles, BodyColor } from 'ui/styles/globalStyles';
import { useDialogs } from 'features/messages';
import { useCurrentProfile } from 'features/profile';
import {
  getDialogId as getDialogIdForPO,
  PetOwnerDialogsIdsResource,
} from 'features/pet-owner-messages';
import { useMessages } from 'features/messages/use-messages';
import {
  ServiceProviderMessages,
  getDialogId as getDialogIdForSP,
} from 'features/service-provider-messages';

import { useCurrentPetOwnerCoordinates } from 'features/pet-owner-profile';

import ReactGA from 'react-ga';

if (process.env.RAZZLE_IS_PRODUCTION === 'true') {
  ReactGA.initialize('UA-194219083-1');

  if (typeof window !== 'undefined') {
    const path = window.location.pathname + window.location.search;
    ReactGA.pageview(path);
  }
}
const App = ({ routes }) => {
  const { profile } = useCurrentProfile();

  useDialogs();

  return (
    <HelmetProvider>
      {profile && <AuthUserSideEffects key={profile.id} />}
      <GlobalStyles />
      <BodyColor />
      {renderRoutes(routes)}
    </HelmetProvider>
  );
};

function AuthUserSideEffects() {
  const { isPetOwner, isServiceProvider } = useCurrentProfile();
  if (isPetOwner) {
    useMessages(PetOwnerDialogsIdsResource, { getDialogId: getDialogIdForPO });
    useCurrentPetOwnerCoordinates();
  } else if (isServiceProvider) {
    useMessages(ServiceProviderMessages, { getDialogId: getDialogIdForSP });
  }

  return null;
}

export default App;
