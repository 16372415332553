import React, { useMemo } from 'react';
import { css, ThemeProvider, useTheme } from 'styled-components';
import { useMediaQuery } from 'react-responsive';

export const adaptive = css`
  @media (max-width: ${props => props.theme.mobileWidth}px)
`;

export const notAdaptive = css`
  @media (min-width: ${props => props.theme.mobileWidth + 1}px)
`;

export function useAdaptive() {
  const theme = useTheme();
  const query = `(max-width: ${theme.mobileWidth}px)`;
  return useMediaQuery({ query: query });
}

export function AdaptiveProvider(props: {
  children: React.ReactNode;
  mobile: number;
}) {
  const parentTheme = useTheme();

  const theme = useMemo(
    () => ({
      ...parentTheme,
      mobileWidth: props.mobile,
    }),
    [],
  );

  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
}
