import { privateApi } from 'api';
import { Token } from '@stripe/stripe-js';

export async function postSubscription(data: {
  planId: string;
  stripeToken: Token;
}) {
  await privateApi.post(`/service-owner/subscriptions`, {
    stripe_plan_id: data.planId,
    token: data.stripeToken.id,
  });
}

interface DetailsApiResponse {
  card_brand?: string;
  expiration_of_premium_account_at: string;
  is_active_subscription: boolean;
  last_card_digits?: string;
  subscription_price: string;
}

export interface Details {
  isActive: boolean;
  isCancelled: boolean;
  expiration: string;
  cardNumber?: string;
  price: string;
}

export async function getSubscriptionDetails(): Promise<Details> {
  const { data } = await privateApi.get<DetailsApiResponse>(
    `/service-owner/subscriptions/details`,
  );
  return parseDetail(data);
}

export async function cancelSubscription() {
  const { data } = await privateApi.delete(`/service-owner/subscriptions`);
  return parseDetail(data);
}

export async function subscribeAgain() {
  const { data } = await privateApi.put(`/service-owner/subscriptions`);
  return parseDetail(data);
}

export async function changeCard(token: unknown) {
  const { data } = await privateApi.put(`/service-owner/subscriptions/card`, {
    token,
  });
  return parseDetail(data);
}

export async function removeCard() {
  const { data } = await privateApi.delete(`/service-owner/subscriptions/card`);
  return parseDetail(data);
}

function parseDetail(data: DetailsApiResponse): Details {
  return {
    isActive: Boolean(data.is_active_subscription),
    isCancelled: Boolean(data.is_subscription_cancelled),
    expiration: data.expiration_of_premium_account_at,
    cardNumber: data.last_card_digits,
    price: data.subscription_price,
  };
}
