import twitter from 'ui/staticSiteData/mainPage/images/social-logo/twitter.svg';
import facebook from 'ui/staticSiteData/mainPage/images/social-logo/facebook.svg';
import instagram from 'ui/staticSiteData/mainPage/images/social-logo/instagram.svg';
import youtube from 'ui/staticSiteData/mainPage/images/social-logo/youtube.svg';

export default {
  en: {
    columns: [
      {
        title: 'Pet owners',
        links: [
          {
            path: '/service-providers',
            text: 'Find a Service Provider',
          },
          // TODO
          // {
          //   path: '/for-pet-owners',
          //   text: 'Parks and Rec'
          // },
          {
            path: '/sign-up-pet-owner',
            text: 'Create Profile',
          },
        ],
      },
      {
        title: 'Service providers',
        links: [
          {
            path: '/for-service-providers',
            text: 'Pawzr for Business',
          },
          {
            path: '/premium-account',
            text: 'Pawzr Premium',
          },
          {
            path: '/sign-up-provider',
            text: 'List Your Business',
          },
        ],
      },
      {
        title: 'Our story',
        links: [
          {
            path: '/about-us',
            text: 'About Us',
          },
          {
            path: '/blog',
            text: 'Blog',
          },
        ],
      },
      {
        title: 'Support',
        links: [
          {
            path: '/faq',
            text: 'FAQ',
          },
          {
            path: '/contact-us',
            text: 'Contact Us',
          },
        ],
      },
    ],
    contacts: {
      socialLinks: [
        {
          name: 'twitter',
          link: '#',
          logo: twitter,
        },
        {
          name: 'facebook',
          link: '#',
          logo: facebook,
        },
        {
          name: 'instagram',
          link: '#',
          logo: instagram,
        },
        {
          name: 'youtube',
          link: '#',
          logo: youtube,
        },
      ],
      emails: [
        {
          title: 'Media Requests',
          links: [
            {
              path: 'media@pawzr.com',
              text: 'media@pawzr.com',
            },
          ],
        },
        {
          title: 'Business information',
          links: [
            {
              path: 'ruh-roh@pawzr.com',
              text: 'ruh-roh@pawzr.com',
            },
          ],
        },
        {
          title: 'Information',
          links: [
            {
              path: 'ruh-roh@pawzr.com',
              text: 'ruh-roh@pawzr.com',
            },
          ],
        },
      ],
    },
    copyright: '© 2020 Pawzr',
  },
};
