import { useEffect, useState } from 'react';

import { publicApi } from 'api';

interface ServiceType {
  id: number;
  title: string;
  is_activities: boolean;
  is_service_providers: boolean;
}

let promiseData = null;

async function getServices(): Promise<ServiceType[]> {
  if (!promiseData) {
    promiseData = publicApi.get('/service-types');
  }
  const { data } = await promiseData;
  return data;
}

export function useServiceTypes(): ServiceType[] {
  const [services, setServices] = useState<ServiceType[]>([]);

  useEffect(() => {
    (async () => {
      const data = await getServices();
      setServices(data);
    })();
  }, []);

  return services;
}
