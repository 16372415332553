import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import styled, { css } from 'styled-components';
import { useMemo } from 'react';

import { mediaPhone } from '../../../../styles/media';
import { adaptive } from '../../../../styles/addaptive-provider';

export interface TabsState {
  ref: React.RefObject<HTMLDivElement> | null;
  id?: string;
}

export const TabsContext = React.createContext<{
  currentTab: TabsState;
  setCurrentTab(state: TabsState): void;
}>({
  currentTab: {
    ref: null,
  },
  setCurrentTab() {},
});

interface Props {
  children: React.ReactNode;
  currentTab: TabsState;
  setCurrentTab(ref: TabsState): void;
  className?: string;
}

export function Tabs(props: Props) {
  const [selectorStyle, setStyle] = useState<React.CSSProperties>({});
  const [hasScroll, setHasScroll] = useState(false);

  const scrollRef = useRef();

  function setNavStyle() {
    const activeTab = props.currentTab.ref?.current ?? null;

    if (activeTab !== null) {
      setStyle({
        width: activeTab.offsetWidth,
        transform: `translateX(${activeTab.offsetLeft}px)`,
        display: 'block',
      });
    }
  }

  function checkHasScroll() {
    setHasScroll(scrollRef.current.scrollWidth > scrollRef.current.offsetWidth);
  }

  useLayoutEffect(() => {
    function callback() {
      checkHasScroll();
      setNavStyle();
    }

    callback();
    window.addEventListener('resize', callback);
    return () => window.removeEventListener('resize', callback);
  }, [props.currentTab]);

  useEffect(() => {
    const activeTab = props.currentTab.ref?.current ?? null;

    if (activeTab !== null) {
      scrollRef.current.scrollBy({
        left: activeTab.offsetLeft - 15 - scrollRef.current.scrollLeft,
        behavior: 'smooth',
      });
    }
  }, [props.currentTab]);

  const contextValue = useMemo(
    () => ({
      setCurrentTab: props.setCurrentTab,
      currentTab: props.currentTab,
    }),
    [props.setCurrentTab, props.currentTab],
  );

  return (
    <TabsContext.Provider value={contextValue}>
      <TabsRoot className={props.className}>
        <Scroll ref={scrollRef} withoutScroll={!hasScroll}>
          <TabsList>{props.children}</TabsList>
          <TabSelect style={selectorStyle} />
        </Scroll>
      </TabsRoot>
    </TabsContext.Provider>
  );
}

const activeCss = css`
  color: #172549;
`;

export const Tab = styled.div<{
  ref: React.Ref<HTMLDivElement>;
  isActive?: boolean;
}>`
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  color: var(--link-color);
  word-break: keep-all;
  white-space: nowrap;

  &.active {
    ${activeCss}
  }

  ${mediaPhone} {
    font-size: 14px;
    line-height: 21px;
  }

  ${props => props.isActive && activeCss}

  :hover {
    color: var(--blueMiddle);
  }
`;

const TabsList = styled.div`
  display: inline-flex;
  justify-content: center;

  padding: 0 32px 16px;
  & > * {
    display: block;
  }
  & > :not(:last-child) {
    margin-right: 40px;

    ${adaptive} {
      margin-right: 20px;
    }
  }

  ${adaptive} {
    padding: 0 15px 16px;
  }
`;

const Scroll = styled.div`
  width: 100%;
  overflow-x: scroll;
  padding-bottom: 10px;
  position: relative;
  -webkit-overflow-scrolling: touch;

  ${props =>
    props.withoutScroll &&
    css`
      display: flex;
      justify-content: center;
    `}
`;

const TabsRoot = styled.div`
  max-width: 100vw;
  //border-bottom: 2px solid #dfe8f6;
  position: relative;
  height: 34px;
  overflow: hidden;

  :before {
    content: '';
    display: block;
    border-bottom: 2px solid #dfe8f6;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  ${mediaPhone} {
    height: 31px;
    font-size: 14px;
    line-height: 21px;

    //border: none;
  }
`;

const TabSelect = styled.div`
  background: #344ca4;
  border-radius: 3px;
  height: 2px;
  position: absolute;
  left: 0;
  width: 75px;
  bottom: 16px;
  transition: all 200ms ease;
  display: none;

  ${mediaPhone} {
    bottom: 16px;
  }
`;
