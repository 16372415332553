import React from 'react';
import styled from 'styled-components';

import {
  FieldsGroup,
  FormFields,
  FormLine,
  FormModalContentTemplate,
  InputField,
  SubmitFormButton,
} from 'ui/ecosystems/forms';
import { Form, useCreateForm } from 'libs/forms';
import { ServiceProviderProfile } from 'features/service-provider-profile/types';
import { updateAccountSettingsAction } from 'features/profile/profile.model';
import { useAsyncAction } from 'libs/reatom-toolkit';

import { useCurrentServiceProviderProfile } from '../../../current-service-provider-profile.model';

interface FormState {
  firstName: string;
  lastName: string;
  email: string;
  privatePhoneNumber: string;
}

function format(values: FormState) {
  return {
    first_name: values.firstName,
    last_name: values.lastName,
    phone_number: values.privatePhoneNumber,
    email: values.email,
  };
}

export function AccountSettingForm() {
  const { profile, updateAccountSettings } = useCurrentServiceProviderProfile();

  async function handleFormSubmit(values: FormState) {
    const data = format(values);
    await updateAccountSettings(data);
  }

  const form = useCreateForm(
    {
      initialValues: profile,
      onSubmit: handleFormSubmit,
    },
    [profile],
  );

  return (
    <Form
      form={form}
      render={({ handleSubmit, dirtySinceLastSubmit, submitSucceeded }) => (
        <FormModalContentTemplate
          renderContent={() => (
            <FormFields>
              <NameGroup>
                <FormLine label="First Name">
                  <InputField name="firstName" />
                </FormLine>
                <FormLine label="Last Name">
                  <InputField name="lastName" />
                </FormLine>
              </NameGroup>
              <FormLine label="Email">
                <InputField name="email" />
              </FormLine>
              <FormLine label="Phone Number">
                <InputField type="tel" name="privatePhoneNumber" />
              </FormLine>
            </FormFields>
          )}
          renderActions={() => (
            <SubmitFormButton
              onClick={handleSubmit}
              disabled={submitSucceeded && !dirtySinceLastSubmit}
            >
              {submitSucceeded && !dirtySinceLastSubmit
                ? 'Saved'
                : 'Save Changes'}
            </SubmitFormButton>
          )}
        />
      )}
    />
  );
}

const NameGroup = styled(FieldsGroup)`
  grid-template-columns: 1fr 1fr;
`;
