import React, { useMemo } from 'react';
import * as yup from 'yup';
import type { FormProps } from 'react-final-form';

import { Form as ReactFinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormApi, setIn, SubmissionErrors } from 'final-form';

import { DebugPopup } from './debug';
import { Form as FormType } from './types';

type DefaultFormProps<Schema, State> = Omit<
  FormProps<State>,
  'onSubmit' | 'form'
> & {
  form?: FormType<State>;
  schema?: yup.Schema<Schema>;
  onSubmit?: (
    values: State,
    form: FormApi<State>,
    callback?: (errors?: SubmissionErrors) => void,
  ) =>
    | SubmissionErrors
    | Promise<SubmissionErrors | undefined>
    | undefined
    | void;
  withDebug?: boolean;
  context?: unknown;
  disabledFields?: unknown;
  backendErrorParse?: (errors: any) => void;
};

interface FormExtendedStateType {
  disabledFields: any;
}

export const FormExtendedState = React.createContext<FormExtendedStateType>({
  disabledFields: {},
});

export function Form<Schema, State>(props: DefaultFormProps<Schema, State>) {
  const extendedState = useMemo(
    () => ({
      disabledFields: props.disabledFields || {},
    }),
    [props.disabledFields],
  );

  async function handleSubmit(values: State, form: FormApi<State>) {
    try {
      const method = props.onSubmit || props.form.onSubmit;
      return await method(values, form);
    } catch (submitError) {
      if (submitError.errors) {
        return {
          hasError: true,
          ...submitError.errors,
        };
      }
      console.error(submitError);
      return {
        networkError: true,
      };
    }
  }

  return (
    <FormExtendedState.Provider value={extendedState}>
      <ReactFinalForm
        validate={values => {
          if (props.schema) {
            // @ts-ignore
            return validateBySchema(props.schema, values);
          } else if (props.validate && typeof props.validate === 'function') {
            return props.validate(values);
          }
          return {};
        }}
        mutators={{
          ...arrayMutators,
        }}
        {...props}
        form={props.form?.FFFormApi}
        onSubmit={handleSubmit}
        render={formProps => (
          <React.Fragment>
            {props.render ? props.render(formProps) : props.children || null}
            {props.withDebug && <DebugPopup formProps={formProps} />}
          </React.Fragment>
        )}
      />
    </FormExtendedState.Provider>
  );
}

export function validateBySchema<T>(schema: yup.Schema<T>, values: T): T | {} {
  try {
    schema.validateSync(values, {
      abortEarly: false,
    });
  } catch (err) {
    return (err as yup.ValidationError).inner.reduce(
      (formError, innerError) =>
        setIn(formError, innerError.path, innerError.message),
      {},
    );
  }
  return {};
}
