import styled from 'styled-components';
import React from 'react';

import { useListItemData } from 'libs/reatom-toolkit/useList';

import { dayjs } from 'libs/dayjs-ext/dayjs-ext';
import { PetAvatar, POAvatar, SPAvatar } from 'ui/ecosystems/users';

import { MessagesList } from '../../messages.model';
import { DialogsList, InterlocutorType } from '../../dialogs.model';

interface Props {
  dialogId: number;
}

const AvatarComponentsMap = {
  [InterlocutorType.serviceOwner]: SPAvatar,
  [InterlocutorType.pet]: PetAvatar,
  [InterlocutorType.petOwner]: POAvatar,
};

export function PreviewDialog(props: Props) {
  const dialog = useListItemData(DialogsList, props.dialogId);
  const lastMessage = useListItemData(MessagesList, dialog.messagesIds[0]);

  return (
    <Root>
      <Avatar
        as={AvatarComponentsMap[dialog.interlocutor.type]}
        avatar={dialog.interlocutor.avatar}
      />
      <Content>
        <Header>
          <Name>{dialog.interlocutor.name}</Name>

          <Time>
            {lastMessage && dayjs(lastMessage.timestamp).format('hh:mm A')}
          </Time>
        </Header>
        <Body>
          <LastMessage>{lastMessage && lastMessage.body}</LastMessage>
          {dialog.unreadCount > 0 && (
            <Count>{dialog.unreadCount}</Count>
          )}
        </Body>
      </Content>
    </Root>
  );
}

const Name = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #172549;
  opacity: 0.6;
`;

const Time = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #5b6784;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LastMessage = styled.div`
  font-size: 12px;
  line-height: 16px;
  height: 16px;
  color: #5b6784;
  max-height: 16px;
  max-width: 175px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Count = styled.div`
  display: inline-flex;
  min-width: 16px;
  height: 16px;
  align-items: center;
  justify-content: center;
  background: #eb5757;
  border-radius: 80px;
  padding: 8px 4px;

  font-weight: 500;
  font-size: 14px;
  line-height: 21px;

  color: #fff;
`;

const Body = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Content = styled.div``;

const Avatar = styled.div`
  width: 48px;
  height: 48px;
`;

const Root = styled.div`
  display: grid;
  grid-template-columns: 48px 1fr;
  grid-gap: 16px;

  padding: 17px 16px;
`;
