import { useEffect } from 'react';

import { useFormField } from 'libs/forms';

import { useAttachment, useFileUploader } from './upload-attachments.model';

export { useFileUploader, useAttachment } from './upload-attachments.model';

export function useFormAttachmentField(name: string): any {
  const { upload } = useFileUploader();
  const field = useFormField<{
    id: string;
    loaded: number;
    total: number;
    isLoading: boolean;
  }>(name);
  const attachment = useAttachment(field.input.value.id);

  const isLoading = attachment?.loading?.isLoading ?? false;
  const progress =
    attachment?.loading?.loaded && attachment?.loading?.total
      ? (attachment.loading.loaded / attachment.loading.total) * 100 || 0
      : 0;

  useEffect(() => {
    if (!isLoading && attachment?._id) {
      field.input.onChange({ ...attachment, id: attachment._id });
    }
  }, [isLoading]);

  async function handleFileSelect(files: File[]) {
    const id = await upload({ file: files[0] });
    field.input.onChange({
      id,
    });
  }

  async function handleSingleFileSelect(file: File, cropOptions) {
    const id = await upload({ file, cropOptions });
    field.input.onChange({
      id,
    });
  }

  return {
    ...field,
    isLoading,
    progress,
    attachment,
    handleFileSelect,
    handleSingleFileSelect,
  };
}
