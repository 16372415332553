import { createGlobalStyle, css } from 'styled-components';
import { normalize } from 'styled-normalize';

// @ts-ignore
import UbuntuRegular from './fonts/Ubuntu/Ubuntu-Regular.ttf';
// @ts-ignore
import UbuntuMedium from './fonts/Ubuntu/Ubuntu-Medium.ttf';
// @ts-ignore
import UbuntuBold from './fonts/Ubuntu/Ubuntu-Bold.ttf';
import { mediaPhone, mediaTablet } from './media';
import './styles.css';

const palette = css`
  // colors by value
  --white: #fff;
  --darkBlue: #344ca4;
  --grayLight: #dfe8f6;
  --grayMiddle: #a1b3ce;
  --grayDark: #5b6784;
  --blueMiddle: #3c5bcc;
  --blueSoft: #b9cbdf;
  --black: #172549;

  // color by purpose
  --link-color: #344ca4;
  --error: #eb5757;
  --baseInputBorderColor: var(--grayLight);
  --baseBorderColor: var(--grayLight);
  --baseLinkColor: var(--darkBlue);
`;

const ContentSize = css`
  --contentWidth: 1128px;

  @media (max-width: 1128px) {
    --contentWidth: calc(100vw - 30px);
  }

  --headerHeight: 104px;
  ${mediaTablet} {
    --headerHeight: 48px;
  }
`;

const zIndexes = css`
  --modalOverlay: 1000;
`;

export const BodyColor = createGlobalStyle`
  body {
  color: #172549
  }

*, *:before, *:after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}
`;

export const GlobalStyles = createGlobalStyle`
  ${normalize}
  
  html {
   ${palette};
   ${zIndexes};
   ${ContentSize};
   box-sizing: border-box;
  }
  
  * {
      word-break: break-word;
  }
  
  *, *:before, *:after {
    box-sizing: inherit
  }
 
  body {
    font-family: 'Ubuntu', sans-serif;
    color: #172549;
  }

  a {
    text-decoration: none;
    color: #344CA4;
  }
  
  button {
    cursor: pointer;
    outline: none;
    border: none;
    background: transparent
  }
  
  a {
    :hover {
      text-decoration: underline;
      
      ${mediaPhone} {
        text-decoration: none
      }
    }
  }
  
  .svg-icon {
    fill: var(--color)
  }

`;
