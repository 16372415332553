export default {
  en: [
    {
      headerTitle: 'Pet Owners',
      subMenu: [
        { text: 'Find a Service Provider', link: '/service-providers' },
        { text: 'Pawzr for Activities', link: '/for-pet-owners' },
        { text: 'Create Your Profile', link: '/sign-up-pet-owner' },
      ],
    },
    {
      headerTitle: 'Service Providers',
      subMenu: [
        { text: 'Pawzr for Business', link: '/for-service-providers' },
        { text: 'Register Your Business', link: '/sign-up-provider' },
        { text: 'Pawzr Premium', link: '/premium-account' },
      ],
    },
    {
      headerTitle: 'Our Story',
      subMenu: [
        { text: 'About Us', link: '/about-us' },
        // { text: 'How It Works', link: '#' },
        // { text: 'Who Uses Pawzr', link: '#' },
        { text: 'Blog', link: '/blog' },
      ],
    },
    {
      headerTitle: 'Support',
      subMenu: [
        { text: 'Frequently Asked Questions', link: '/faq' },
        {
          text: 'Get the Pawzr iOS App',
          link: 'https://apps.apple.com/us/app/pawzr/id1274537678',
          href: 'https://apps.apple.com/us/app/pawzr/id1274537678',
        },
        { text: 'Contact Us', link: '/contact-us' },
      ],
    },
  ],
};
