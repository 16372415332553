import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';
import { InfoWindow } from '@react-google-maps/api';

import { useGeolocation } from 'libs/react-use-geolocation';
import { Map, MapMarker } from 'ui/organisms/map/map';
import { ServiceProviderCard } from 'ui/organisms/service-provider-card/service-provider-card';
import { AdaptiveProvider } from 'ui/styles/addaptive-provider';
import { publicApi } from 'api';
import { useCurrentProfile } from 'features/profile';

export function ServicesOnMap(props: { serviceType: number; search: string }) {
  const [map, setMap] = useState();
  const [companies, setCompanies] = useState([]);
  const deviceGeoLocation = useGeolocation();
  const { profile } = useCurrentProfile();
  const petOwnerGeoLocation =
    profile && profile.latitude && profile.longitude
      ? { latitude: profile.latitude, longitude: profile.longitude }
      : null;
  const defaultGeoLocation = {
    latitude: 41.85003,
    longitude: -87.65005,
  };
  const geoLocation =
    deviceGeoLocation && Object.keys(deviceGeoLocation).length > 0
      ? deviceGeoLocation
      : petOwnerGeoLocation || defaultGeoLocation;

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  async function getCompanies(geoLocation) {
    const { data } = await publicApi.get(
      `/service-owner/by-service-type-square/${props.serviceType}`,
      {
        params: {
          search: props.search,
          latitudeNorthEast: geoLocation.northEast.latitude,
          longitudeNorthEast: geoLocation.northEast.longitude,
          latitudeSouthWest: geoLocation.southWest.latitude,
          longitudeSouthWest: geoLocation.southWest.longitude,
        },
      },
    );

    setCompanies(data);
  }

  const [handleCenterChanged] = useDebouncedCallback(() => {
    const bounds = map?.getBounds?.();

    const northEast = bounds?.getNorthEast && {
      latitude: bounds.getNorthEast().lat(),
      longitude: bounds.getNorthEast().lng(),
    };

    const southWest = bounds?.getSouthWest && {
      latitude: bounds.getSouthWest().lat(),
      longitude: bounds.getSouthWest().lng(),
    };

    if (northEast && southWest) {
      getCompanies({ northEast, southWest });
    }
  }, 500);

  const center = useMemo(() => {
    if (geoLocation) {
      return {
        lat: geoLocation.latitude,
        lng: geoLocation.longitude,
      };
    }
  }, []);

  const [openedInfoId, open] = useState(null);

  return (
    <Root>
      <Map
        getInstance={setMap}
        onBoundsChanged={(...args) => console.log('onBoundsChanged', ...args)}
        onCenterChanged={handleCenterChanged}
        onLoad={onLoad}
        center={center}
      >
        {companies
          .filter(company => company.latitude && company.longitude)
          .map(company => (
            <MapMarker
              key={company.id}
              position={{ lat: company.latitude, lng: company.longitude }}
              onClick={() => open(company.id)}
            >
              {openedInfoId === company.id && (
                <InfoWindow maxWidth="320px" onCloseClick={() => open(null)}>
                  <AdaptiveProvider mobile={40000}>
                    <ServiceProviderCardOnMap {...company} />
                  </AdaptiveProvider>
                </InfoWindow>
              )}
            </MapMarker>
          ))}
      </Map>
    </Root>
  );
}

const ServiceProviderCardOnMap = styled(ServiceProviderCard)`
  width: 252px;
  max-width: 80vw;
  border: none;
  padding: 16px 0 0 16px;
`;

const Root = styled.div`
  height: 392px;

  .gm-style-iw.gm-style-iw-c {
    max-width: 310px !important;
    padding: 0;
  }
`;
