import styled, { css } from 'styled-components';

export const IconCss = css<{ icon: string }>`
  display: flex;
  align-items: center;

  &:before {
    content: '';
    display: block;
    height: 12px;
    width: 12px;
    background-image: url(${props => props.icon});
    background-repeat: no-repeat;
    background-size: 100%;
  }
`;

export const Icon = styled.i<{ icon: string }>`
  ${IconCss}
`;

// @ts-ignore
Icon.imageSelector = ':before';
