import styled from 'styled-components';

import { ModalContent } from './modal-content';
import { adaptive } from '../../../styles/addaptive-provider';

export const ModalFooter = styled(ModalContent)`
  background: #f7f8ff;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 0 0 8px 8px;

  ${adaptive} {
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 0;
  }
`;
