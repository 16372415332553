import React from 'react';
import styled from 'styled-components';

import { Label } from '../atoms/label';

interface FormLineProps {
  label: string;
  children: React.ReactNode;
  className?: string;
}

export const FormLine = styled((props: FormLineProps) => {
  return (
    <FormLineRoot className={props.className}>
      <Label>{props.label}</Label>
      {props.children}
    </FormLineRoot>
  );
})``;

const FormLineRoot = styled.div`
  ${Label} {
    margin-bottom: 4px;
  }
`;
