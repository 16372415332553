import { declareAtom } from '@reatom/core';
import { useAtom } from '@reatom/react';

import {
  call,
  declareAsyncAction,
  useAsyncAction,
  declareAsyncStatusAtom,
} from 'libs/reatom-toolkit';
import { postSubscription } from 'features/premium/premiun.api';

import { setTokenAction, clearTokenAction } from '../authentication/auth.model';
import {
  CurrentServiceProviderProfile,
  ServiceProviderProfileApiResponse,
} from './types';
import {
  auth,
  getProfile,
  updateProfile,
  updateAccountSettings,
} from './service-provider-profile.api';

export const serviceProviderAuthAction = declareAsyncAction<
  string,
  { email: string; password: string }
>('get token', async (authData, store) => {
  const token = await auth(authData);
  store.dispatch(setTokenAction(token));
  await call(store, getProfileAction());
  return token;
});

export const getProfileAction = declareAsyncAction<
  CurrentServiceProviderProfile
>('Current Service Provider | get profile', getProfile);

const updateProfileAction = declareAsyncAction<
  CurrentServiceProviderProfile,
  ServiceProviderProfileApiResponse
>('Current Service Provider | update profile', updateProfile);

const updateAccountSettingsAction = declareAsyncAction<
  CurrentServiceProviderProfile,
  ServiceProviderProfileApiResponse
>('Current Service Provider | update account settings', updateAccountSettings);

const subscribeToPremiumAction = declareAsyncAction(
  'Current Service Provider | Subscribe To Premium Service',
  postSubscription,
);

export const currentServiceProviderAtom = declareAtom<
  CurrentServiceProviderProfile
>(['Current Service Provider'], {} as CurrentServiceProviderProfile, on => [
  on(clearTokenAction, () => ({} as CurrentServiceProviderProfile)),
  on(getProfileAction.done, (_, payload) => payload),
  on(updateProfileAction.done, (_, payload) => payload),
  on(subscribeToPremiumAction.done, profile => ({
    ...profile,
    hasPremium: true,
  })),
]);

export function useServiceProviderSignInService() {
  return {
    authAsServiceProvider: useAsyncAction(serviceProviderAuthAction),
  };
}
const isLoadingAtom = declareAsyncStatusAtom(
  'Service Provider Profile Is Loading',
  getProfileAction,
);

export function useCurrentServiceProviderProfile(): {
  profile: CurrentServiceProviderProfile;
  isLoading: boolean;
  updateAccountSettings(data: any): Promise<any>;
  updateProfile(data: any): Promise<any>;
  subscribeToPremium(data: any): Promise<any>;
} {
  return {
    profile: useAtom(currentServiceProviderAtom),
    isLoading: useAtom(isLoadingAtom),
    updateProfile: useAsyncAction(updateProfileAction),
    updateAccountSettings: useAsyncAction(updateAccountSettingsAction),
    subscribeToPremium: useAsyncAction(subscribeToPremiumAction),
  };
}
