import { useEffect } from 'react';

export const useOutsideClick = (ref, handleFunction) => {
  function handleClickOutside(event) {
    const isComponentStillExists = document.contains(event.target);
    const isComponentInsideRef =
      ref.current && !ref.current.contains(event.target);
    if (isComponentStillExists && isComponentInsideRef) {
      handleFunction(event);
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
};
