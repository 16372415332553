import React from 'react';
import styled from 'styled-components';

// @ts-ignore
import { ReactComponent as PawIcon } from 'ui/atoms/loader.svg';

export function LoadableContent(props: {
  isLoading: boolean;
  children: React.ReactNode;
}) {
  return props.isLoading ? (
    <Root>
      <Loader>
        <LoaderIcon />
        <Label>Loading...</Label>
      </Loader>
    </Root>
  ) : (
    <>{props.children}</>
  );
}

const LoaderIcon = styled(PawIcon)`
  width: 24px;
  max-width: 100%;
  .icon {
    fill: rgb(52, 76, 164);
  }
`;

const Label = styled.div``;

const Loader = styled.div`
  display: flex;
  align-items: center;
  ${Label} {
    margin-left: 9px;
  }
`;

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${Loader} {
    margin-top: 220px;
  }
`;
