import React from 'react';
import { Link } from 'react-router-dom';
import { Title } from 'ui/templates/cohfirm-page-template';

export function ConfirmEmailFailedPage() {
  return (
    <>
      <Title>Something wrong</Title>
      <Link to="/login">← Back to Login</Link>
    </>
  );
}
