import { useEffect, useState } from 'react';

export function useGeolocation() {
  const [geolocation, setGeoLocation] = useState(null);

  function getLocation(position) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;

    setGeoLocation({
      latitude,
      longitude,
    });
  }

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(getLocation, () =>
      setGeoLocation({}),
    );
  }, []);
  return geolocation;
}
