import { declareResource } from 'libs/reatom-toolkit';

import { Image } from 'types';
import { publicApi } from 'api';

export interface ServiceType {
  icon_url?: any;
  id: number;
  title: string;
}

export interface ServiceType2 {
  icon_url?: any;
  id: number;
  title: string;
}

export interface ServiceOwner {
  id: number;
  company_name: string;
  distance: number;
  rating?: number;
  is_favorite: boolean;
  is_open: boolean;
  open_till: string;
  closed_till: string;
  background_image: Image;
  latitude?: number;
  longitude?: number;
  is_active_subscription: boolean;
  service_type: ServiceType2[];
}

export interface RootObject {
  service_type: ServiceType;
  results_count: number;
  service_owners: ServiceOwner[];
}

interface BasicSearchResultItem {
  results_count: number;
  service_type: ServiceType;
  service_owners: ServiceOwner[];
}

export const BasicSearchResource = declareResource<
  BasicSearchResultItem[],
  {
    search?: string;
    geoLocation: {
      latitude: number;
      longitude: number;
    };
  }
>('Basis Search', [], async ({ geoLocation, search }) => {
  const { data } = await publicApi.get('/service-owner', {
    params: {
      search,
      ...geoLocation,
    },
  });
  return data.filter(item => item.service_owners.length > 0);
});
