import {
  declareAtom,
  declareAction,
  combine,
  Atom,
  PayloadActionCreator,
} from '@reatom/core';

type IDType = string | number;

export type List<T> = [
  Atom<{
    data: {
      [key in IDType]: T;
    };
    ids: Array<IDType>;
  }>,
  {
    add: PayloadActionCreator<T>;
    addList: PayloadActionCreator<T[]>;
    remove: PayloadActionCreator<number>;
    edit: PayloadActionCreator<Partial<T>>;
    clearAction: PayloadActionCreator<void>;
  },
];

const MapperSymbol = Symbol();

export function declareList<T extends { id: IDType }>(name: string | [string]): List<T> {
  const prefix = Array.isArray(name) ? name[0] : name;

  const addAction = declareAction<T>(`${prefix} list add`);
  const addListAction = declareAction<T[]>(`${prefix} list add list`);
  const removeAction = declareAction<number>(`${prefix} list remove`);
  const editAction = declareAction<Partial<T>>(`${prefix} list edit`);
  const clearAction = declareAction<void>(`${prefix} list clear lust`);

  const data = declareAtom<{ [key in IDType]: T }>(name, {}, on => [
    on(addAction, (list, data) => {
      return {
        ...list,
        [data.id]: data,
      };
    }),
    on(addListAction, (list, newItems) => ({
      ...list,
      ...newItems.reduce(
        (acc, item) => ({
          ...acc,
          [item.id]: item,
        }),
        {},
      ),
    })),
    on(removeAction, (list, id) => {
      delete list[id];
      return { ...list };
    }),
    on(editAction, (list, data) => {
      if (data[MapperSymbol]) {
        return {
          ...list,
          [data.id]: {
            ...list[data.id],
            ...data[MapperSymbol](list[data.id]),
          },
        };
      } else {
        return {
          ...list,
          [data.id]: {
            ...list[data.id],
            ...data,
          },
        };
      }
    }),
    on(clearAction, () => ({})),
  ]);

  const ids = declareAtom<IDType[]>([`${prefix} ids`], [], on => [
    on(addAction, (list, payload) => [...list, payload.id]),
    on(addListAction, (list, newItems) => [
      ...list,
      ...newItems.map(item => item.id),
    ]),
    on(removeAction, (list, payload) => list.filter(id => id !== payload)),
    on(clearAction, () => []),
  ]);

  const atom = combine({
    ids,
    data,
  });

  function edit(...args) {
    if (args.length === 1) {
      return editAction(...args);
    }

    if (args.length === 2) {
      const [id, mapper] = args;
      return editAction({
        id,
        [MapperSymbol]: mapper,
      });
    }
  }

  return [
    atom,
    {
      add: addAction,
      addList: addListAction,
      remove: removeAction,
      edit,
      clear: clearAction,
    },
  ];
}
