import React from 'react';
import styled from 'styled-components';

import { Form } from 'libs/forms';
import {
  FieldsGroup,
  FormFields,
  FormModalContentTemplate,
  InputField,
  SubmitFormButton,
} from 'ui/ecosystems/forms';
import * as Yup from 'yup';

const schema = Yup.object({
  currentPassword: Yup.string().required(' '),
  password: Yup.string().required(' '),
});

export function ChangePasswordModalForm(props: {
  onSubmit({ password: string, currentPassword: string }): Promise<void>;
}) {
  // @ts-ignore
  async function handleFormSubmit({ password, currentPassword }) {
    try {
      await props.onSubmit({ password, currentPassword });
    } catch (error) {
      return {
        serverError: error.response.data,
      };
    }
  }

  return (
    <Form
      schema={schema}
      onSubmit={handleFormSubmit}
      render={({
        handleSubmit,
        submitErrors,
        submitSucceeded,
        dirtySinceLastSubmit,
      }) => (
        <FormModalContentTemplate
          renderContent={() => (
            <FormFields>
              <FieldsGroup>
                <PasswordsGroup>
                  <InputField
                    type="password"
                    name="currentPassword"
                    placeholder="Old Password"
                  />
                  <InputField
                    type="password"
                    name="password"
                    placeholder="New Password"
                  />
                </PasswordsGroup>
              </FieldsGroup>
              <a href="">Forgot password?</a>
              {submitErrors?.serverError && (
                <FormError>{submitErrors.serverError.message}</FormError>
              )}
            </FormFields>
          )}
          renderActions={() => (
            <SubmitFormButton
              onClick={handleSubmit}
              disabled={submitSucceeded && !dirtySinceLastSubmit}
            >
              {submitSucceeded && !dirtySinceLastSubmit
                ? 'Saved'
                : 'Save Changes'}
            </SubmitFormButton>
          )}
        />
      )}
    />
  );
}

const PasswordsGroup = styled(FieldsGroup)`
  grid-template-columns: 1fr 1fr;
`;

const FormError = styled.div`
  text-align: center;
  color: var(--error);
`;
