import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import qs from 'qs';

export function useQueryParams<T>(): [T, (params: T) => void] {
  const location = useLocation();
  const history = useHistory();

  const setQueryStringParameter = useCallback(
    (value: any) => {
      const cleanValue = value === '' ? undefined : value;
      const values = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });
      const newUrl =
        location.pathname +
        qs.stringify(
          { ...values, ...cleanValue },
          {
            addQueryPrefix: true,
            encodeValuesOnly: true,
          },
        );
      history.replace(newUrl);
    },
    [location],
  );

  const queryStringParameters = useMemo<T>(
    // @ts-ignore
    () => {
      return qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });
    },
    [location],
  );

  return [queryStringParameters, setQueryStringParameter];
}
