import * as yup from 'yup';

export const schema = yup.object({});

export function parse(values) {
  return values;
}

interface ApiInput {
  company_name: string;
  service_types: number[];
  phone_numbers: string[];
  web_site: string;
  company_address: string;
  company_address2: string;
  city: string;
  state: string;
  zip_code: string;
  latitude: number;
  longitude: number;
  overview: string;
  avatar_id: number;
  hero_image_id: number;
}

export interface FormState {
  id: number;
  firstName: string;
  lastName: string;
  companyName: string;
  email: string;
  serviceTypeId: number;
  phoneNumber: string;
  city: string;
  state: string;
  zipCode: string;
  companyAddress: string;
  companyAddress2: string;
  avatar: { id: number };
  coverImage: { id: number };
  website: string;
  about: string;
  latitude: number;
  longitude: number;
}

export function format(formState: FormState): ApiInput {
  return {
    company_name: formState.companyName,
    service_types: [formState.serviceTypeId],
    phone_numbers: [formState.phoneNumber],
    web_site: formState.website,
    company_address: formState.companyAddress,
    company_address2: formState.companyAddress2,
    city: formState.city,
    state: formState.state,
    zip_code: formState.zipCode,
    latitude: formState.latitude,
    longitude: formState.longitude,
    overview: formState.about,
    avatar_id: formState.avatar?.id ?? null,
    hero_image_id: formState.coverImage?.id ?? null,
  };
}
