import { combine, map, declareAction } from '@reatom/core';
import { useAtom } from '@reatom/react';
import {
  currentPetOwnerAtom,
  parsePetOwnerProfile,
  getCurrentPetOwnerProfileAction,
} from 'features/pet-owner-profile';
import {
  currentServiceProviderAtom,
  getCurrentServiceProviderProfileAction,
  parseServiceProvideProfile,
  ServiceProviderProfile,
} from 'features/service-provider-profile';
import { privateApi } from 'api';

import { PetOwner } from '../../types';

export const setCurrentProfile = declareAction(
  'set current profile',
  async (_, { dispatch }) => {
    const { data } = await privateApi.get('/common/user/self-profile');
    if (data.company_name) {
      const profile = parseServiceProvideProfile(data);
      dispatch(getCurrentServiceProviderProfileAction.done(profile));
    } else {
      const profile = parsePetOwnerProfile(data);
      dispatch(getCurrentPetOwnerProfileAction.done(profile));
    }
  },
);

type CurrentProfile = {
  isPetOwner: boolean;
  isServiceProvider: boolean;
} & (
  | {
      isPetOwner: true;
      profile: PetOwner;
    }
  | {
      isServiceProvider: true;
      profile: ServiceProviderProfile;
    }
);

export const ProfileAtom = map<CurrentProfile>(
  combine({
    petOwner: currentPetOwnerAtom,
    serviceProvider: currentServiceProviderAtom,
  }),
  profiles => {
    if (profiles.petOwner.id) {
      return {
        isPetOwner: true,
        isServiceProvider: false,
        profile: profiles.petOwner,
      };
    }
    if (profiles.serviceProvider.id) {
      return {
        isServiceProvider: true,
        isPetOwner: false,
        profile: profiles.serviceProvider,
      };
    }

    return {
      profile: null,
      isPetOwner: false,
      isServiceProvider: false,
    };
  },
);

export function useCurrentProfile(): CurrentProfile {
  return useAtom(ProfileAtom);
}
