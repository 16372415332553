import { declareAsyncAction } from 'libs/reatom-toolkit';
import { declareAction, declareAtom } from '@reatom/core';

interface AuthData {
  email: string;
  password: string;
}

export const serviceProviderAuthAction = declareAsyncAction<string, AuthData>(
  'get token',
  async (authData, { publicApi, dispatch }) => {
    const {
      data: { token },
    } = await publicApi.post('/service-owner/auth', authData);
    dispatch(setTokenAction(token));
    return token;
  },
);
export const petOwnerAuthAction = declareAsyncAction<string, AuthData>(
  'get token',
  async (authData, { publicApi, dispatch }) => {
    const {
      data: { token },
    } = await publicApi.post('/pet-owner/auth', authData);
    dispatch(setTokenAction(token));
    return token;
  },
);

const localStorageKey = 'pawzr_token';

export const setTokenFromStorageAction = declareAction();

export const setTokenAction = declareAction<string>('set token', token => {
  localStorage.setItem(localStorageKey, token);
});

export const clearTokenAction = declareAction('clear token', () => {
  localStorage.removeItem(localStorageKey);
});

export const TokenAtom = declareAtom<string>(['token'], '', on => [
  on(setTokenFromStorageAction, () => {
    const token = localStorage.getItem(localStorageKey);
    return token || '';
  }),
  on(serviceProviderAuthAction.done, (_, payload) => payload),
  on(petOwnerAuthAction.done, (_, payload) => payload),
  on(setTokenAction, (_, payload) => payload),
  on(clearTokenAction, () => ''),
]);
