import { declareResource, declareAsyncAction } from 'libs/reatom-toolkit';
import { privateApi } from 'api';

import {
  Dialog,
  DialogsList,
  Message,
  MessagesList,
  ReceiveNewMessagesAction,
} from 'features/messages';

import {
  PostNewMessageAction,
  InterlocutorType,
} from '../messages/dialogs.model';

const [, dialogsActions] = DialogsList;
const [, messagesActions] = MessagesList;

export function getDialogId(dialog) {
  return `po-${dialog.pet_owner.id}`;
}

export function createDialogByMessagesApiResponse(message): Dialog {
  return {
    id: getDialogId(message),
    messagesIds: [message.id],
    interlocutor: {
      id: message.pet_owner.id,
      type: InterlocutorType.petOwner,
      name: `${message.pet_owner.first_name} ${message.pet_owner.last_name}`,
      avatar: message.pet_owner.avatar?.url,
    },
    unreadCount: message.unread_count,
  };
}

export const ServiceProviderMessages = declareResource(
  'Service Provider Dialogs',
  [],
  async (_, store) => {
    const { data } = await privateApi.get(`/service-owner/messages/last-all`);

    const dialogs: Dialog[] = data.map(createDialogByMessagesApiResponse);

    store.dispatch(messagesActions.addList(data));
    store.dispatch(dialogsActions.addList(dialogs));

    return dialogs.map(dialog => dialog.id);
  },
);

export const getMessagesForDialogAction = declareAsyncAction<
  void,
  { dialogId: string; petOwnerId: number; lastMessageId: number }
>('', async ({ dialogId, petOwnerId, lastMessageId }, store) => {
  const { data } = await privateApi.get(
    `/service-owner/pet-owners/${petOwnerId}/messages`,
    {
      params: {
        id: lastMessageId,
      },
    },
  );

  const messages: Message[] = data;

  store.dispatch(
    ReceiveNewMessagesAction({
      dialogId,
      messages,
    }),
  );
});

export const ServiceOwnerPostsMessageToPetOwnerAction = declareAsyncAction<
  void,
  {
    petOwnerId: number;
    message: string;
  }
>('asdf', async ({ petOwnerId, message }, store) => {
  const { data } = await privateApi.post(
    `/service-owner/pet-owners/${petOwnerId}/messages`,
    {
      body: message,
      file_ids: [],
    },
  );
  store.dispatch(
    PostNewMessageAction({
      dialogId: `po-${petOwnerId}`,
      message: data,
    }),
  );
});
