import { declareAction } from '@reatom/core';
import { useAtom } from '@reatom/react';

import { uploadSingleAttachment } from 'services/attachments';
import {
  declareList,
  useAsyncAction,
  declareAsyncAction,
  call,
} from 'libs/reatom-toolkit';

type Attachment = {
  id: string;
  type?: string;
  url: string;
  loading?: {
    isLoading: boolean;
    loaded: number;
    total: number;
  };
  original_name?: string;
};

export const [Attachments, AttachmentsActions] = declareList<Attachment>(
  'Attachments',
);

let uniqId = 0;
function getId() {
  return `attachment_${uniqId++}`;
}

export const addFileToUploadingAction = declareAsyncAction<
  string,
  { file: File; cropOptions?: {} }
>('Attachments | Start uploading', ({ file, cropOptions }, store) => {
  const id = getId();
  call(store, addNewFile({ file, id, cropOptions }));
  return id;
});

export const addNewFile = declareAction<{
  id: string;
  file: File;
  cropOptions?: {};
}>('add file', async ({ id, file, cropOptions }, { dispatch }) => {
  dispatch(
    AttachmentsActions.add({
      id,
      url: '',
      loading: {
        isLoading: true,
        loaded: 0,
        total: 0,
      },
    }),
  );
  const { data } = await uploadSingleAttachment(file, {
    cropOptions,
    onProgressChange({ loaded, total }) {
      dispatch(
        AttachmentsActions.edit({
          id,
          url: '',
          loading: {
            isLoading: true,
            loaded,
            total,
          },
        }),
      );
    },
  });
  dispatch(AttachmentsActions.add(data));
  dispatch(
    AttachmentsActions.edit({
      ...data,
      id,
      _id: data.id,
      loading: {
        isLoading: false,
      },
    }),
  );
  return data;
});

export function useFileUploader() {
  const upload = useAsyncAction(addFileToUploadingAction);
  return {
    upload,
  };
}

export function useAttachment(id: string): Attachment {
  return useAtom(Attachments, ({ data }) => data[id], [id]);
}

export function useAttachments(ids: string[]): Attachment[] {
  return useAtom(
    Attachments,
    ({ data }) =>
      ids
        .map(id => data[id])
        .filter(attachment => !attachment.loading?.isLoading),
    [ids],
  );
}
