import { privateApi } from 'api';

type onProgressChangeCallback = (progress: {
  loaded: number;
  total: number;
}) => void;

type uploadFileServiceConfig = {
  onProgressChange?: onProgressChangeCallback;
  fieldName?: string;
  cropOptions?: {
    startX: number;
    startY: number;
    endX: number;
    endY: number;
    width?: number;
    height?: number;
  };
};

export async function uploadSingleAttachment(
  file: File,
  {
    onProgressChange,
    fieldName = 'file',
    cropOptions,
  }: uploadFileServiceConfig,
) {
  const formData = new FormData();

  const withCrop = Boolean(cropOptions);
  formData.append(fieldName, file);

  if (withCrop) {
    Object.entries(cropOptions).forEach(([key, value]) => {
      formData.append(key, value);
    });
  }

  return await privateApi.post(
    withCrop ? '/common/files/crop' : '/common/files',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: progressEvent => {
        if (onProgressChange) {
          onProgressChange({
            loaded: progressEvent.loaded,
            total: progressEvent.total,
          });
        }
      },
    },
  );
}
