// @flow
import { useAction, useAtom } from '@reatom/react';

import { Resource } from './declareResource';

export function useResource<T, V>(resource: Resource<T, V>) {
  const data = useAtom<T>(resource);
  const isLoading = useAtom(resource.isLoading);
  const error = useAtom(resource.error);

  const get = useAction<V>(resource.get);
  const set = useAction(resource.get.done);

  return { data, isLoading, error, get, set };
}
