import React from 'react';
import styled from 'styled-components';

import { Button } from 'ui/index';
import { useForm, useFormState } from 'react-final-form';
import { ReactComponent as LoaderIcon } from 'ui/atoms/loader.svg';

interface Props {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
}

export function SubmitFormButton(props: Props) {
  const { submitSucceeded, dirtySinceLastSubmit, submitting } = useFormState();
  const { submit } = useForm();

  return (
    <SubmitFormButtonRoot
      className={props.className}
      onClick={submit}
      disabled={(submitSucceeded && !dirtySinceLastSubmit) || props.disabled}
      {...props}
    >
      {submitting ? (
        <Loader />
      ) : !submitting && submitSucceeded && !dirtySinceLastSubmit ? (
        'Saved'
      ) : (
        props.children
      )}
    </SubmitFormButtonRoot>
  );
}

export function SubmitButton(
  props: { isSubmitting: boolean } & React.ComponentType<typeof Button>,
) {
  const { isSubmitting, children, ...rest } = props;

  return <Button {...rest}>{isSubmitting ? <Loader /> : children}</Button>;
}

const Loader = styled(LoaderIcon)`
  width: 32px;
  .icon {
    fill: #fff;
  }
`;

const SubmitFormButtonRoot = styled(Button)`
  min-width: 276px;
`;
