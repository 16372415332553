import React from 'react';
import styled from 'styled-components';

import { Logo } from 'ui/atoms/logo/logo';
import { Link } from 'react-router-dom';
import { mediaPhone, mediaTablet } from 'ui/styles/media';

interface Props {
  renderContent?: () => React.ReactNode;
}

export function BaseHeaderTemplate(props: Props) {
  return (
    <Header>
      <HeaderInner>
        <Link to="/">
          <Logo blue />
        </Link>
        {props.renderContent && <Content>{props.renderContent()}</Content>}
      </HeaderInner>
    </Header>
  );
}

const Content = styled.div`
  display: flex;
  justify-items: flex-end;
  align-items: stretch;
  height: 100%;
`;

const HeaderInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: var(--contentWidth);
`;

const Header = styled.div`
  position: sticky;
  top: 0;
  width: 100vw;
  z-index: 1;
  display: flex;
  justify-content: center;
  height: var(--headerHeight);
  background: #fff;
  box-shadow: 0 16px 16px rgba(104, 130, 159, 0.16);
`;
