import styled, { css } from 'styled-components';

interface Props {
  isInvalid?: boolean;
  disabled?: boolean;
  rounded?: boolean;
  secondary?: boolean;
  middle?: boolean;
}

export const focusCss = css`
  border-color: #3c5bcc;
  outline: none;
`;

export const placeholderCss = css`
  color: var(--placeholderColor);
`;

export const Placeholder = styled.div`
  ${placeholderCss}
`;

export const InputWrapper = styled.div<Props>`
  height: 40px;
  border: 2px solid var(--baseInputBorderColor);
  border-radius: 8px;
  background: #fff;
  font-size: 14px;
  line-height: 21px;
  padding: 0 16px;

  display: flex;
  align-items: center;
   --placeholderColor: #a1b3ce;

  ::placeholder {
    ${placeholderCss}
  }

  ${props =>
    props.isInvalid &&
    css`
      border-color: var(--error);
      ::placeholder {
        color: var(--error);
      }
      --placeholderColor: var(--error);
    `}

  :focus {
    ${focusCss}
  }

  ${props =>
    props.theme?.form?.rounded ||
    (props.rounded &&
      css`
        border-radius: 100px;
      `)}

  ${props =>
    props.theme?.form?.secondary ||
    (props.secondary &&
      css`
        background: var(--baseInputBorderColor);
      `)}
  
    ${props =>
      (props.theme?.form?.middle || props.middle) &&
      css`
        height: 48px;
        font-size: 16px;
        line-height: 24px;
      `}
`;
