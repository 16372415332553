import { declareAsyncAction, declareResource } from 'libs/reatom-toolkit';

import {
  DialogsList,
  InterlocutorType,
  ReceiveNewMessagesAction,
} from 'features/messages';
import { privateApi } from 'api';

import { MessagesList } from 'features/messages';
import { Message } from 'features/messages/messages.model';

import { declareAction } from '@reatom/core';

import { PostNewMessageAction, Dialog } from 'features/messages';

import { ServiceProviderProfile } from '../service-provider-profile';

const [, dialogsActions] = DialogsList;
const [, messagesActions] = MessagesList;

const availableTypes = {
  pet: InterlocutorType.pet,
  serviceOwner: InterlocutorType.serviceOwner,
  petOwner: InterlocutorType.serviceOwner,
};

export function createDialogByMessagesApiResponse(dialog): Dialog {
  const sender = dialog.pet_card || dialog.service_owner;

  return {
    id: getDialogId(dialog),
    interlocutor: {
      id: sender.id,
      type: availableTypes[dialog.pet_card ? 'pet' : 'serviceOwner'],
      name: dialog.pet_card
        ? dialog.pet_card.name
        : dialog.service_owner.company_name,
      avatar: sender.avatar?.url,
    },
    messagesIds: [dialog.id],
    unreadCount: dialog.unread_count,
  };
}

const openNewDialogAction = declareAction();

export const PetOwnerDialogsIdsResource = declareResource(
  'Pet Owner Dialogs',
  [],
  async (_, store) => {
    const { data } = await privateApi.get(`/pet-owner/messages/last-all`);

    const dialogs: Dialog[] = data
      .filter(dialog => availableTypes[dialog.sender] !== undefined)
      .map(createDialogByMessagesApiResponse);

    store.dispatch(messagesActions.addList(data));
    store.dispatch(dialogsActions.addList(dialogs));

    return dialogs.map(dialog => dialog.id);
  },
  on => [on(openNewDialogAction, (state, newId) => [newId, ...state])],
);

export const getMessagesForDialogAction = declareAsyncAction<
  void,
  {
    dialogId: string;
    lastMessageId: number;
    senderId: number;
  }
>(
  'getDialog',
  async ({ dialogId, lastMessageId, petId, serviceOwnerId }, store) => {
    let url;
    if (petId) {
      url = `/pet-owner/pet-cards/${petId}/messages`;
    } else {
      url = `/pet-owner/service-owners/${serviceOwnerId}/messages`;
    }

    const { data } = await privateApi.get<Message[]>(url, {
      params: {
        id: lastMessageId,
        limit: 30,
      },
    });

    const messages: Message[] = data;

    store.dispatch(
      ReceiveNewMessagesAction({
        dialogId,
        messages,
      }),
    );
  },
);

export const petOwnerPostMessageToServiceProviderAction = declareAsyncAction<
  void,
  { serviceOwnerId: number; message: string; attachments: number[] }
>(
  'petOwnerPostMessageToServiceProviderAction',
  async ({ serviceOwnerId, message, attachments }, store) => {
    const { data } = await privateApi.post(
      `/pet-owner/service-owners/${serviceOwnerId}/messages`,
      {
        body: message,
        file_ids: attachments,
      },
    );

    const dialogId = getDialogId(data);
    store.dispatch(PostNewMessageAction({ dialogId, message: data }));
  },
);

export const petOwnerPostMessageToPetAction = declareAsyncAction<
  void,
  { petId: number; message: string; attachments: number[] }
>(
  'petOwnerPostMessageToPetAction',
  async ({ petId, message, attachments }, store) => {
    const { data } = await privateApi.post(
      `/pet-owner/pet-cards/${petId}/messages`,
      {
        body: message,
        file_ids: attachments,
      },
    );

    const dialogId = getDialogId(data);
    store.dispatch(PostNewMessageAction({ dialogId, message: data }));
  },
);

const map = {
  pet: dialog => `pet-${dialog.pet_card.id}`,
  serviceOwner: dialog => `sp-${dialog.service_owner.id}`,
  petOwner: dialog =>
    dialog.pet_card
      ? `pet-${dialog.pet_card.id}`
      : `sp-${dialog.service_owner.id}`,
};

export function getDialogId(message) {
  return map[message.sender](message);
}

export function createDialogWithServiceProvider(
  serviceProvider: ServiceProviderProfile,
) {
  return {
    id: `sp-${serviceProvider.id}`,
    interlocutor: {
      type: InterlocutorType.serviceOwner,
      id: serviceProvider.id,
      name: serviceProvider.companyName,
      avatar: serviceProvider.avatar?.url,
    },
    messagesIds: [],
  };
}

export const openDialogWithServiceProvider = declareAction<{
  serviceProvider: ServiceProviderProfile;
}>(
  ['PO open dialog with service provider'],
  ({ serviceProvider }, { getState, history, dispatch }) => {
    const dialogId = `sp-${serviceProvider.id}`;

    const existingDialog = getState(PetOwnerDialogsIdsResource);

    if (!existingDialog.some(id => id === dialogId)) {
      const newDialog = createDialogWithServiceProvider(serviceProvider);
      dispatch(dialogsActions.add(newDialog));
      dispatch(openNewDialogAction(dialogId));
    }

    history.push(`/messages/${dialogId}`);
  },
);
