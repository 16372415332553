import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import Cropper from 'react-easy-crop';

import { Button } from 'ui';

import { ModalWindowTemplate } from '../../../modals/templates';
import { FormModalContentTemplate } from '../..';

export interface CropOptions {
  aspect: number;
  cropSize?: {
    width: number;
    height: number;
  };
  cropShape: 'round' | 'rect';
}

interface Props {
  image?: string | null;
  isOpened: boolean;
  onCropSelected;
  onClose(): void;
  cropOptions: CropOptions;
}

export function ImageCropModal(props: Props) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const [cropData, setCropData] = useState<{
    startX: number;
    startY: number;
    endX: number;
    endY: number;
  } | null>(null);

  const container = useRef<HTMLElement>();

  function onCropComplete(_, data) {
    setCropData({
      startX: data.x,
      startY: data.y,
      endX: data.x + data.width,
      endY: data.y + data.height,
    });
  }

  function handleSelect() {
    props.onCropSelected(cropData);
    props.onClose();
  }

  return (
    <>
      <ModalWindowTemplate
        isOpened={props.isOpened}
        onClose={props.onClose}
        title="Crop image size"
        renderContent={() => (
          <FormModalContentTemplate
            renderContent={() => (
              <CropContainer ref={container}>
                <Cropper
                  image={props.image}
                  crop={crop}
                  {...(props.cropOptions ?? {})}
                  restrictPosition={false}
                  zoom={zoom}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
              </CropContainer>
            )}
            renderActions={() => (
              <Actions>
                <Button outline onClick={props.onClose}>
                  Back
                </Button>
                <Button onClick={handleSelect}>Crop and continue</Button>
              </Actions>
            )}
          />
        )}
      />
    </>
  );
}

const Actions = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;

  grid-gap: 16px;
`;

const CropContainer = styled.div`
  position: relative;
  height: 456px;
`;
