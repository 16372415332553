import React from 'react';
import styled from 'styled-components';

import { QueryLink } from 'libs/router';
import { adaptive } from 'ui/styles/addaptive-provider';

export const CategoryName = styled(
  (props: {
    service: { id: number | undefined; title: string | undefined };
    count: any;
    className?: string;
  }) => {
    return (
      <CategoryRootHeader className={props.className}>
        <Title as={QueryLink} name="serviceType" value={props.service?.id}>
          {props.service?.title}
        </Title>
        {props.count && (
          <Count>
            {props.count} Result{props.count > 1 && 's'}
          </Count>
        )}
      </CategoryRootHeader>
    );
  },
)``;

const Count = styled.span`
  :before {
    content: ' — ';
  }
`;

const Title = styled.div`
  font-weight: 500;

  color: #344ca4;
`;

const CategoryRootHeader = styled.div`
  font-size: 24px;
  line-height: 32px;

  ${adaptive} {
    font-size: 16px;
    line-height: 24px;
  }
`;
