import styled, { css } from 'styled-components';

interface ContentBlockProps {
  background?: boolean;
  className?: string;
}

const filledCss = css`
  background: #f7f8ff;
  border: 2px solid #f7f8ff;
`;

export const ContentBlock = styled.div<ContentBlockProps>`
  box-sizing: border-box;
  background: var(--white);
  border-radius: 8px;
  border: 2px solid var(--baseInputBorderColor);

  ${props => props.background && filledCss}
`;

// @ts-ignore
ContentBlock.filledCss = filledCss;
