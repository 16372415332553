import { useAction } from '@reatom/react';

import { clearTokenAction } from './auth.model';

export function useSession() {
  const logout = useAction(clearTokenAction);

  return {
    logout,
  };
}
