import React from 'react';
import { Link } from 'react-router-dom';

import { Title, Message } from 'ui/templates/cohfirm-page-template';

export function ConfirmEmailWasSendPage() {
  return (
    <>
      <Title>Thanks for registration!</Title>
      <Message>
        Your account has been created and a verification email has been sent to
        your registered email address. Please click on the verification link
        included in the email to activate your account
      </Message>
      <Link to="/">← Back to Homepage</Link>
    </>
  );
}
