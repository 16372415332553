import React from 'react';

import styled from 'styled-components';

import Header from '../../_components/Header/Header';
import Footer from '../../_components/Footer/Footer';

interface Props {
  children: React.ReactNode;
}

export function ConfirmPageTemplate(props: Props) {
  return (
    <React.Fragment>
      <Header fixed />
      <Content>{props.children}</Content>
      <Footer />
    </React.Fragment>
  );
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  min-height: 80vh;
  padding: 0 15px;
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 12px;
  text-align: center;
`;

export const Message = styled.div`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
  text-align: center;
  width: 546px;
  max-width: 100%;
  padding: 0 10px;
`;
