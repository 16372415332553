import React from 'react';

import {
  DialogsTemplate,
  PreviewDialog,
  DialogsList,
  useDialog,
  useMessagesSubscription,
} from 'features/messages';
import { useListItemData } from 'libs/reatom-toolkit/useList';
import { DialogTemplate } from 'features/messages/ui/templates/dialog-template';

import { useAction } from '@reatom/react';

import {
  getDialogId,
  getMessagesForDialogAction,
  ServiceOwnerPostsMessageToPetOwnerAction,
} from '../service-provider-messages.model';

export function ServiceOwnerDialogs(props) {
  useMessagesSubscription(getDialogId);

  return (
    <DialogsTemplate
      dialogsIds={props.dialogsIds}
      renderDialogPreview={id => <DialogPreview dialogId={id} />}
      renderDialog={id => <Dialog dialogId={id} />}
    />
  );
}

export function SPDialog(props) {
  const { messages, getNextPage } = useDialog(props.dialogId, {
    getMessagesAction: getMessagesForDialogAction,
  });

  const dialog = useListItemData(DialogsList, props.dialogId);
  const ServiceOwnerPostsMessageToPetOwner = useAction(
    ServiceOwnerPostsMessageToPetOwnerAction,
  );

  async function handlePostMessage(message: string) {
    await ServiceOwnerPostsMessageToPetOwner({
      message,
      petOwnerId: dialog.interlocutor.id,
    });
  }

  const poMessages = messages
    .map(message => {
      if (message.files.length > 0) {
        return message.files.map(file => {
          const [fileName] = file.original_name.split(/(?:\.([^.]+))?$/);
          const poName = `${message.pet_owner.first_name} ${message.pet_owner.last_name}`;
          const body = `${poName} has shared document "${fileName}" to you`;
          const key = `${message.id}${file.id}`;
          return {
            ...message,
            ...file,
            body: body,
            id: message.id,
            fileId: file.id,
            key: key,
          };
        });
      }
      return message;
    })
    .flat();

  return (
    <DialogTemplate
      dialog={dialog}
      messages={poMessages}
      getIsOwnMessage={message => message.sender === 'serviceOwner'}
      onPostMessage={handlePostMessage}
      getNextPage={() =>
        getNextPage({
          petOwnerId: dialog.interlocutor.id,
        })
      }
    />
  );
}
