import styled from 'styled-components';

export const Modal = styled.div`
  background: #ffffff;
  border-radius: 8px;
  padding: 32px 0 0;
  width: 480px;
  max-width: 100vw;
  position: relative;
`;
