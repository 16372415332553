import { useEffect } from 'react';
import { useAction, useAtom } from '@reatom/react';

import { TokenAtom } from 'features/authentication';
import { createSocket } from 'api';

import { Message } from './messages.model';
import { PostNewMessageAction } from './dialogs.model';

export function useMessagesSubscription(
  getDialogId: (message: Message) => string | undefined,
) {
  const token = useAtom(TokenAtom);

  const addMessage = useAction(PostNewMessageAction);

  function handleGetMessage(message) {
    const dialogId = getDialogId(message);

    if (dialogId) {
      addMessage({
        dialogId,
        message,
        bySocket: true,
      });
    }
  }

  useEffect(() => {
    if (token) {
      const ws = createSocket('/api/private/chat');
      ws.onopen = function () {
        const token_user = `Bearer ${token}`;
        const authElements = '{"authorization":"' + token_user + '"}';
        ws.send(authElements);
      };

      ws.onmessage = function (event) {
        const data = JSON.parse(event.data);
        console.log('data', data);

        if (data.body) {
          handleGetMessage(data);
        }
      };

      ws.onerror = function (error) {
        console.error('ws connection error', error);
      };

      return () => ws.close();
    }

    return () => {};
  }, [token]);
}
