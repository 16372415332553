import React from 'react';
import { InputProps } from 'libs/forms';

import { DropdownTemplate, DropdownSelectAbleItem } from '../../../dropdown';
import { Placeholder } from '../../atoms/input-wrapper';

type Props = InputProps<unknown> & {
  options: Array<{
    value: unknown;
    label: string | React.ReactNode;
  }>;
  placeholder?: string;
  isInvalid?: boolean;
};

export function SimpleSelector(props: Props) {
  const options = props.options || [];
  const currentValueLabel = options.find(
    option => option.value === props.value,
  );

  return (
    <DropdownTemplate
      isInvalid={props.isInvalid}
      renderLabel={() =>
        currentValueLabel?.label || (
          <Placeholder>{props.placeholder}</Placeholder>
        )
      }
      renderPopupContent={() =>
        options.map(option => (
          <DropdownSelectAbleItem onClick={() => props.onChange(option.value)}>
            {option.label}
          </DropdownSelectAbleItem>
        ))
      }
    />
  );
}
