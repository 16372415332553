import { useContext, useMemo } from 'react';
import { FieldConfig, getIn } from 'final-form';
import { FieldRenderProps, useField } from 'react-final-form';

import { useNestedName } from './nestedForm';
import { FormExtendedState } from './form';

export function useFormField<FieldValue = unknown>(
  name: string,
  config?: FieldConfig<FieldValue>,
): FieldRenderProps<FieldValue> & { shouldShowError: boolean; error: string } {
  const fieldName = useNestedName(name);
  const { disabledFields } = useContext(FormExtendedState);
  const { input, meta } = useField<FieldValue>(fieldName, config);

  const shouldShowError =
    (!!meta.error && meta.submitFailed) || meta.submitError;
  const extendedInput = useMemo(
    () => ({
      ...input,
      disabled: getIn(disabledFields, fieldName),
    }),
    [disabledFields, input, fieldName],
  );

  return {
    input: extendedInput,
    meta,
    shouldShowError,
    error: meta.submitError || meta.error,
  };
}
