import {
  createForm as createFFForm,
  FormApi,
  SubmissionErrors,
} from 'final-form';
import { useMemo } from 'react';
import { FormProps } from 'react-final-form';
import arrayMutators from 'final-form-arrays';

import { Form } from './types';

type OnSubmit<State> = (
  values: State,
  form: FormApi<State>,
  callback?: (errors?: SubmissionErrors) => void,
) =>
  | SubmissionErrors
  | Promise<SubmissionErrors | undefined>
  | undefined
  | void;

export function createForm<T>(options: FormProps<T>): Form<T> {
  return {
    FFFormApi: createFFForm({
      mutators: {
        ...arrayMutators,
      },
      ...options,
    }),
    onSubmit: options.onSubmit,
  };
}

export function useCreateForm<T>(
  options: FormProps<T>,
  deps: any[] = [],
): Form<T> {
  return useMemo<{ FFFormApi: FormApi<T>; onSubmit: OnSubmit<T> }>(
    () => createForm(options),
    deps,
  );
}
