import { Link } from 'react-router-dom';
import styled from 'styled-components';
import React from 'react';

import { DropdownItem } from 'libs/react-dropdown-elements';

export const DropDownMenu = styled.div`
  min-width: 167px;
  display: grid;
  grid-gap: 16px;

  background: #fff;
  padding: 16px 24px;
  box-shadow: 0 16px 16px rgba(104, 130, 159, 0.16);
  border-radius: 0 0 8px 8px;

  font-size: 16px;
  line-height: 24px;
  color: var(--baseLinkColor);
`;

export const MenuItem = styled(DropdownItem)`
  cursor: pointer;
`;

export function LinkMenuItem(props: { children: React.ReactNode; to: string }) {
  return (
    <MenuItem>
      <Link to={props.to}>{props.children}</Link>
    </MenuItem>
  );
}

export const Divider = styled.div`
  border-bottom: 2px solid var(--baseBorderColor);
`;
