import React, { useState } from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';

import { useFormAttachmentField } from 'features/upload-attachments';
// @ts-ignore
import { ReactComponent as DeleteIcon } from 'ui/atoms/icons/close.svg';
import { useOpenableState } from 'libs/use-openable-state';

import { InputWrapper } from '../../atoms/input-wrapper';
// @ts-ignore
import { ReactComponent as AddIcon } from './addIcon.svg';
// @ts-ignore
import { ReactComponent as ChangeIcon } from './changeIcon.svg';
import { ImageCropModal } from '../image-crop-modal';

function getImagePreviewFromFile(file): Promise<string> {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onload = e => {
      resolve(e.target.result as string);
    };
    reader.readAsDataURL(file); // convert to base64 string
  });
}

export function CoverImageField(props: { name: string }) {
  const imageCropModal = useOpenableState();
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [file, setFile] = useState();

  const {
    input,
    isLoading,
    progress,
    handleSingleFileSelect,
  } = useFormAttachmentField(props.name);

  async function handleFileSelect(files) {
    const preview = await getImagePreviewFromFile(files[0]);
    setFile(files[0]);
    setImagePreview(preview);
    imageCropModal.open();
  }

  function handleCropSelected(cropData) {
    handleSingleFileSelect(file, cropData);
  }

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop: handleFileSelect,
  });

  const cropModalJSX = imageCropModal.isOpened && (
    <ImageCropModal
      cropOptions={{
        cropShape: 'rect',
        aspect: 5.1,
      }}
      isOpened={imageCropModal.isOpened}
      onClose={imageCropModal.close}
      image={imagePreview}
      onCropSelected={handleCropSelected}
    />
  );

  if (isLoading) {
    return (
      <CoverImageRoot>
        <AddIcon />
        <Label>{progress.toFixed(0)}%</Label>
      </CoverImageRoot>
    );
  } else if (input.value?.url) {
    return (
      <>
        <CoverImage image={input.value.url}>
          <CoverImageActions>
            <Action {...getRootProps()}>
              <input {...getInputProps()} />
              <ChangeIcon />
              Change Image
            </Action>
            <Action onClick={() => input.onChange(null)}>
              <DeleteIcon />
              Delete
            </Action>
          </CoverImageActions>
        </CoverImage>
        {cropModalJSX}
      </>
    );
  }

  return (
    <>
      <CoverImageRoot {...getRootProps()}>
        <input {...getInputProps()} />
        <AddIcon />
        <Label>Add Profile Cover</Label>
      </CoverImageRoot>
      {cropModalJSX}
    </>
  );
}

const Label = styled.div`
  font-size: 14px;
  line-height: 21px;
  color: #5b6784;
`;

const CoverImageRoot = styled(InputWrapper)`
  width: 100%;
  height: 136px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${Label} {
    margin-top: 8px;
  }
`;

const Action = styled.div`
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  :hover {
    text-decoration: underline;
  }

  .icon {
    fill: #fff;
    margin-right: 4px;
  }
`;

const CoverImageActions = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: auto auto;
  grid-gap: 20px;
  background: linear-gradient(
    0deg,
    rgba(23, 37, 73, 0.5),
    rgba(23, 37, 73, 0.5)
  );
`;

const CoverImage = styled.div<{ image: string }>`
  border-radius: 8px;
  height: 136px;
  background: url(${props => props.image}) no-repeat center center;
  background-size: cover;
  position: relative;
  overflow: hidden;

  ${CoverImageActions} {
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  :hover ${CoverImageActions} {
    display: grid;
  }
`;
