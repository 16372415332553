import styled, { css } from 'styled-components';

// @ts-ignore
import spAvatarPlaceholder from './sp.svg';

// @ts-ignore
import poAvatarPlaceholder from './po.svg';

// @ts-ignore
import petAvatarPlaceholder from './pet.svg';

export const UserAvatar = styled.div<{
  avatar: string;
  bordered?: boolean;
  placeholder: string;
}>`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #dfe8f6;
  background-position: center center;
  background-size: cover;

  ${props =>
    props.avatar
      ? css`
          background-image: url(${props.avatar});
          border: 1px solid rgba(52, 76, 164, 0.1);
        `
      : css`
          background-repeat: no-repeat;
          background-image: url(${props.placeholder});
          background-size: 50%;
          background-position: center;
        `}

  ${props =>
    props.bordered &&
    css`
      border: 2px solid #fff;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    `};
`;

export const SPAvatar = styled(UserAvatar).attrs({
  placeholder: spAvatarPlaceholder,
})``;
export const POAvatar = styled(UserAvatar).attrs({
  placeholder: poAvatarPlaceholder,
})``;
export const PetAvatar = styled(UserAvatar).attrs({
  placeholder: petAvatarPlaceholder,
})``;
