import styled from 'styled-components';
import React from 'react';

import { ServiceProviderCard } from 'ui/organisms/service-provider-card/service-provider-card';

export function CompaniesList(props: { items: any }) {
  return (
    <CompanyCardList>
      {props.items.map(item => (
        <ServiceProviderCard {...item} />
      ))}
    </CompanyCardList>
  );
}

const CompanyCardList = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  justify-content: space-between;
`;
