import React from 'react';
import styled from 'styled-components';
import MaskedInput from 'react-text-mask';

import { InputProps } from 'libs/forms';

import { InputWrapper } from '../../atoms/input-wrapper';

type Props = InputProps<string> & {
  placeholder?: string;
};

// eslint-disable-next-line
const phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

export function PhoneInput(props: Props) {
  return (
    <MaskInput
      // @ts-ignore
      mask={phoneMask}
      value={props.value}
      onChange={props.onChange}
      placeholder={props.placeholder}
      disabled={props.disabled}
      isInvalid={props.isInvalid}
    />
  );
}

const MaskInput = styled(InputWrapper).attrs({
  as: MaskedInput,
})`
  width: 100%;
`;
