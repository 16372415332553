import React from 'react';
import ReactDom from 'react-dom';
import styled from 'styled-components';

export function DebugPopup({ formProps }) {
  return ReactDom.createPortal(
    <Debug id="DEBUG">
      <div>dirty: {formProps.dirty.toString()}</div>
      <div>dirty d: {JSON.stringify(formProps.dirtyFields, 0, 2)}</div>
      values
      <pre>{JSON.stringify(formProps.values, 0, 2)}</pre>
      errors
      <div>{JSON.stringify(formProps.errors, 0, 2)}</div>
      submit errors
      <div>{JSON.stringify(formProps.submitErrors, 0, 2)}</div>
    </Debug>,
    document.getElementById('modals'),
  );
}

const Debug = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 500px;
  background-color: #9a9a9a;
  z-index: 999999999;
  // fix for ios 13
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
`;
