import React, { useContext, useRef } from 'react';

import { useOpenableState, OpenableState } from 'libs/use-openable-state';
import { useOutsideClick } from 'libs/outsideClick';

const noop = () => {};
export const DropdownContext = React.createContext<OpenableState>({
  isOpened: false,
  open: noop,
  close: noop,
  toggle: noop,
});

export function useDropdown() {
  const openAbleState = useOpenableState();
  const ref = useRef<HTMLElement>();
  useOutsideClick(ref, openAbleState.close);

  const dropdownProps = {
    ref,
    openAbleState: openAbleState,
    contextValue: openAbleState,
  };

  return dropdownProps;
}

export function DropdownItem(props) {
  const dropdown = useContext(DropdownContext);

  return (
    <div
      {...props}
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        }
        dropdown.close();
      }}
    >
      {props.children}
    </div>
  );
}
