import React from 'react';
import { privateApi } from 'api';
import { ChangePasswordModalForm } from 'features/authentication';

export function PasswordChangeForm() {
  async function onSubmit({ password, currentPassword }) {
    await privateApi.put('/pet-owner/user/password', {
      password,
      current_password: currentPassword,
    });
  }

  return <ChangePasswordModalForm onSubmit={onSubmit} />;
}
