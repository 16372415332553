import React, { useState } from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';

import { Icon } from 'ui/atoms/icon';
import { useFormAttachmentField } from 'features/upload-attachments';

import { useOpenableState } from 'libs/use-openable-state';

// @ts-ignore
import icon from './upload-icon.svg';
import { ImageCropModal, CropOptions } from '../image-crop-modal';

interface Props {
  name: string;
  ImageComponent: typeof UserAvatar;
  cropOptions?: CropOptions;
}

function getImagePreviewFromFile(file): Promise<string> {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onload = e => {
      resolve(e.target.result as string);
    };

    reader.readAsDataURL(file); // convert to base64 string
  });
}

export function ImageField(props: Props) {
  const { input, handleSingleFileSelect } = useFormAttachmentField(props.name); //handleFileSelect
  const imageCropModal = useOpenableState();
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [file, setFile] = useState();

  async function handleFileSelect(files) {
    const preview = await getImagePreviewFromFile(files[0]);
    setFile(files[0]);
    setImagePreview(preview);
    imageCropModal.open();
  }

  function handleCropSelected(cropData) {
    handleSingleFileSelect(file, cropData);
  }

  const { getInputProps } = useDropzone({
    multiple: false,
    onDrop: handleFileSelect,
  });

  const url = input.value.url ?? null;

  return (
    <>
      <AvatarFieldRoot>
        <UserAvatar as={props.ImageComponent} avatar={url} url={url} />
        <Actions>
          <Label as="label">
            <input {...getInputProps()} />
            Upload New Image
          </Label>
        </Actions>
      </AvatarFieldRoot>

      {imageCropModal.isOpened && (
        <ImageCropModal
          cropOptions={
            props?.cropOptions || {
              cropShape: 'round',
              aspect: 1,
            }
          }
          isOpened={imageCropModal.isOpened}
          onClose={imageCropModal.close}
          image={imagePreview}
          onCropSelected={handleCropSelected}
        />
      )}
    </>
  );
}

const Label = styled(Icon).attrs({
  icon,
})`
  color: var(--baseLinkColor);
  cursor: pointer;
  ${Icon.imageSelector} {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
  :hover {
    text-decoration: underline;
  }
`;

const Actions = styled.div`
  ${Label} {
    margin-bottom: 4px;
  }
`;

const UserAvatar = styled.div``;

const AvatarFieldRoot = styled.div`
  display: flex;
  align-items: center;
  ${UserAvatar} {
    margin-right: 16px;
  }
`;
