import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import dayjs from 'dayjs';
import { Detail } from 'react-calendar';

import { InputProps } from 'libs/forms';
import { useOpenableState } from 'libs/use-openable-state';
import { Calendar } from 'ui/organisms/calendar/calendar';

import { useOutsideClick } from 'libs/outsideClick';

import {
  InputWrapper,
  placeholderCss,
  focusCss,
} from '../../atoms/input-wrapper';
// @ts-ignore
import { ReactComponent as CalendarIcon } from './calendar.svg';

type Props = InputProps<Date> & {
  defaultView?: Detail;
  placeholder?: string;
  maxDate?: Date;
  minDate?: Date;
};

export function DatePicker(props: Props) {
  const dropdown = useOpenableState();
  const ref = useRef<HTMLDivElement>();
  useOutsideClick(ref, dropdown.close);

  function handleChange(value: Date) {
    props.onChange(value);
    dropdown.close();
  }

  return (
    <DatePickerRoot ref={ref as any}>
      <DatePickerInput
        isOpened={dropdown.isOpened}
        onClick={dropdown.toggle}
        isInvalid={props.isInvalid}
      >
        {props.value ? (
          dayjs(props.value).format('MMM DD, YYYY')
        ) : (
          <Placeholder>{props.placeholder}</Placeholder>
        )}
        <Icon />
      </DatePickerInput>
      {dropdown.isOpened && (
        <DatePickerCalendar
          value={props.value && new Date(props.value)}
          onChange={handleChange}
          defaultView={props.defaultView}
          maxDate={props.maxDate}
          minDate={props.minDate}
        />
      )}
    </DatePickerRoot>
  );
}

const Placeholder = styled.div`
  ${placeholderCss}
`;

const Icon = styled(CalendarIcon)``;

const DatePickerInput = styled(InputWrapper)<{ isOpened: boolean }>`
  --color: #a1b3ce;
  cursor: pointer;

  ${Icon} {
    position: absolute;
    right: 16px;
  }

  ${props =>
    props.isOpened &&
    css`
      --color: #3c5bcc;
      ${focusCss}
    `}
`;

const DatePickerCalendar = styled(Calendar)``;

const DatePickerRoot = styled.div<{ ref: React.Ref<HTMLDivElement> }>`
  position: relative;

  ${DatePickerCalendar} {
    position: absolute;
    left: 0;
    top: calc(100% + 7px);
    z-index: 1;
  }
`;
