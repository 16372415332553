import React from 'react';
import { Link } from 'react-router-dom';

import {
  ConfirmPageTemplate,
  Title,
} from 'ui/templates/cohfirm-page-template/';

export function CheckEmailPage() {
  return (
    <ConfirmPageTemplate>
      <React.Fragment>
        <Title>
          Please follow the link emailed to you to reset your password
        </Title>
        <Link to="/">← Back to Homepage</Link>
      </React.Fragment>
    </ConfirmPageTemplate>
  );
}
