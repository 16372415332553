import { declareState } from 'libs/reatom-toolkit';
import { useAtom, useAction } from '@reatom/react';
import { Atom, Action } from '@reatom/core';

import { MessagesList } from './messages.model';
import { DialogsList } from './dialogs.model';

const OpenedDialogState = declareState('Opened Dialog ID', null);

const [DialogsDataAtom] = DialogsList;
const [MessagesDataAtom] = MessagesList;

export function useDialogs() {
  const [openedDialogId, openedDialogById] = useReatomState(OpenedDialogState);
  useAtom(DialogsDataAtom);
  useAtom(MessagesDataAtom);

  return {
    openedDialogId,
    openedDialogById,
  };
}

function useReatomState<T>(state: [Atom<T>, Action<T>]): [T, (v: T) => void] {
  return [useAtom(state[0]), useAction(state[1])];
}
