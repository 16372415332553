import { declareAction, getState } from '@reatom/core';

import { declareAsyncAction, declareList } from 'libs/reatom-toolkit';

import { ProfileAtom } from 'features/profile';

import { privateApi } from 'api';

import { Message, MessagesList } from './messages.model';

export enum InterlocutorType {
  pet,
  petOwner,
  serviceOwner,
}

export interface Dialog {
  id: string;
  interlocutor: {
    id: number;
    type: InterlocutorType;
    name: string;
    avatar: string;
  };
  messagesIds: number[];
  unreadCount: number;
}

export const DialogsList = declareList<Dialog>('Dialogs');

const [DialogsDataAtom, dialogsActions] = DialogsList;
const [, messagesActions] = MessagesList;

export const ReceiveNewMessagesAction = declareAction<{
  dialogId: string;
  messages: Message[];
}>('Dialogs | Receive New Messages', ({ dialogId, messages }, store) => {
  store.dispatch(messagesActions.addList(messages));

  const dialog = store.getState(DialogsDataAtom).data[dialogId];

  const newMessagesIds = messages
    .map(m => m.id)
    .filter(id => !dialog.messagesIds.includes(id));

  store.dispatch(
    dialogsActions.edit({
      id: dialog.id,
      messagesIds: [...dialog.messagesIds, ...newMessagesIds],
    }),
  );
});

export const PostNewMessageAction = declareAction<{
  dialogId: string;
  message: Message;
  bySocket?: boolean;
}>('Dialogs | Post New Message', ({ dialogId, message, bySocket }, store) => {
  store.dispatch(messagesActions.add(message));

  store.dispatch(
    dialogsActions.edit(dialogId, dialog => {
      const messagesIds = dialog.messagesIds.includes(message.id)
        ? dialog.messagesIds
        : [message.id, ...dialog.messagesIds];

      return {
        messagesIds,
        unreadCount: bySocket ? message.unread_count : dialog.unreadCount,
      };
    }),
  );
});

export const ReadMessageAction = declareAsyncAction<
  void,
  { messageId: number; dialogId: string }
>('Read Messages', async ({ messageId, dialogId }, store) => {
  const { isPetOwner } = store.getState(ProfileAtom);

  const type = isPetOwner ? 'pet-owner' : 'service-owner';
  await privateApi.put(`/${type}/messages/${messageId}/read`);

  store.dispatch(
    messagesActions.edit({
      id: messageId,
      is_read: true,
    }),
  );
});
