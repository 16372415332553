import React from 'react';
import { useLocation } from 'react-router';
import qs from 'qs';
import { Link } from 'react-router-dom';

export function QueryLink(props: {
  children: React.ReactNode;
  name: string;
  value: string;
  className?: string;
}) {
  const location = useLocation();
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });

  const query = qs.stringify(
    {
      ...params,
      [props.name]: props.value,
    },
    {
      addQueryPrefix: true,
    },
  );

  return (
    <Link to={`${location.pathname}${query}`} className={props.className}>
      {props.children}
    </Link>
  );
}
