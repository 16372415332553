import React from 'react';

import { privateApi } from 'api';

type Props = {
  children: React.ReactNode;
  url: string;
  fileId: number;
  className?: string;
};

export function DownloadAble(props: Props) {
  async function getAttachment() {
    try {
      await privateApi.head(`/common/files/${props.fileId}`);
      window.open(props.url, '_blank');
    } catch (e) {
      window.open('/404', '_blank');
    }
  }

  return (
    <div onClick={getAttachment} className={props.className}>
      {props.children}
    </div>
  );
}
