import React from 'react';

import { Detail } from 'react-calendar';

import { useFormField } from 'libs/forms';

import { DatePicker } from '../molecules/date-picker/date-picker';

interface Props {
  name: string;
  placeholder?: string;
  defaultView?: Detail;
  maxDate?: Date;
}

export function DatePickerField(props: Props) {
  const { input, shouldShowError } = useFormField(props.name);
  return (
    // @ts-ignore
    <DatePicker
      {...input}
      placeholder={props.placeholder}
      defaultView={props.defaultView}
      maxDate={props.maxDate}
      isInvalid={shouldShowError}
    />
  );
}
