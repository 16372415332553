import React, { useContext } from 'react';

import { DropdownContext } from 'libs/react-dropdown-elements';

import { Item } from '../../atoms/item';

export function SelectAbleItem(props: {
  children: React.ReactNode;
  onClick?: () => void;
}) {
  const dropdown = useContext(DropdownContext);

  return (
    <Item
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        }
        dropdown.close();
      }}
    >
      {props.children}
    </Item>
  );
}
