import React from 'react';

import { useServiceTypes } from 'services/service-types';

import { SimpleSelectorField } from './simple-selector-field';

type Props = Omit<React.ComponentProps<typeof SimpleSelectorField>, 'options'>;

export function ServiceTypeSelectorField(props: Props) {
  const serviceTypes = useServiceTypes();

  const options = serviceTypes.map(service => ({
    label: service.title,
    value: service.id,
  }));

  return <SimpleSelectorField {...props} options={options} />;
}
