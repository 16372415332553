import styled from 'styled-components';

import { InputWrapper, focusCss } from '../../forms/atoms/input-wrapper';

// @ts-ignore

export const Selector = styled(InputWrapper)<{ isOpened: boolean }>`
  position: relative;
  padding-right: 30px;
  :before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    right: 16px;

    width: 7px;
    height: 7px;
    border-radius: 0 0 2px 0;
    border-width: 0 2px 2px 0;
    border-style: solid;
    color: var(--arrowColor, #a1b3ce);
  }

  ${props => props.isOpened && focusCss}
`;
