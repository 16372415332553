import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import styled, { createGlobalStyle } from 'styled-components';

// @ts-ignore
import { ReactComponent as CloseIcon } from 'ui/atoms/icons/close.svg';

import { AdaptiveProvider, adaptive } from 'ui/styles/addaptive-provider';

import { Overlay } from '../../atoms/overlay/overlay';
import { ModalFooter } from '../../atoms/modal-footer';
import { Modal } from '../../atoms/modal';
import { ModalContent } from '../../atoms/modal-content';

interface Props {
  isOpened: boolean;
  title?: string;
  renderContent(): React.ReactNode;
  onClose?: () => void;
  className?: string;
}

export function ModalWindowTemplate(props: Props) {
  if (!props.isOpened) return null;

  return createPortal(
    <AdaptiveProvider mobile={480}>
      <ModalOverlay>
        {props.isOpened && <LockRootScroll />}
        <ModalWindowTemplateRoot className={props.className}>
          <ModalContent>
            {props.onClose && <Close role="button" onClick={props.onClose} />}
            {props.title && <Title>{props.title}</Title>}
          </ModalContent>
          <Content>{props.renderContent()}</Content>
        </ModalWindowTemplateRoot>
      </ModalOverlay>
    </AdaptiveProvider>,
    document.getElementById('modals'),
  );
}

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Close = styled(CloseIcon)`
  width: 14px;
  height: 14px;
  cursor: pointer;

  :hover {
    .icon {
      fill: #3c5bcc;
    }
  }
`;

export const Title = styled.div`
  line-height: 28px;
  font-weight: 500;
  font-size: 20px;
  color: #172549;
`;

const ModalWindowTemplateRoot = styled(Modal)`
  display: flex;
  flex-direction: column;

  ${Close} {
    position: absolute;
    top: 29px;
    right: 37px;
  }

  ${Title} {
    margin-bottom: 26px;
  }

  ${ModalFooter} {
    margin-top: 20px;
  }

  ${adaptive} {
    border-radius: 0;
    min-height: calc(100vh - var(--headerHeight));
    ${Close} {
      right: 15px;
    }

    ${Title} {
      margin-bottom: 16px;
    }
  }
`;

const ModalOverlay = styled(Overlay)`
  display: flex;
  justify-content: center;
  align-items: flex-start;

  ${ModalWindowTemplateRoot} {
    margin: 51px 0;

    ${adaptive} {
      margin-bottom: 0;
    }
  }
`;

const LockRootScroll = React.memo(() => {
  const [scrollPosition] = useState(document.scrollingElement?.scrollTop ?? 0);

  useEffect(() => () => {
    setTimeout(() => {
      document.scrollingElement.scrollTop = scrollPosition;
    });
  });

  const LockStyles = createGlobalStyle`
    #root {
      position: fixed;
      width: 100vw;
      transform: translateY(-${scrollPosition}px);
    };
  `;

  return scrollPosition !== null ? <LockStyles /> : null;
});
