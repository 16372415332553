import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import footerData from '../../staticSiteData/mainPage/footer';
import logoImg from '../../staticSiteData/mainPage/images/footer-logo.svg';
import s from './Footer.module.css';
import DropDown from './DropDown';
import { requestRepository } from '../../../repository';

export default function Footer(props) {
  const { isHide } = props;
  const [socialLinks, setSocialLinks] = useState({
    facebook: '',
    twitter: '',
    instagram: '',
    youtube: '',
  });

  useEffect(() => {
    requestRepository.getSocialLinks().then(res => {
      setSocialLinks(res);
    });
  }, []);

  return (
    !isHide && (
      <div className={s.Footer}>
        <div className={s.Content}>
          <div className={s.MainGroup}>
            <div className={s.NavColumns}>
              {footerData.en.columns.map((column, index) => (
                <div key={index}>
                  <div className={s.Column}>
                    <div className={s.ColumnTitle}>{column.title}</div>
                    {column.links.map((link, index) => (
                      <Link className={s.ColumnLink} to={link.path} key={index}>
                        {link.text}
                      </Link>
                    ))}
                  </div>
                  <DropDown title={column.title} className={s.dropdown}>
                    {column.links.map((link, index) => (
                      <Link className={s.ColumnLink} to={link.path} key={index}>
                        {link.text}
                      </Link>
                    ))}
                  </DropDown>
                </div>
              ))}
            </div>
            <div className={s.Contacts}>
              <div className={s.SocialLinks}>
                {footerData.en.contacts.socialLinks.map((link, index) => (
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    key={index}
                    href={socialLinks[link.name]}
                  >
                    <img src={link.logo} alt="logo" />
                  </a>
                ))}
              </div>
              <div className={s.Emails}>
                {footerData.en.contacts.emails.map((email, index) => (
                  <div key={index} className={s.Email}>
                    <div className={s.EmailTitle} key={index}>
                      {email.title}
                    </div>
                    {email.links.map((link, index) => (
                      <a
                        className={s.EmailLink}
                        href={`mailto:${link.path}`}
                        key={index}
                      >
                        {link.text}
                      </a>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={s.Copyright}>
            <img className={s.Logo} src={logoImg} alt="logo"></img>
            <div className={s.CopyrightText}>{footerData.en.copyright}</div>
          </div>
        </div>
      </div>
    )
  );
}

Footer.propTypes = {};
