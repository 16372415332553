import React from 'react';
import { Link } from 'react-router-dom';

import { Title, ConfirmPageTemplate } from 'ui/templates/cohfirm-page-template';

export function ConfirmNewPasswordSuccessPage() {
  return (
    <ConfirmPageTemplate>
      <Title>Your password has been changed successfully</Title>
      <Link to="/login">← Back to login</Link>
    </ConfirmPageTemplate>
  );
}
