import { privateApi, publicApi } from '../../api';

import {
  CurrentServiceProviderProfile,
  ServiceProviderProfileApiResponse,
} from './types';

export async function auth(authData: {
  email: string;
  password: string;
}): Promise<string> {
  const {
    data: { token },
  } = await publicApi.post('/service-owner/auth', authData);
  return token;
}

export async function getProfile() {
  const { data } = await privateApi.get<ServiceProviderProfileApiResponse>(
    '/common/user/self-profile',
  );
  return parseServiceProvideProfile(data);
}

export async function updateProfile(
  profile: ServiceProviderProfileApiResponse,
): Promise<CurrentServiceProviderProfile> {
  const { data } = await privateApi.put(
    '/service-owner/user/public-profile',
    profile,
  );
  return parseServiceProvideProfile(data);
}

export async function updateAccountSettings(
  profile: ServiceProviderProfileApiResponse,
): Promise<CurrentServiceProviderProfile> {
  const { data } = await privateApi.put(
    '/service-owner/user/account-settings',
    profile,
  );
  return parseServiceProvideProfile(data);
}

export function parseServiceProvideProfile(
  apiData: ServiceProviderProfileApiResponse,
): CurrentServiceProviderProfile {
  return {
    id: apiData.id,
    firstName: apiData.first_name,
    lastName: apiData.last_name,
    companyName: apiData.company_name,
    email: apiData.email,
    website: apiData.web_site,
    serviceTypeId: apiData.service_types?.[0]?.id,
    phoneNumber: apiData.phone_numbers?.[0],
    privatePhoneNumber: apiData.phone_number,
    city: apiData.city,
    state: apiData.state,
    zipCode: apiData.zip_code,
    companyAddress: apiData.company_address,
    companyAddress2: apiData.company_address2,
    avatar: apiData.avatar,
    coverImage: apiData.hero_image,
    isFavorite: apiData.is_favorite,
    about: apiData.overview,
    rating: apiData.rating,
    hasPremium: apiData.is_active_subscription,
    latitude: apiData.latitude,
    longitude: apiData.longitude,
    isOpen: apiData?.is_open,
    openTill: apiData?.open_till,
    closedTill: apiData?.closed_till,
  };
}
