import styled from 'styled-components';

import { adaptive } from 'ui/styles/addaptive-provider';

export const ModalContent = styled.div`
  padding: 0 32px;

  ${adaptive} {
    padding: 0 15px;
  }
`;
