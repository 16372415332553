import React from 'react';

import { ModalWindowTemplate } from 'ui/ecosystems/modals';
import { ModalTabs, Tab } from 'ui/ecosystems/navigation/modals-tabs';

import { ProfileForm } from '../profile-form/profile-form';
import { PasswordChangeForm } from '../change-password-form';

interface Props {
  isOpened: boolean;
  onClose(): void;
}

export function PetOwnerEditProfileModal(props: Props) {
  return (
    <ModalWindowTemplate
      isOpened={props.isOpened}
      onClose={props.onClose}
      title="Edit Profile"
      renderContent={() => (
        <React.Fragment>
          <ModalTabs>
            <Tab id="profile" renderContent={() => <ProfileForm />}>
              Public Profile
            </Tab>
            <Tab id="Password" renderContent={() => <PasswordChangeForm />}>
              Password
            </Tab>
          </ModalTabs>
        </React.Fragment>
      )}
    />
  );
}
