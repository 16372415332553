import React, { useEffect, useState } from 'react';

import { OpenableState } from 'libs/use-openable-state';

import { Button } from 'ui';
import styled, { css } from 'styled-components';
import { ModalWindowTemplate, Title } from 'ui/ecosystems/modals/templates';
import { ModalFooter } from 'ui/ecosystems/modals';

// @ts-ignore
import { SubmitButton } from 'ui/ecosystems/forms/atoms/submit-form-button';

import { PetAvatar } from 'ui/ecosystems/users';

import { ReactComponent as DocIcon } from './document.svg';
import { usePetsDocuments } from '../../documents.model';

type FormProps = {
  onConfirm(attachments: number[]): Promise<void>;
};

export function ChooseDocumentsModal(props: OpenableState & FormProps) {
  const { petsDocuments, getPetsDocuments } = usePetsDocuments();
  const [selectedList, setSelectedList] = useState([]);

  useEffect(() => {
    getPetsDocuments();
  }, []);

  function changeSelectedList(documentId: number) {
    selectedList.includes(documentId)
      ? setSelectedList(selectedList.filter(item => item != documentId))
      : setSelectedList(selectedList.concat(documentId));
  }
  async function handleConfirm(value: number[]) {
    await props.onConfirm(value);
    props.close();
  }
  return (
    <Modal
      isOpened={props.isOpened}
      onClose={props.close}
      renderContent={() => (
        <>
          <ModalTitle>Choose documents you want to share</ModalTitle>
          <Content>
            <PetsList>
              {petsDocuments &&
                petsDocuments
                  .filter(pet => pet.documents.length > 0)
                  .map(pet => (
                    <PetItem key={pet.id}>
                      <PetHeader>
                        <PetAvatar avatar={pet.avatar?.url} />
                        <PetName>{pet.name}</PetName>
                      </PetHeader>
                      <DocumentsList>
                        {pet.documents.map(doc => (
                          <DocumentItem
                            key={doc.id}
                            documentName={doc.original_name}
                            selected={selectedList.includes(doc.id)}
                            onClick={() => changeSelectedList(doc.id)}
                          />
                        ))}
                      </DocumentsList>
                    </PetItem>
                  ))}
            </PetsList>
          </Content>
          <Footer>
            <ConfirmButton onClick={() => handleConfirm(selectedList)}>
              Confirm
            </ConfirmButton>
            <CancelButton onClick={props.close}>Cancel</CancelButton>
          </Footer>
        </>
      )}
    />
  );
}

function DocumentItem(props) {
  const [documentName] = props.documentName?.split(/(?:\.([^.]+))?$/);
  return (
    <Document onClick={props.onClick}>
      <DocumentIcon />
      <Selected isSelect={props.selected} />
      <DocumentName>{documentName}</DocumentName>
    </Document>
  );
}

const DocumentName = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 14px;
  font-weight: bold;
  color: #384d9e;
  overflow: hidden;
  background: rgba(0, 0, 0, 0);
  border: none;
  height: 45px;
`;

const ModalTitle = styled(Title)`
  padding: 20px 32px 24px;
  border-bottom: 1px solid #dfe8f6;
`;

const Content = styled.div`
  min-height: 350px;
  padding: 8px 32px 32px;
`;

const ConfirmButton = styled(SubmitButton)``;

const CancelButton = styled(Button).attrs({
  outline: true,
})``;

const Footer = styled(ModalFooter)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 72px;
  padding: 20px 40px;
`;

const Modal = styled(ModalWindowTemplate)`
  padding: 0;
  width: 560px;
  max-width: 100%;

  ${ModalTitle} {
    margin-bottom: 0;
  }
`;

const DocumentIcon = styled(DocIcon)`
  width: 40px;
  height: 40px;
  flex-shrink: 0;
`;

const Selected = styled.div<{ isSelect: boolean }>`
  width: 10px;
  height: 10px;
  border: 1px solid #384d9e;
  border-radius: 100%;
  ${props =>
    props.isSelect
      ? css`
          background: #6d7389;
        `
      : css`
          background: rgba(0, 0, 0, 0);
        `};
`;

const Document = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px 24px 12px 12px;
  max-width: 100%;
  border-radius: 8px;
  background: rgba(196, 196, 196, 0.25);
  ${DocumentIcon} {
    margin-right: 12px;
  }
  ${Selected} {
    position: absolute;
    top: 4px;
    right: 5px;
  }
`;

const PetName = styled.div`
  font-size: 12px;
  line-height: 14px;
  font-weight: bold;
`;

const PetHeader = styled.div`
  display: flex;
  align-items: center;
  ${PetAvatar} {
    width: 30px;
    height: 30px;
    margin-right: 6px;
  }
`;

const PetItem = styled.div`
  display: flex;
  flex-direction: column;
  ${PetHeader} {
    margin-bottom: 8px;
  }
`;

const DocumentsList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 16px;
`;

const PetsList = styled.div`
  display: flex;
  flex-direction: column;
  ${PetItem} {
    margin-bottom: 14px;
  }
`;
