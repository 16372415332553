import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isBetween from 'dayjs/plugin/isBetween';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(advancedFormat);
dayjs.extend(localeData);
dayjs.extend(localizedFormat);
dayjs.extend(weekOfYear);
dayjs.extend(isBetween);
dayjs.extend(relativeTime);

dayjs.locale('en');

export { dayjs };

export function removeTimeZone(date?: string): string {
  if (date) {
    return date.split('T')[0];
  }
  return '';
}
