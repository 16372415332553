import React from 'react';
import { Redirect } from 'react-router';
import { RouteConfig } from 'react-router-config';

export function onlyAuth(route: RouteConfig, { user }) {
  if (user?.profile?.id) return route;
  return {
    ...route,
    component: () => <Redirect to="/login" />,
  };
}
