import styled from 'styled-components';
import React from 'react';
import { Link } from 'react-router-dom';

import { ContentBlock } from 'ui/atoms/content-block';
import { adaptive } from 'ui/styles/addaptive-provider';
import { Rating } from 'ui/molecules/rating';
import { ServiseProvider } from 'types';
import { Icon } from 'ui/atoms/icon';
import { dayjs } from 'libs/dayjs-ext/dayjs-ext';

// @ts-ignore
import locationIcon from './location.svg';
// @ts-ignore
import proIcon from './pro.svg';
import { FavoriteIcon } from '../../atoms/favorite-icon';

export function ServiceProviderCard(
  props: ServiseProvider & {
    className?: string;
  },
) {
  return (
    <Root
      as={Link}
      to={`/service-provider/${props.id}`}
      className={props.className}
    >
      <Block>
        <Row>
          <CompanyName>{props.company_name}</CompanyName>
          <FavoriteIcon isFavorite={props.is_favorite} />
        </Row>
        {props.distance > 0 && (
          <Location>
            <LocationIcon />
            <Distance>{props.distance.toFixed(2)} miles away</Distance>
          </Location>
        )}
      </Block>
      <Row>
        {props.open_till ? (
          <OpenTill isOpen={props.is_open}>
            Open till{' '}
            {dayjs(props.open_till, 'YYYY-MM-DD HH:mm:ss').format('hh:mm a')}
          </OpenTill>
        ) : props.closed_till ? (
          <OpenTill isOpen={props.is_open}>
            Closed till{' '}
            {dayjs(props.closed_till, 'YYYY-MM-DD HH:mm:ss').format('hh:mm a')}
          </OpenTill>
        ) : (
          <div />
        )}
        <RatingBlock>
          {props.is_active_subscription && (
            <React.Fragment>
              <ProIcon />
              <IsPro>Pro</IsPro>
            </React.Fragment>
          )}
          <Rating rating={props.rating} size={12} />
        </RatingBlock>
      </Row>
    </Root>
  );
}

const CompanyName = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 1.33;

  ${adaptive} {
    font-size: 16px;
    line-height: 1.5;
  }
`;

const LocationIcon = styled(Icon).attrs({
  icon: locationIcon,
})`
  ${Icon.imageSelector} {
    width: 8px;
    height: 12px;
  }
`;

const Distance = styled.div`
  font-size: 14px;
  line-height: 1.5;
  color: var(--grayDark);
  align-self: stretch;
  ${adaptive} {
    font-size: 12px;
  }
`;

const Location = styled.div`
  display: flex;
  ${Distance} {
    margin-left: 4px;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Block = styled.div`
  ${Row} {
    margin-bottom: 10px;
  }
`;

const ProIcon = styled(Icon).attrs({
  icon: proIcon,
})`
  ${Icon.imageSelector} {
    width: 11px;
    height: 12px;
  }
`;

const IsPro = styled.div`
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
  color: #ffc01f;
  position: relative;
  text-decoration: none;
`;

const RatingBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  ${IsPro} {
    padding-left: 4px;
    margin-right: 8px;
  }
`;

const OpenTill = styled.div<{ isOpen: boolean }>`
  font-size: 14px;
  line-height: 1.5;
  position: relative;
  margin-left: 12px;
   :before {
    content: '';
    position: absolute;
    left: -12px;
    top: 6px; 
    width: 8px;
    height: 8px;
    background-color: ${props => (props.isOpen ? '#22c663' : '#EB5757')};
    margin-right: 4px;
    border-radius: 50%;

  
${adaptive} {
    font-size: 12px;
  }
`;

const Root = styled(ContentBlock)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  padding: 24px;
  height: 184px;

  ${adaptive} {
    padding: 16px 16px 19px;
    height: 128px;
  }

  :hover {
    text-decoration: none;
  }
`;
