import styled from 'styled-components';

import { ReactComponent as Heart } from '../icons/like.svg';

export const FavoriteIcon = styled(Heart)<{ isFavorite: boolean }>`
  width: 24px;
  height: 24px;
  cursor: pointer;
  path {
    stroke: ${props => (props.isFavorite ? 'red' : '#B9CBDF')};
    fill: ${props => (props.isFavorite ? 'red' : '')};
  }
`;
