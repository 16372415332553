import React from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { Field } from 'react-final-form';
import styled from 'styled-components';
import cn from 'classnames';

import { Form, useCreateForm } from 'libs/forms';
import { useCurrentProfile } from 'features/profile';
import headerData from 'ui/staticSiteData/mainPage/header/headerData';

import { ReactComponent as LogoIcon } from './logo.svg';
import { ReactComponent as SearchIcon } from './search-icon.svg';
import { ReactComponent as BurgerIcon } from './burger-icon.svg';
import { ReactComponent as CloseIcon } from './close-icon.svg';
import { ReactComponent as SearchSmallIcon } from './search-small-icon.svg';

import s from './Header.module.css';

type Props = {
  className: string;
  autoFocus?: boolean;
  callback?(): void;
  isMobile?: boolean;
};

function SearchField(props: Props) {
  const history = useHistory();
  function onSubmit(values: { searchString: string }) {
    props.callback && props.callback();
    const queryString = values.searchString?.trim();
    if (queryString) {
      history.push(`/search?search=${queryString}`);
    }
  }

  const form = useCreateForm({
    onSubmit: onSubmit,
  });
  const { className, autoFocus } = props;
  return (
    <Form
      form={form}
      render={({ handleSubmit }) => (
        <React.Fragment>
          <div className={s.openSearch}>
            <div className={s.searchInput}>
              <form onSubmit={handleSubmit}>
                <Field name="searchString">
                  {props => {
                    return (
                      <div>
                        <input
                          {...props.input}
                          autoFocus={autoFocus}
                          className={className}
                          type="text"
                          placeholder="Search Services, Parks, Events..."
                        />
                      </div>
                    );
                  }}
                </Field>
              </form>
            </div>
            {!props.isMobile && <ActiveSearchIcon onClick={handleSubmit} />}
          </div>
          {props.isMobile && (
            <SearchSmallIcon className={s.searchIcon} onClick={handleSubmit} />
          )}
        </React.Fragment>
      )}
    />
  );
}

const ActiveSearchIcon = styled(SearchIcon)`
  cursor: pointer;
`;

class Header extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      inScroll: false,
      isMobile: false,
      showSearch: false,
      siteMenuOpen: false,
      userMenuOpen: false,
      openMenuId: null,
    };

    this.handleScroll.bind(this);

    this.mobileMediaQuery = '(max-width: 991px)';
  }
  componentDidMount = () => {
    if (window.matchMedia(this.mobileMediaQuery).matches) {
      this.setState({ isMobile: true });
    }
    window.addEventListener('scroll', this.handleScroll.bind(this));
    window.addEventListener('resize', this.handleResize.bind(this));
  };

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.handleScroll.bind(this));
    window.removeEventListener('resize', this.handleResize.bind(this));
  };

  handleScroll = () => {
    this.setState({ isScroll: window.scrollY > 50 });
  };

  handleResize = () => {
    this.setState({
      isMobile: !!window.matchMedia(this.mobileMediaQuery).matches,
    });
    if (!window.matchMedia(this.mobileMediaQuery).matches) {
      this.setState({ siteMenuOpen: false });
    }
  };

  handleToggleSearch = () =>
    this.setState(prevState => ({ showSearch: !prevState.showSearch }));

  handleToggleOpenMenu = () =>
    this.setState(prevState => ({ siteMenuOpen: !prevState.siteMenuOpen }));

  handleToggleShowMobileSubMenu = e => {
    const menuId = Number(e.target.dataset.id);
    this.setState(prevState => ({
      openMenuId: prevState.openMenuId === menuId ? null : menuId,
    }));
  };

  handelCloseMobileMenu = () =>
    this.setState({ siteMenuOpen: false, openMenuId: null });

  render() {
    const {
      isScroll,
      showSearch,
      isMobile,
      siteMenuOpen,
      openMenuId,
    } = this.state;
    const { isHide } = this.props;
    return (
      !isHide && (
        <>
          <div
            className={cn(
              s.headerWrapper,
              { [s.active]: isScroll || isMobile || this.props.fixed },
              { [s.siteMenuOpen]: siteMenuOpen },
              { [s.fixed]: this.props.fixed },
            )}
          >
            <div className={s.header}>
              <div className={s.menuButton} onClick={this.handleToggleOpenMenu}>
                {siteMenuOpen ? <CloseIcon /> : <BurgerIcon />}
              </div>
              <Link to="/" className={s.logo}>
                <LogoIcon />
              </Link>
              <div className={cn(s.menu, { [s.disable]: showSearch })}>
                {headerData.en.map(menuItem => (
                  <div className={s.menuItem} key={menuItem.headerTitle}>
                    <div className={s.menuItemTitle}>
                      {menuItem.headerTitle} <div className={s.ddArrow} />
                    </div>
                    <ul className={s.subMenuWrapper}>
                      {menuItem.subMenu.map(subMenuItem => (
                        <li key={subMenuItem.text}>
                          {subMenuItem.href ? (
                            <a
                              href={subMenuItem.href}
                              className={s.subMenuLink}
                            >
                              {subMenuItem.text}
                            </a>
                          ) : (
                            <Link
                              to={subMenuItem.link}
                              className={s.subMenuLink}
                            >
                              {subMenuItem.text}
                            </Link>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>

              <div
                className={cn(s.searchWrapper, {
                  [s.showSearch]: showSearch,
                })}
              >
                {showSearch && (
                  <SearchField
                    className={s.searchInput}
                    autoFocus={true}
                    callback={() => {
                      this.handleToggleSearch();
                    }}
                  />
                )}
                <SearchIcon
                  onClick={this.handleToggleSearch}
                  className={s.searchIcon}
                />
              </div>

              {showSearch ? (
                <div
                  onClick={this.handleToggleSearch}
                  className={s.signInButton}
                >
                  Close
                </div>
              ) : (
                <ProfileButton />
              )}
            </div>
          </div>
          <div
            className={cn(s.mobileSiteMenu, { [s.siteMenuOpen]: siteMenuOpen })}
          >
            <div className={s.searchWrapper}>
              <SearchField className={s.searchInput} isMobile={true} />
            </div>
            {headerData.en.map((menuItem, i) => (
              <div
                className={cn(s.mMenuItem, {
                  [s.activeMenu]: i === openMenuId,
                })}
                key={menuItem.headerTitle}
              >
                <div
                  className={cn(s.mMenuTitle)}
                  data-id={i}
                  onClick={e => this.handleToggleShowMobileSubMenu(e)}
                >
                  {menuItem.headerTitle}
                  <div className={s.ddArrow} />
                </div>
                <ul
                  className={s.subMMenuWrapper}
                  data-item-count={menuItem.subMenu.length}
                >
                  {menuItem.subMenu.map(subMenuItem => (
                    <li key={subMenuItem.text}>
                      {subMenuItem.href ? (
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={subMenuItem.href}
                          className={s.subMMenuLink}
                        >
                          {subMenuItem.text}
                        </a>
                      ) : (
                        <Link
                          onClick={this.handelCloseMobileMenu}
                          to={subMenuItem.link}
                          className={s.subMMenuLink}
                        >
                          {subMenuItem.text}
                        </Link>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </>
      )
    );
  }
}

export default Header;

function ProfileButton() {
  const { isPetOwner, isServiceProvider } = useCurrentProfile();

  if (isPetOwner) {
    return (
      <Link to={'/pet-owner-profile'} className={s.signInButton}>
        <div>Profile</div>
      </Link>
    );
  }

  if (isServiceProvider) {
    return (
      <Link to={'/service-provider-profile'} className={s.signInButton}>
        <div>Profile</div>
      </Link>
    );
  }

  return (
    <Link to={'/login'} className={s.signInButton}>
      <div>Sign In</div>
    </Link>
  );
}
