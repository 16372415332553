import styled, { css } from 'styled-components';

// @ts-ignore
import { ReactComponent as StarIcon } from './star.svg';

const fillStyle = css`
  opacity: 0.2;
  path {
    fill: #172549;
  }
`;

export const RatingStar = styled(StarIcon)<{ fill: boolean }>`
  width: 32px;
  height: 32px;
  ${props => props.fill || fillStyle};
`;

RatingStar.fillStyle = fillStyle;
