import React from 'react';
import styled, { css } from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { Switch, Route } from 'react-router';
import { NavLink } from 'react-router-dom';

import { InternalPageTemplate } from 'ui/templates';
import { AdaptiveProvider, adaptive } from 'ui/styles/addaptive-provider';

import { useDialogs } from '../../dialogs-list.model';

const breakPoint = 768;

export function DialogsTemplate(props: {
  dialogsIds: string[];
  renderDialogPreview(id: number): React.ReactNode;
  renderDialog(id: number): React.ReactNode;
}) {
  useDialogs();

  const isMobile = useMediaQuery({ maxWidth: breakPoint });

  if (props.dialogsIds.length === 0) {
    return null;
  }

  return (
    <AdaptiveProvider mobile={breakPoint}>
      {isMobile ? (
        <InternalPageTemplate noFooter isFull>
          <Switch>
            <Route
              exact
              path="/messages"
              render={() => (
                <Dialogs>
                  {props.dialogsIds.map(id => (
                    <DialogsItem key={id} as={NavLink} to={`/messages/${id}`}>
                      {props.renderDialogPreview(id)}
                    </DialogsItem>
                  ))}
                </Dialogs>
              )}
            />
            <Route
              path="/messages/:dialogId"
              render={({ match }) => (
                <MobileDialog>
                  {props.renderDialog(match.params.dialogId)}
                </MobileDialog>
              )}
            />
          </Switch>
        </InternalPageTemplate>
      ) : (
        <InternalPageTemplate>
          <Grid>
            <Dialogs>
              {props.dialogsIds.map(id => (
                <DialogsItem key={id} as={NavLink} to={`/messages/${id}`}>
                  {props.renderDialogPreview(id)}
                </DialogsItem>
              ))}
            </Dialogs>
            <OpenedDialog>
              <Switch>
                <Route
                  path="/messages/:dialogId"
                  render={({ match }) =>
                    props.renderDialog(match.params.dialogId)
                  }
                />
                <Route
                  path="*"
                  render={() => <NoSelected>select conversation </NoSelected>}
                />
              </Switch>
            </OpenedDialog>
          </Grid>
        </InternalPageTemplate>
      )}
    </AdaptiveProvider>
  );
}

const DialogsItemActiveStyles = css`
  background-color: #fff;
  border-right-color: transparent;
`;

const DialogsItem = styled.div`
  cursor: pointer;
  display: block;
  border: solid #dfe8f6;
  border-width: 1px 0 0;
  :hover {
    ${DialogsItemActiveStyles};
    text-decoration: none;
  }

  &.active {
    ${DialogsItemActiveStyles}
  }

  ${adaptive} {
    border-width: 1px 0 0;
  }
`;

const Dialogs = styled(({ children, ...props }) => (
  <div {...props}>
    <div>{children}</div>
  </div>
))`
  display: grid;
  background: #f7f8ff;
  border-right: 1px solid #dfe8f6;

  ${adaptive} {
    background: #fff;
    border: none;
  }
`;

const NoSelected = styled.div`
  ${adaptive} {
    display: none;
  }
`;

const OpenedDialog = styled.div`
  height: 100%;
  overflow: hidden;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 300px 1fr;

  border-radius: 8px;
  overflow: hidden;
  background: #fff;

  height: calc(100vh - var(--headerHeight) - 60px);

  ${adaptive} {
    display: block;

    ${Dialogs} {
      width: 100vw;
    }
  }
`;

const MobileDialog = styled.div`
  height: calc(var(--appHeight) - var(--headerHeight));
`;
