import { RouteConfig } from 'react-router-config';

type Guard<T extends {} = {}> = (
  route: RouteConfigWithGuards,
  context: T,
) => RouteConfig;

interface RouteConfigWithGuards extends RouteConfig {
  guards?: Guard[];
}

export function compileRoutes(
  routes: RouteConfigWithGuards[],
  context = {},
): RouteConfig[] {
  return routes
    .map(route => {
      return route.guards
        ? route.guards.reduce(
            (currentRoute, guard) =>
              currentRoute.stop ? currentRoute : guard(currentRoute, context),
            route,
          )
        : route;
    })
    .filter(Boolean)
    .map(({ guards, stop, ...route }) => ({
      ...route,
      routes: route.routes
        ? compileRoutes(route.routes, context)
        : route.routes,
    }));
}
