import styled from 'styled-components';
import ReactCalendar, { Detail } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import React from 'react';
import { dayjs } from 'libs/dayjs-ext';

interface Props {
  value?: any;
  onChange?: any;
  view?: Detail;
  defaultView?: Detail;
  maxDate?: Date;
  minDate?: Date;
  defaultActiveStartDate?: Date;
  className?: string;
}

const calendarType = 'US';
const locale = 'en-EN';
const formatShortWeekday = (
  _locale: any,
  date: { getDay: () => React.ReactText },
) => [`Su`, `Mo`, `Tu`, `We`, `Th`, `Fr`, `Sa`][date.getDay()];

const formatMonth = (_locale: any, date: any) =>
  [
    `Jan`,
    `Feb`,
    `Mar`,
    `Apr`,
    `May`,
    `Jun`,
    `Jul`,
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ][date.getMonth()];

const formatMonthYear = (_locale: any, date: Date) =>
  dayjs(date).format('MMM YYYY');

export function Calendar(props: Props) {
  const defaultActiveStartDate =
    (props.maxDate && dayjs(props.maxDate).date(1).toDate()) ||
    (props.value && dayjs(props.value).date(1).toDate()) ||
    dayjs().date(1).toDate();
  return (
    <ReactCalendarStyled
      value={props.value}
      view={props.view}
      defaultView={props.defaultView}
      maxDate={props.maxDate}
      minDate={props.minDate}
      defaultActiveStartDate={defaultActiveStartDate}
      onChange={props.onChange}
      calendarType={calendarType}
      locale={locale}
      className={props.className}
      formatShortWeekday={formatShortWeekday}
      formatMonthYear={formatMonthYear}
      formatMonth={formatMonth}
    />
  );
}

const ReactCalendarStyled = styled(ReactCalendar)`
  &.react-calendar {
    width: 100%;
    max-width: 360px;
    min-width: 290px;
    overflow: hidden;
    transition: background 0.3s;
    border: none;
    box-shadow: 0 4px 24px rgba(104, 130, 159, 0.24);
    border-radius: 8px;
    font-family: 'Ubuntu', sans-serif;
    font-size: 14px;
    .react-calendar__navigation__arrow {
      color: var(--grayMiddle);
    }
    .react-calendar__navigation {
      height: 48px;
      border-bottom: 1px solid var(--grayLight);
    }
    .react-calendar__navigation__label {
      font-weight: 500;
    }
    .react-calendar__month-view__weekdays__weekday {
      text-decoration: none;
      font-weight: normal;
    }
    .react-calendar__tile {
      font-size: 14px;
      line-height: 14px;
      color: #5b6784;
      word-break: keep-all;
    }
    .react-calendar__month-view__days__day--weekend {
      color: #5b6784;
    }
    .react-calendar__month-view__weekdays__weekday abbr[title] {
      font-size: 14px;
      line-height: 21px;
      text-decoration: none;
      text-transform: none;
    }

    .react-calendar__viewContainer {
      padding: 0 24px 24px;
    }
    .react-calendar button:enabled:hover {
      border-radius: 2px;
      background: #dfe8f6;
      color: #000;
    }

    .react-calendar__tile--active,
    .react-calendar__tile--hasActive {
      background: var(--darkBlue) !important;
      color: var(--white) !important;
      border-radius: 2px !important;
    }
    .react-calendar__tile--now {
      background: var(--grayLight);
      border-radius: 2px;
    }

    .react-calendar__year-view {
      padding: 10px 0 8px;
    }

    .react-calendar__year-view__months {
      display: grid !important;
      grid-template-columns: repeat(3, auto);
      grid-gap: 22px 18px;
    }
    .react-calendar__year-view__months__month {
      padding: 3.5px;
      max-width: 100% !important;
    }

    .react-calendar__decade-view {
      padding: 10px 0 8px;
    }

    .react-calendar__decade-view__years {
      display: grid !important;
      grid-template-columns: repeat(3, auto);
      grid-gap: 22px 20px;
      justify-content: space-around;
    }
    .react-calendar__decade-view__years__year {
      padding: 3px 10px;
      max-width: 50px !important;
    }
  }
`;
