import React from 'react';

import {
  FormModalContentTemplate,
  InputField,
  CoverImageField,
  ImageField,
  FormLine,
  FormFields,
  SubmitFormButton,
  BirthdayField,
  TwoFields,
} from 'ui/ecosystems/forms';
import { Form, useCreateForm } from 'libs/forms';
import { POAvatar } from 'ui/ecosystems/users';
import { useCurrentPetOwnerProfile } from 'features/pet-owner-profile';

import { formatPetOwner } from '../../../current-pet-owner-profile.model';

export function ProfileForm() {
  const { profile, updateProfile } = useCurrentPetOwnerProfile();

  async function handleFormSubmit(values: unknown) {
    const profile = formatPetOwner(values);
    await updateProfile(profile);
  }

  const form = useCreateForm(
    {
      initialValues: profile,
      onSubmit: handleFormSubmit,
    },
    [profile],
  );

  return (
    <Form
      form={form}
      render={({ handleSubmit, submitSucceeded, dirtySinceLastSubmit }) => (
        <FormModalContentTemplate
          renderContent={() => (
            <FormFields>
              <ImageField name="avatar" ImageComponent={POAvatar} />
              <CoverImageField name="coverImage" />
              <TwoFields>
                <FormLine label="First Name">
                  <InputField name="firstName" placeholder="First Name" />
                </FormLine>
                <FormLine label="Last Name">
                  <InputField name="lastName" placeholder="Last Name" />
                </FormLine>
              </TwoFields>
              <FormLine label="Email">
                <InputField name="email" placeholder="Email" />
              </FormLine>
              <TwoFields>
                <FormLine label="Phone Number">
                  <InputField
                    type="tel"
                    name="phoneNumber"
                    placeholder="Phone Number"
                  />
                </FormLine>
                <FormLine label="ZIP">
                  <InputField name="zipCode" placeholder="ZIP" />
                </FormLine>
              </TwoFields>
              <FormLine label="Birthday">
                <BirthdayField
                  name="birthday"
                  placeholder="Select Date"
                  minAge={18}
                />
              </FormLine>
            </FormFields>
          )}
          renderActions={() => (
            <SubmitFormButton
              onClick={handleSubmit}
              disabled={submitSucceeded && !dirtySinceLastSubmit}
            >
              {submitSucceeded && !dirtySinceLastSubmit
                ? 'Saved'
                : 'Save Changes'}
            </SubmitFormButton>
          )}
        />
      )}
    />
  );
}
