import React, { useState } from 'react';
import { Autocomplete, useLoadScript } from '@react-google-maps/api';

import { Input } from '../../atoms/input/input';

export type AddressDetails = {
  street_number: string;
  route: string;
  city: string;
  state: string;
  zip_code: string;
  latitude: number;
  longitude: number;
};

type Props = {
  placeholder?: string;
  onChange(value: AddressDetails): void;
};

export function PlaceAutocompleteInput(props: Props) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyCjNxIoWPWSt1XR83PzTQGB1iUvL38VKE0',
    libraries: ['places'],
  });
  const [autocomplete, setAutocomplete] = useState(null);
  const fields = ['name', 'address_component', 'geometry'];

  function onLoad(autocomplete) {
    setAutocomplete(autocomplete);
  }

  function onPlaceChanged() {
    if (autocomplete !== null) {
      const {
        address_components,
        geometry: {
          location: { lat, lng },
        },
      } = autocomplete.getPlace();
      const details: AddressDetails = {
        street_number:
          address_components.find(item => item.types.includes('street_number'))
            ?.long_name || '',
        route:
          address_components.find(item => item.types.includes('route'))
            ?.long_name || '',

        city:
          address_components.find(item => item.types.includes('locality'))
            ?.long_name || '',
        state:
          address_components.find(item =>
            item.types.includes('administrative_area_level_1'),
          )?.long_name || '',
        zip_code:
          address_components.find(item => item.types.includes('postal_code'))
            ?.long_name || '',
        latitude: lat(),
        longitude: lng(),
      };
      props.onChange(details);
    } else {
      console.log('Autocomplete is not loaded yet!');
    }
  }

  if (!isLoaded) return null;

  return (
    <Autocomplete
      fields={fields}
      restrictions={{ country: 'us' }}
      onLoad={onLoad}
      onPlaceChanged={onPlaceChanged}
    >
      <Input placeholder={props.placeholder} />
    </Autocomplete>
  );
}
