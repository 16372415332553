import React from 'react';
import { useAction } from '@reatom/react';

import { useListItemData } from 'libs/reatom-toolkit/useList';
import {
  DialogsList,
  useDialog,
  InterlocutorType,
  Dialog,
} from 'features/messages';

import { DialogTemplate } from 'features/messages/ui/templates/dialog-template';

import { useOpenableState } from 'libs/use-openable-state';

import {
  getMessagesForDialogAction,
  petOwnerPostMessageToPetAction,
  petOwnerPostMessageToServiceProviderAction,
} from '../pet-owner-messages.model';
import { ChooseDocumentsModal } from '../../documents/ui/organisms/choose-document-modal';

export function PODialog(props: { dialogId: string }) {
  const DialogsComponentsBySender = {
    [InterlocutorType.pet]: {
      dialog: withPetDialogPage,
    },
    [InterlocutorType.serviceOwner]: {
      dialog: withSPDialogPage,
    },
  };

  const dialog = useListItemData(DialogsList, props.dialogId);

  if (!dialog) return null;

  const DialogComponent =
    DialogsComponentsBySender[dialog.interlocutor.type].dialog;

  return <DialogComponent dialog={dialog} />;
}

function withPetDialogPage(props: { dialog: Dialog }) {
  const { messages, getNextPage } = useDialog(props.dialog.id, {
    getMessagesAction: getMessagesForDialogAction,
  });
  const postMessage = useAction(petOwnerPostMessageToPetAction);

  async function handlePostMessage(message: string) {
    postMessage({
      petId: props.dialog.interlocutor.id,
      message,
      attachments: [],
    });
  }

  return (
    <DialogTemplate
      dialog={props.dialog}
      messages={messages}
      getIsOwnMessage={() => false}
      onPostMessage={handlePostMessage}
      getNextPage={() => getNextPage({ petId: props.dialog.interlocutor.id })}
    />
  );
}

function withSPDialogPage(props: { dialog: Dialog }) {
  const { messages, getNextPage } = useDialog(props.dialog.id, {
    getMessagesAction: getMessagesForDialogAction,
  });
  const postMessage = useAction(petOwnerPostMessageToServiceProviderAction);
  const modal = useOpenableState();

  async function handlePostMessage(message: string) {
    postMessage({
      serviceOwnerId: props.dialog.interlocutor.id,
      message,
      attachments: [],
    });
  }

  async function handlePostAttachments(attachments: number[]) {
    postMessage({
      serviceOwnerId: props.dialog.interlocutor.id,
      message: '',
      attachments: attachments,
    });
  }

  const spMessages = messages
    .map(message => {
      if (message.files.length > 0) {
        return message.files.map(file => {
          const [fileName] = file.original_name.split(/(?:\.([^.]+))?$/);
          const body = `You have shared the "${fileName}" document to the Service Provider`;
          const fileId = file.id;
          const key = `${message.id}${file.id}`;
          return { ...message, ...file, body: body, fileId: fileId, key: key };
        });
      }
      return message;
    })
    .flat();

  return (
    <>
      <DialogTemplate
        dialog={props.dialog}
        messages={spMessages}
        getIsOwnMessage={message => message.sender === 'petOwner'}
        onPostMessage={handlePostMessage}
        onAttachmentsButtonClick={() => modal.open()}
        getNextPage={() =>
          getNextPage({
            serviceOwnerId: props.dialog.interlocutor.id,
          })
        }
      />
      {modal.isOpened && (
        <ChooseDocumentsModal {...modal} onConfirm={handlePostAttachments} />
      )}
    </>
  );
}
