import { declareResource, declareAsyncAction } from 'libs/reatom-toolkit';
import { publicApi } from 'api';

export const getNextPageAction = declareAsyncAction(
  'Get Service Provider Get Next Page',
  async ({}, store) => {},
);

export const SearchByServiceTypeResource = declareResource<
  {
    list: [];
    count: number;
    isLoading: boolean;
  },
  {
    page: number;
    serviceType: number;
    search?: string;
    geoLocation: {
      latitude: number;
      longitude: number;
    };
  }
>(
  'Service Provider By Service Types',
  {
    list: [],
    count: 0,
    isLoading: true,
    page: 1,
  },
  async ({ geoLocation, serviceType, search, page = 1 }, store) => {
    const { list } = store.getState(SearchByServiceTypeResource);
    const {
      data,
      headers: { total_count },
    } = await publicApi.get(`/service-owner/by-service-type/${serviceType}`, {
      params: {
        page,
        per_page: 12,
        geoLocation,
        search,
      },
    });
    return {
      list: [...list, ...data],
      count: total_count,
      isLoading: false,
      page,
    };
  },
);
