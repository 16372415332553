import { RouteConfig } from 'react-router-config';
import { HomePage } from 'pages';

import { AboutUsPage } from 'pages/about-us';
import { BlogPage } from 'pages/blog';
import { BlogArticlePage } from 'pages/blog-article';
import { PetOwnerPage } from 'pages/pet-owner-profile';
import { PetOwnerProfileCalendarPage } from 'pages/pet-owner-profile/calendar';
import { PetOwnerProfilePetsPage } from 'pages/pet-owner-profile/pets';
import { PetOwnerProfileFavoritesPage } from 'pages/pet-owner-profile/favorites';
import { PetOwnerProfileReviewsPage } from 'pages/pet-owner-profile/reviews';
import { PetProfilePage } from 'pages/pet-profile';
import { PetProfileProfilePage } from 'pages/pet-profile/profile';
import { PetProfileCalendarPage } from 'pages/pet-profile/calendar';
import { PetProfilePrivacyPage } from 'pages/pet-profile/privacy';
import { PetProfileMedicalHistoryPage } from 'pages/pet-profile/medical-history';
import { PetPage } from 'pages/pet';
import { ServiceProviderProfilePage } from 'pages/service-provider-profile';
import { ServiceProviderProfileProfilePage } from 'pages/service-provider-profile/profile';
import { ServiceProviderProfileAppointmentsPage } from 'pages/service-provider-profile/appointments';
import { ServiceProviderProfileServicesPage } from 'pages/service-provider-profile/services';
import { ServiceProviderProfilePersonnelPage } from 'pages/service-provider-profile/personnel';
import { ServiceProviderProfileReviewsPage } from 'pages/service-provider-profile/reviews';
import { PremiumPage } from 'pages/service-provider-profile/premium';
import { ServiceProviderPage } from 'pages/service-provider';
import { ServiceProviderPublicProfilePage } from 'pages/service-provider/profile';
import { ServiceProviderServicesPage } from 'pages/service-provider/services';
import { ServiceProviderReviewsPage } from 'pages/service-provider/reviews';
import { ServiceProviderPersonnelPage } from 'pages/service-provider/personnel';
import { onlyAuth } from 'features/authentication/onlyAuth';
import { ConfirmEmailFailedPage } from 'pages/confirm-email/failed';
import { ConfirmEmailPage } from 'pages/confirm-email';
import { ConfirmEmailSuccessPage } from 'pages/confirm-email/success';
import { ConfirmEmailWasSendPage } from 'pages/confirm-email/send';

import { ConfirmNewPasswordPage } from 'pages/confirm-new-password';
import { Page404 } from 'pages/404/';
import { ContactUsPage } from 'pages/contact-us';
import { DescriptionForPetOwnersPage } from 'pages/description-for-pet-owners';
import { DescriptionForServiceProvidersPage } from 'pages/for-service-providers';
import { FAQPage } from 'pages/faq';
import { ForgotPasswordPage } from 'pages/forgot-password';
import { LoginPage } from 'pages/login';
import { PetOwnerSignUp } from 'pages/sign-up-pet-owner';
import { PremiumAccountDescriptionPage } from 'pages/premium-account';
import { ProviderSignUp } from 'pages/sign-up-provider';
import { clientSideOnly } from 'libs/ssr/client-side-only';
import { CheckEmailPage } from 'pages/forgot-password/sent';
import { ConfirmNewPasswordSuccessPage } from 'pages/confirm-new-password/success';
import { ServiceProvidersPage } from 'pages/service-providers';
import { MessagesPage } from 'pages/messages/messages.page';
import { SearchPage } from 'pages/search/search.page';

const clientSideOnlyWithFallback = clientSideOnly({
  fallback: () => null,
});

export const routes: RouteConfig[] = [
  {
    exact: true,
    path: '/',
    component: HomePage,
  },
  {
    exact: true,
    path: '/about-us',
    component: AboutUsPage,
  },
  {
    exact: true,
    path: '/blog',
    component: BlogPage,
  },
  {
    path: '/blog-article/:articleId',
    component: BlogArticlePage,
  },

  {
    exact: true,
    path: '/confirm-new-password',
    component: ConfirmNewPasswordPage,
  },

  {
    exact: true,
    path: '/confirm-new-password/success',
    component: ConfirmNewPasswordSuccessPage,
  },

  {
    exact: true,
    path: '/contact-us',
    component: ContactUsPage,
  },
  {
    exact: true,
    path: '/for-pet-owners',
    component: DescriptionForPetOwnersPage,
  },
  {
    exact: true,
    path: '/for-service-providers',
    component: DescriptionForServiceProvidersPage,
  },
  {
    exact: true,
    path: '/faq',
    component: FAQPage,
  },
  {
    exact: true,
    path: '/reset-password',
    component: ForgotPasswordPage,
  },

  {
    exact: true,
    path: '/reset-password/sent',
    component: CheckEmailPage,
  },

  {
    exact: true,
    path: '/login',
    component: LoginPage,
  },
  {
    exact: true,
    path: '/sign-up-pet-owner',
    component: PetOwnerSignUp,
  },

  {
    exact: true,
    path: '/premium-account',
    component: PremiumAccountDescriptionPage,
  },
  {
    exact: true,
    path: '/sign-up-provider',
    component: ProviderSignUp,
  },
  {
    path: '/confirm-email/',
    component: ConfirmEmailPage,
    routes: [
      {
        exact: true,
        path: '/confirm-email/success',
        component: ConfirmEmailSuccessPage,
      },
      {
        exact: true,
        path: '/confirm-email/send',
        component: ConfirmEmailWasSendPage,
      },
      {
        exact: true,
        path: '/confirm-email/failed',
        component: ConfirmEmailFailedPage,
      },
    ],
  },

  {
    path: '/pet-owner-profile',
    guards: [clientSideOnlyWithFallback, onlyAuth],
    component: PetOwnerPage,
    routes: [
      {
        exact: true,
        path: '/pet-owner-profile',
        component: PetOwnerProfileCalendarPage,
      },
      {
        exact: true,
        path: '/pet-owner-profile/pets',
        component: PetOwnerProfilePetsPage,
      },
      {
        exact: true,
        path: '/pet-owner-profile/favorites',
        component: PetOwnerProfileFavoritesPage,
      },
      {
        exact: true,
        path: '/pet-owner-profile/reviews',
        component: PetOwnerProfileReviewsPage,
      },
    ],
  },

  {
    path: '/pet-profile/:petId',
    guards: [onlyAuth],
    component: PetProfilePage,
    routes: [
      {
        exact: true,
        path: '/pet-profile/:petId',
        component: PetProfileProfilePage,
      },

      {
        exact: true,
        path: '/pet-profile/:petId/calendar',
        component: PetProfileCalendarPage,
      },
      {
        exact: true,
        path: '/pet-profile/:petId/medical-history',
        component: PetProfileMedicalHistoryPage,
      },
      {
        exact: true,
        path: '/pet-profile/:petId/privacy',
        component: PetProfilePrivacyPage,
      },
    ],
  },

  {
    exact: true,
    path: '/pet/:petId',
    component: PetPage,
    guards: [onlyAuth],
  },
  {
    exact: true,
    path: '/service-provider-profile/premium',
    component: PremiumPage,
    guards: [clientSideOnlyWithFallback, onlyAuth],
  },
  {
    path: '/service-provider-profile',
    guards: [clientSideOnlyWithFallback, onlyAuth],
    component: ServiceProviderProfilePage,
    routes: [
      {
        exact: true,
        path: '/service-provider-profile',
        component: ServiceProviderProfileProfilePage,
      },
      {
        exact: true,
        path: '/service-provider-profile/appointments',
        component: ServiceProviderProfileAppointmentsPage,
      },
      {
        exact: true,
        path: '/service-provider-profile/services',
        component: ServiceProviderProfileServicesPage,
      },
      {
        exact: true,
        path: '/service-provider-profile/personnel',
        component: ServiceProviderProfilePersonnelPage,
      },
      {
        exact: true,
        path: '/service-provider-profile/reviews',
        component: ServiceProviderProfileReviewsPage,
      },
    ],
  },
  {
    path: '/service-provider/:serviceProviderId',
    component: ServiceProviderPage,
    routes: [
      {
        exact: true,
        path: '/service-provider/:serviceProviderId',
        component: ServiceProviderPublicProfilePage,
      },
      {
        exact: true,
        path: '/service-provider/:serviceProviderId/personnel',
        component: ServiceProviderPersonnelPage,
      },
      {
        exact: true,
        path: '/service-provider/:serviceProviderId/services',
        component: ServiceProviderServicesPage,
      },
      {
        exact: true,
        path: '/service-provider/:serviceProviderId/reviews',
        component: ServiceProviderReviewsPage,
      },
    ],
  },

  {
    path: '/service-providers',
    component: ServiceProvidersPage,
  },

  {
    path: '/messages',
    guards: [onlyAuth],
    component: MessagesPage,
  },

  {
    path: '/search',
    component: SearchPage,
  },

  {
    path: '*',
    staticContext: { status: 404 },
    component: Page404,
  },
];
