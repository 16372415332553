import React from 'react';
import { Link } from 'react-router-dom';

import { Title } from 'ui/templates/cohfirm-page-template';

export function ConfirmEmailSuccessPage() {
  return (
    <>
      <Title>Your account has been successfully verified</Title>
      <Link to="/login">← Back to Login</Link>
    </>
  );
}
