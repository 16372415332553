import styled from 'styled-components';

export const Item = styled.div`
  height: 29px;
  padding: 4px 16px;
  word-break: keep-all;
  white-space: nowrap;
  :hover {
    background: #dfe8f6;
    color: #344ca4;
  }
`;
